// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import {
  ThemeProvider,
  withStyles,
  createStyles
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import EndUserEmailSignupController from "./EndUserEmailSignupController.web";
import {
  commonStyles,
  themeWhite,
  themeYellow,
  webStyles
} from "../../../framework/src/enduserTheme";
import { backArrow, lockIcon } from "./assets";
import MobileLoader from "../../../components/src/MobileLoader";
import { Formik } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  handleAndConditions,
  handleOrConditions
} from "../../../components/src/CommonFunctions";

const validation_Schema_stepOne = yup.object({
  thoughts: yup
    .string()
    .required("Your thoughts are required")
    .matches(/[a-zA-Z]/, "Only alphabets are allowed")
});

const validation_Schema_stepTwo = yup.object({
  userName: yup
    .string()
    .required("Full Name is required")
    .matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1")
});

const validation_Schema_stepThree = yup.object({
  fullNumber: yup
    .string()
    .required("Phone number is required")
    .min(10, "Must be at least 10 digits")
});

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 46,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    color: "#FFC629", // Yellow color for inactive state button
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white, // White color for active state button
      backgroundColor: "#FFC629", // Yellow color for active state background
      "& + $track": {
        backgroundColor: "#FFC629", // Yellow color for active state track
        opacity: 1,
        border: "1px solid #000"
      }
    },
    "&$focusVisible $thumb": {
      color: theme.palette.common.white, // White color for active state button
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #000`,
    backgroundColor: theme.palette.grey[50], // White background for inactive state
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
  // @ts-ignore
}))(({ classes, ...props }) => {
  return (
    <Switch
      data-test-id="switchBtn"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export class EndUserEmailSignUp extends EndUserEmailSignupController {
  render() {
    const { classes }: any = this.props;

    const StepTwo = () => {
      return (
        <Formik
          initialValues={{
            userName: ""
          }}
          validationSchema={validation_Schema_stepTwo}
          onSubmit={values => {
            this.setState({
              userName: values.userName,
              stepTwo: false,
              stepThree: true
            });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit
          }) => (
            <>
              <Box className={classes.backIconBox}>
                <IconButton
                  data-test-id="backToStepOne"
                  onClick={() =>
                    this.setState({ stepOne: true, stepTwo: false })
                  }
                >
                  <img src={backArrow} alt="back_arror" />
                </IconButton>
              </Box>
              <Box className={classes.textBox}>
                <Typography
                  data-test-id="secondStepText1"
                  align="center"
                  className={classes.centerTextSU1}
                >
                  {this.state.thoughts}
                </Typography>
              </Box>
              <Box className={classes.addNameBox}>
                <Typography
                  data-test-id="secondStepText2"
                  className={classes.textOne}
                >
                  Great start! Let's add a personal touch to your post.
                </Typography>
                <Typography className={classes.textTwoSU}>
                  What's your first and last name?
                </Typography>
                <input
                  name="userName"
                  data-test-id="secondStepInput"
                  placeholder=" Add Your Name"
                  className={classes.nameInputSU}
                  value={values.userName}
                  onChange={handleChange("userName")}
                  onBlur={handleBlur("userName")}
                />
                {handleAndConditions(
                  touched.userName,
                  errors.userName,
                  <Typography className={classes.errorMsg}>
                    {errors.userName}
                  </Typography>
                )}
              </Box>
              <Button
                data-test-id="stepTwoButton"
                onClick={() => handleSubmit()}
                className={handleOrConditions(
                  values.userName,
                  classes.buttonPrimary,
                  classes.buttonDisable
                )}
              >
                Continue
              </Button>
            </>
          )}
        </Formik>
      );
    };

    const StepThree = () => {
      return (
        <Formik
          initialValues={{
            fullNumber: ""
          }}
          validationSchema={validation_Schema_stepThree}
          onSubmit={values => {
            this.senOtpToUser(values.fullNumber);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit
          }) => (
            <>
              <Box className={classes.backIconBox}>
                <IconButton
                  data-test-id="backToStepTwo"
                  onClick={() =>
                    this.setState({ stepThree: false, stepTwo: true })
                  }
                >
                  <img src={backArrow} alt="back_arror" />
                </IconButton>
              </Box>

              <Typography align="center" className={classes.centerTextSU1}>
                {this.state.thoughts}
              </Typography>
              <Box>
                <Typography
                  data-test-id="thirdStepText"
                  className={classes.textTwoSU}
                >
                  We protect our community by making sure everyone is real
                </Typography>
                <Box className={classes.flexDisplaySU}>
                  <Box className={classes.marginAutoSU}>
                    <PhoneInput
                      specialLabel={""}
                      data-test-id="fullNumber"
                      country={"us"}
                      value={values.fullNumber}
                      onChange={handleChange("fullNumber")}
                      onBlur={handleBlur("fullNumber")}
                      placeholder="Enter phone number"
                      buttonStyle={handleOrConditions(
                        this.state.themeSwitch,
                        webStyles.buttonStyleYellow,
                        webStyles.buttonStyleLight
                      )}
                      inputStyle={handleOrConditions(
                        this.state.themeSwitch,
                        webStyles.phoneInputYellow,
                        webStyles.phoneInputLight
                      )}
                    />
                  </Box>
                </Box>
                {handleAndConditions(
                  touched.fullNumber,
                  errors.fullNumber,
                  <Typography className={classes.errorMsg}>
                    {errors.fullNumber}
                  </Typography>
                )}
                <Box className={classes.flexDisplaySU}>
                  <img
                    src={lockIcon}
                    alt="lockIcon"
                    className={classes.lockIcon}
                  />
                  <Typography className={classes.lockTextSU}>
                    We never share this with anyone and it won't be on your
                    public profile. We'll only verify your number to make sure
                    you are you.
                  </Typography>
                </Box>
                {handleOrConditions(
                  this.state.phoneNumberError,
                  <Typography className={classes.errorMsg}>
                    Please check your phone number and try again.
                  </Typography>,
                  null
                )}
              </Box>

              <Box className={classes.bottomBox}>
                <Button
                  data-test-id="stepThreeSubmit"
                  onClick={() => handleSubmit()}
                  className={handleOrConditions(
                    values.fullNumber,
                    classes.buttonPrimary,
                    classes.buttonDisable
                  )}
                  style={{ marginBottom: "20px" }}
                >
                  Continue
                </Button>
                <Typography className={classes.lockTextSU}>
                  By continuing, you agree to our privacy policy and terms of
                  service and authorize (business user's company name) to send
                  text messages with offers & other information to the mobile
                  number you provided. Message/data rates apply.
                </Typography>
              </Box>
            </>
          )}
        </Formik>
      );
    };

    const handleSteps = () => {
      if (this.state.stepOne) {
        return null;
      } else if (this.state.stepTwo) {
        return <StepTwo data-test-id="mainContainer2" />;
      } else {
        return <StepThree data-test-id="mainContainer3" />;
      }
    };

    return (
      <ThemeProvider theme={this.state.themeSwitch ? themeYellow : themeWhite}>
        <MobileLoader loading={this.state.loading} />
        <CssBaseline />
        <Container
          data-test-id="mainContainer"
          maxWidth="sm"
          className={classes.mainContainer}
        >
          {this.state.stepOne && (
            <Formik
              initialValues={{
                thoughts: ""
              }}
              validationSchema={validation_Schema_stepOne}
              onSubmit={values => {
                this.setState({
                  thoughts: values.thoughts,
                  stepTwo: true,
                  stepOne: false
                });
              }}
              data-test-id="stepOneForm"
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit
              }) => (
                <>
                  <Box className={classes.backIconBox}>
                    <IconButton onClick={this.handleSignInClick}>
                      <img src={backArrow} alt="back_arror" />
                    </IconButton>
                  </Box>
                  <Box className={classes.switchBox}>
                    <FormControlLabel
                      data-test-id="themeSwicthSignup"
                      control={
                        <IOSSwitch
                          // @ts-ignore
                          checked={this.state.themeSwitch}
                          onChange={this.handelThemeChange}
                          name="checkedB"
                        />
                      }
                      label=""
                    />
                  </Box>
                  <Box>
                    <textarea
                      rows={4}
                      name="userThoughts"
                      data-test-id="stepOneText"
                      className={classes.thoughtsInput}
                      placeholder="Tap to share your thoughts or ask a question..."
                      value={values.thoughts}
                      onChange={handleChange("thoughts")}
                      onBlur={handleBlur("thoughts")}
                    />
                    {handleAndConditions(
                      touched.thoughts,
                      errors.thoughts,
                      <Typography className={classes.errorMsg}>
                        {errors.thoughts}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    data-test-id="stepOneButton"
                    onClick={() => handleSubmit()}
                    className={handleOrConditions(
                      values.thoughts,
                      classes.buttonPrimary,
                      classes.buttonDisable
                    )}
                  >
                    Continue
                  </Button>
                  <Typography className={classes.signInContainer}>
                    Already have an account?{" "}
                    <span
                      data-test-id="eu-redirect-signin-btn"
                      onClick={this.handleSignInClick}
                    >
                      Sign In
                    </span>
                  </Typography>
                </>
              )}
            </Formik>
          )}
          {handleSteps()}
        </Container>
      </ThemeProvider>
    );
  }
}

const styles = () =>
  createStyles({
    addNameBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "30px"
    },
    switchBox: {
      position: "absolute",
      top: "30px",
      right: "10px"
    },
    errorMsg: {
      ...commonStyles.errorMsg
    },
    nameInputSU: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
      outline: "none",
      background: "transparent",
      padding: "5px 0px",
      margin: "10px 0 15px",
      border: "none",
      borderBottom: "1px solid #000",
      "&::placeholder": {
        color: "#7f6415"
      }
    },
    marginAutoSU: {
      margin: "10px 0px",
      width: "100%",
      border: "1px solid #cacaca",
      borderRadius: "5px",
      "& .react-tel-input .form-control": {
        border: "none",
        "&:hover": {
          border: "none"
        },
        "&:focus": {
          border: "none",
          boxShadow: "none"
        }
      },
      "& .flag-dropdown": {
        border: "none",
        borderRight: "1px solid #cacaca"
      },
      "&:hover": {
        border: "1px solid #1976d2",
        "& .flag-dropdown": {
          borderColor: "#1976d2"
        }
      },
      "&:focus-within": {
        border: "2px solid #1976d2",
        "& .flag-dropdown": {
          borderColor: "#1976d2",
          borderRight: "2px solid #1976d2"
        }
      }
    },
    flexDisplaySU: {
      display: "flex"
    },
    lockIcon: {
      height: "16px",
      width: "16px",
      margin: "auto 5px"
    },
    lockTextSU: {
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "14px",
      padding: "0 5px"
    },
    textOne: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "14px",
      padding: "20px 0px 30px"
    },
    textTwoSU: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "14px"
    },
    centerTextSU: {
      color: "#000",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      paddingLeft: "20px",
      paddingRight: "20px"
    },
    centerTextSU1: {
      color: "#000",
      textAlign: "center",
      alignItems: "center",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      margin: "0 auto"
    },
    textBox: {
      position: "absolute",
      top: "100px",
      left: "50%",
      transform: "translateX(-50%)"
    },
    centerTextSU2: {
      color: "#000",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      paddingLeft: "10px",
      paddingRight: "35px",
      overflow: "scroll",
      position: "absolute",
      top: "50px"
    },
    mainContainer: {
      ...commonStyles.mobileContainer,
      padding: "30px 20px",
      overflowY: "auto"
    },
    buttonDisable: {
      ...commonStyles.mobileButton,
      width: "100%",
      alignSelf: "center",
      height: "54px",
      color: "#666",
      backgroundColor: "#cccccc",
      marginBottom: "70px",
      "&:hover": {
        color: "#666",
        backgroundColor: "#cccccc"
      }
    },
    buttonPrimary: {
      ...commonStyles.mobileButton,
      width: "100%",
      alignSelf: "center",
      height: "56px",
      backgroundColor: "#000000",
      marginBottom: "70px"
    },
    buttonPrimarySignUp: {
      ...commonStyles.mobileButton,
      marginBottom: "20px",
      width: "80%",
      alignSelf: "center",
      height: "56px",
      backgroundColor: "#7f6415"
    },
    backIconBox: {
      margin: "10px 0",
      position: "relative",
      right: "20px"
    },
    bottomBox: {
      // textAlign: "center",
    },
    thoughtsInput: {
      background: "transparent",
      border: "none",
      outline: "none",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
      fontFamily: "Inter",
      width: "100%",
      "&::placeholder": {
        fontSize: "18px", // Modify the font size for the placeholder
        fontWeight: 600, // Modify the font weight for the placeholder
        textAlign: "center" // Modify the text alignment for the placeholder
        /* Any other styles you want to apply to the placeholder text */
      }
    },
    signInContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      marginTop: "30px",
      color: "#0F172A",
      lineHeight: "24px",
      fontFamily: "Inter",
      fontWeight: 400,
      "&>span": {
        fontWeight: "700",
        lineHeight: "24px",
        fontFamily: "Inter",
        cursor: "pointer"
      }
    }
  });

export default withStyles(styles)(EndUserEmailSignUp);
// Customizable Area End
