import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const webStyle = {

  alertMsg:
   {
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(5, 0),
    borderLeft: '4px solid #DC2626',
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#FEE2E2',
    width:"300px",
    color: '#DC2626',
    height:"20px",
    '& p': {
      fontSize: '12px',
      color: '#DC2626',
    },
  },

  message: {
    fontFamily: "sans-serif",
  }
};

const EmailAccountLoginErrorBlock = (props: any) => {
  return (
    // Required for all blocks
    // Customizable Area start
    <ThemeProvider theme={theme}>
      <div style={webStyle.alertMsg}
       role="alert">
        <div className="MuiAlert-message" style={webStyle.message}>{props.errorMsg}</div>
      </div>
    </ThemeProvider>
    // Customizable Area End
  );
};
export default EmailAccountLoginErrorBlock;
// Customizable Area End
