import React from 'react';
// Customizable Area Start
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, TextField, Button, TextareaAutosize, CircularProgress, Card, CardContent, Grid } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert"
import Snackbar from '@material-ui/core/Snackbar';
import UserProfileController, { Props } from "./UserProfileController.web"
import { skip_avatar, next, trueIcon, profileHover, evrybrand } from "./assets"
import NavigationMenuEu from '../../../components/src/NavigationMenuEU.web';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const styles = (theme: Theme) =>
  createStyles({
    headerprofile: {
      backgroundColor: "#FFC629",
      height: "217px",
      border: "1px solid #FFC629 ",
      borderRadius: "0px 0px 48px 0px",
      position: "relative",
    },
    maincontainer: {
      margin: " 20px 94px 50px -12px",
      backgroundColor: "#fff",
    },
    overlay: {
      cursor: "pointer",
      position: "absolute",
      top: "139px",
      left: "39px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      border: "2px solid #FFF",
      width: "156px",
      height: "156px",
      borderRadius: "50%",
      overflow: "hidden",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'opacity 0.3s ease',
    },
    imgprofileHover: {
      width: "100%",
    },
    hoverImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    imgprofile: {
      cursor: "pointer",
      position: "absolute",
      top: "139px",
      left: "39px",
      backgroundColor: "#FFC629",
      border: "2px solid #FFF",
      width: "156px",
      height: "156px",
      borderRadius: "50%",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      objectFit: "cover"
    },
    labelField: {
      letterSpacing: "0px",
      textAlign: "left",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "30px",
    },
    maincontainerProfile: {
      maxWidth: "1087px",
      margin: "0 auto"
    },
    nextHomePage: {
      backgroundColor: "#FFC629",
      textTransform: "capitalize" as "capitalize",
      height: "44px",
      fontSize: "16px",
      fontWeight: 700,
      width: "252px",
      borderRadius: "8px",
      gap: "8px",
      "& :hover": {
        backgroundColor: "#FFC629",
      }

    },
    nextHomePage2: {
      backgroundColor: "#FFC629",
      textTransform: "capitalize" as "capitalize",
      height: "44px",
      fontSize: "16px",
      gap: "8px",
      padding: "10px 16px 10px 16px",
      fontWeight: 700,
      width: "188",
      borderRadius: "8px",
      "& :hover": {
        backgroundColor: "#FFC629",
      }
    },
    profileData: {
      lineHeight: "30px",
      fontSize: "25px",
      fontWeight: 700,
      letterSpacing: "0px",

    },
    headline: {
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "0px",
      textAlign: "left",
      fontWeight: 700,
      marginTop: "35px"

    },
    basicInfo: {
      fontWeight: 400,
      letterSpacing: "0px",
      fontSize: "13px",
      textAlign: "left",
      lineHeight: "24px",

    },
    textareaAdd: {
      width: "100%",
      marginTop: "30px",
      padding: "10px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Inter",
    "&:focus": {
      outline: "none",
      border: "2px solid #FFC629",
    },
    },
    card: {

      borderRadius: '10px',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      fontSize: "14px",
      backgroundColor: 'black',
      alignItems: 'center',
      height: '100%',
      textAlign: 'center',
    },
    text: {
      fontSize: '14px',
      fontWeight: 700
    },
    everybrand: {
      position: "absolute",
      bottom: "29px",
      right: "3px"

    },
    nextIcon: {
      cursor: "pointer",
      position: "absolute",
      right: "-55px",
      top: "42%"
    },
    cancelButton: {
      width: "87px",
      height: "44px",
      padding: "10px 16px 10px 16px",
      borderRadius: "8px",
      gap: "8px",
      textTransform: "capitalize" as "capitalize",
      fontSize: "16px",
      fontWeight: 700,
      backgroundColor: "black",
      color: "#fff",
      marginRight: "20px"
    },
    buttongroup: {
      float: "right"
    },
    profileLoader:{
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center", 
      left: "95px",
      top: "200px",
      position: "absolute",
      zIndex: 1 
    },

    customPlaceholderForTextaea:{

    },
    customPlaceholder:{
      "&::placeholder":{
      color:"#0F172A",
      opacity:1
      }
    }
  });

class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { personalities, name, email, profileImage, isLoading, currentcity, currentstate, aboutprofile, hoverImg,isProfileLoading } = this.state;
    return (
      <>
        <Snackbar
          open={this.state.isShowAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={"success"}
          >
            {this.state.showSucess}

          </MuiAlert>
        </Snackbar>
        {this.state.isEndUser && <NavigationMenuEu/>}
        {isLoading ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} ><CircularProgress /></Box > :
          <>
            <Box className={classes.maincontainerProfile} >
              <Box className={classes.maincontainer} paddingBottom={15}>
                <Box className={classes.headerprofile}>

                  <div
                    onMouseEnter={() => this.showHoverImg()}
                    onMouseLeave={() => this.hideHoverImg()}
                    data-test-id="mouseEvents"
                    style={{position:"relative"}}
                  >
                    {isProfileLoading
                             ? <Box  className={classes.profileLoader}><CircularProgress/></Box > :
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={this.handleFileChange}
                        id="imageInput"
                        data-test-id="imageInput"
                        name="photo"
                      />
                    }

                    <label htmlFor="imageInput">

                      <img
                        src={this.state.selectedProfileImage || (profileImage.length > 0 ? profileImage : "")}
                        alt=""
                        className={classes.imgprofile}
                      />

                      <div style={{position: "absolute", top: "265px",
      left: "150px", background: "#fff", border: "2px solid #fff", borderRadius: "50%", padding: "2px", zIndex: 9999}}><CameraAltIcon/></div>


                      {hoverImg &&
                        <div className={classes.overlay}>
                          <img
                            alt="Hover Image"
                            src={profileHover}
                            className={classes.imgprofileHover}
                          />
                        </div>
                      }
                    </label>
                  </div>
                  <div className={classes.everybrand}>
                    <img src={evrybrand} alt="everybrand" width="75%" />
                  </div>
                </Box>
                <Box marginTop={15} paddingX={20}>
                  <Typography variant="h6" gutterBottom className={classes.labelField} >
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Diana Turner"
                    variant="outlined"
                    size="small"
                    name="name"
                    onChange={(e) => this.handleFieldChange(e)}
                    value={name}
                    data-test-id="name"
                    inputProps={{
                      style: {
                        height: "35px",
                      },
                    }}
                  />
                  <Box mt={5} marginTop={5}>
                    <Typography variant="h6" gutterBottom className={classes.labelField}>
                      Email
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Dturner12@mail.com"
                      variant="outlined"
                      name="email"
                      onChange={(e) => this.handleFieldChange(e)}
                      value={email}
                      data-test-id="email"
                      inputProps={{
                        style: {
                          height: "35px",
                        },
                      }}
                    />
                  </Box>

                  <Box marginTop={7}>
                    <Typography variant="h4" gutterBottom className={classes.profileData}>
                      Let´s make your profile a little more you.
                    </Typography>
                    <Typography variant="h4" gutterBottom className={classes.headline}>
                      My headline
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} marginTop={3} >
                      <Typography variant="subtitle1" gutterBottom className={classes.basicInfo}>
                        Make your personality stand out from the crowd.
                      </Typography>
                      <Button variant="contained" data-test-id="nextButton" className={classes.nextHomePage}>See Headline examples</Button>
                    </Box>

                    <TextareaAutosize minRows={7}   className={classes.textareaAdd} name="aboutprofile" data-test-id="aboutprofile" value={aboutprofile} onChange={(e) => this.handleFieldChange(e)} placeholder='Use this space to write a fun and punchy intro about yourself.' />
                    <Box marginTop={5}>
                      <Typography variant="h6" gutterBottom className={classes.labelField} >
                        Current City
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Add City"
                        variant="outlined"
                        size="small"
                        name="currentcity"
                        onChange={(e) => this.handleFieldChange(e)}
                        value={currentcity}
                        data-test-id="currentcity"
                        inputProps={{
                          style: {
                            height: "35px",
                            color: "#0F172A" // Change the color to the desired color
                          },
                          className:classes.customPlaceholder
                        }}
                        
                      />
                    </Box>
                    <Box marginTop={5}>
                      <Typography variant="h6" className={classes.labelField} gutterBottom>
                        Current State
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Add state abreviations"
                        variant="outlined"
                        name="currentstate"
                        onChange={(e) => this.handleFieldChange(e)}
                        value={currentstate}
                        size="small"
                        data-test-id="currentstate"
                        inputProps={{
                          style: {
                            height: "35px",
                            
                          },
                          className:classes.customPlaceholder
                        }}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Typography variant="h4" gutterBottom className={classes.headline}>
                      Pick a Personality Prompt
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom className={classes.basicInfo}>
                      It´s the perfect opportunity to showcase your sense of humor, hobbies, interests, and your overall personality.
                    </Typography>
                  </Box>
                  <Box marginTop={3} sx={{ position: "relative" }}>
                    <Grid container spacing={2}>
                      {personalities.map((item: string, index: number) => (
                        <Grid item xs={12} sm={6} md={4}>
                          <Card className={classes.card} key={index}>
                            <CardContent >
                              <Typography variant="h6" component="div" className={classes.text}>
                                {item}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>

                    <img src={next} alt="next" className={classes.nextIcon} />
                  </Box>
                  <Box marginTop={5} className={classes.buttongroup} >
                    <Button variant="contained" className={classes.cancelButton} data-test-id="cancelbutton" onClick={this.handleCancleChanges}>Cancel</Button>
                    <Button variant="contained" data-test-id="nextHomePage2" onClick={this.handleSaveChanges} className={classes.nextHomePage2}>
                      <img src={trueIcon} alt="img" />Save changes</Button>

                  </Box>
                </Box>
              </Box>
            </Box></>
        }


      </>
    );
  }
}

export { UserProfile };
export default withStyles(styles)(UserProfile);
// Customizable Area End
