// Customizable Area Start
import React from "react";
import {
    Button,
    Container,
    Typography,
    Card,
    Box,
    IconButton,
    Modal,
    TextField,
    DialogContent,
    Divider,
    Snackbar,

} from "@material-ui/core";
import { Link } from "react-router-dom"
import {
    withStyles,
    createStyles,
    Theme,
    styled,
} from "@material-ui/core/styles";
import { stepImageFirst, CloseIcon } from "./assets";
import MuiAlert from "@material-ui/lab/Alert";

import { Form, Formik } from "formik";
import * as yup from "yup";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';



import WaitListController from "./WaitListController.web";

import CreateWaitListContactWeb from "./CreateWaitListContact.web";
import WaitListSubscriptionWeb from "./WaitListSubscription.web";





export class WaitList extends WaitListController {


    isFieldInvalid = (touched: any, errors: any, fieldName: any) => {
        return touched[fieldName] && Boolean(errors[fieldName]);
    };

    FormFieldError = (errors: any, touched: any) => {

        return errors && touched && <div data-testid="phone-error" style={{ color: "red", marginTop: "5px" }}>
            {errors}
        </div>
    }





    handleFieldChange = (name: string, businessName: any) => {
        return `Hi <span style="
color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px
;">Contact Name</span>,  This is <span style="
color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px;">${name || "Provider name"} </span>   from 
<span style="
color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px;">${businessName || "Business name"} </span>     we have an earlier open appointment available for you. If the time below works, just type “Yes” and it´s all yours. <br/>  <br/> If not, type “Pass” and we´ll keep working our way down our list and check back if another earlier spot opens up.`



    }
    render() {

        const { classes }: any = this.props;


        const form_schema = yup.object().shape({
            name: yup
                .string().nullable().required("Provider name is required"),
            business_name: yup.string().nullable().required("Business name is required"),
            reminder_note: yup
                .string().nullable().required("Trigger condition is required")


        });

        return (
            <Container maxWidth={false} className={classes.waitListContainer}>
                <Snackbar
                    open={this.state.isShowAlert}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity={this.state.errorAlertMessage ? "error" : "success"}
                    >
                        {this.state.showSucess}

                    </MuiAlert>

                </Snackbar>
                <Typography className={classes.autoHeading}>
                    From Last Minute Cancelation to Filled Slot
                </Typography>
                <Typography className={classes.autoPara}>
                    Everybrand helps you fill cancelations and capture lost revenue in minutes
                </Typography>
                <Typography className={classes.autoSubPara}>
                    Waitlist Folder
                </Typography>
                <Box display="flex"
                    flexWrap="wrap" style={{ gap: "30px" }}>

                    {this.state.folders.map((item: any, dataindex: number) => {

                        return (
                            <>
                                <Card
                                    className={classes.autoCards}
                                    // onClick={() => this.openModal(item)}
                                    data-test-id="automationsCard"
                                >
                                    <IconButton
                                        onClick={() => {

                                            this.openModal(item?.attributes?.automation?.id)
                                        }
                                        }
                                        data-testId="editIcon"
                                        className="icon"
                                        style={{
                                            position: 'absolute',
                                            top: '8px',
                                            right: '8px',
                                            zIndex: 1,
                                            border: "1px solid black",
                                            borderRadius: "8px",
                                            width: "31px",
                                            height: "31px",
                                            padding: "10px 16px 10px 16px",
                                            gap: "8px"
                                        }}
                                    // Add your icon component here, for example:
                                    // icon={<YourIconComponent />}
                                    >
                                        <EditOutlinedIcon style={{ fill: "#000" }} />
                                    </IconButton>

                                    <Box className={classes.autosmileBox}>
                                        <img
                                            src={stepImageFirst}
                                            alt="simeimag"
                                            style={{ height: "56px", width: "56px" }}
                                        />
                                        <Typography className={classes.autoParaName}>
                                            {item.attributes.name}
                                        </Typography>
                                    </Box>

                                    <HrLine />
                                    <div className={classes.buttonDiv}>

                                        <Link to={{
                    pathname: `/WaitListChat/${item.id}`,
                    state: { name: item.attributes.name }
                }} >
                                            <Button className={classes.waitListButton}>Waitlist</Button>
                                        </Link>
                                        <Button data-testId="addContact" className={classes.AddContactButton} onClick={() => this.handleClickOpen(item.id, item.attributes.name)}>Add Contact</Button>
                                    </div>
                                </Card>


                            </>
                        )
                    })}
                    <Modal
                        open={this.state.createWebModal}
                        onClose={this.closeModal}

                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        data-test-id="templateEditModal"

                    >

                        <Box className={classes.modalBox}>
                            <>
                                <Formik
                                    data-test-id="templateEditForm"
                                    initialValues={{

                                        name: this.state?.automationTemplateData?.data?.attributes.name,
                                        business_name: this.state?.automationTemplateData?.data?.attributes.business_name,
                                        reminder_note: this.state?.automationTemplateData?.data?.attributes.reminder_note,
                                        message_template: this.state?.automationTemplateData?.data?.attributes.message_template
                                    }}
                                    enableReinitialize

                                    validationSchema={form_schema}
                                    onSubmit={(values: any, actions: any) => {


                                        this.createAutomationTemplateApi(values);

                                        actions.setSubmitting(false);
                                        actions.resetForm();

                                    }}

                                >
                                    {({
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        setFieldValue,
                                        values,
                                        touched,
                                        handleSubmit,
                                    }) => (
                                        <Form>
                                            <Typography className={classes.modalHeading}>
                                                Set up your automation
                                            </Typography>
                                            <IconButton
                                                onClick={this.closeModal}
                                                className={classes.crossIcon}
                                            >
                                                <img src={CloseIcon} alt="" />
                                            </IconButton>
                                            <Box sx={{ marginTop: "20px" }}>
                                                <Typography className={classes.inputName}>
                                                   Name*
                                                </Typography>
                                                <TextField

                                                    className={classes.inputField}
                                                    data-test-id="providerName"
                                                    variant="outlined"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={values.name}
                                                    onChange={(event: any) => {
                                                        handleChange("name")(event);
                                                        
                                                    }}

                                                    error={this.isFieldInvalid(touched, errors, 'name')}
                                                    onBlur={handleBlur("subject")}
                                                    inputProps={{
                                                        className: classes.customPlaceholder,

                                                    }}
                                                />
                                                {this.FormFieldError(errors.name, touched.name)}



                                                <Typography className={classes.inputName}>
                                                    Business Name*
                                                </Typography>
                                                <TextField
                                                    className={classes.inputField}
                                                    data-test-id="businessName"
                                                    variant="outlined"
                                                    placeholder="Business Name"
                                                    name="business_name"
                                                    value={values.business_name}
                                                    onChange={(event: any) => {

                                                        handleChange("business_name")(event);
                                                        
                                                    }}

                                                    error={this.isFieldInvalid(touched, errors, 'business_name')}
                                                    onBlur={handleBlur("subject")}
                                                    inputProps={{
                                                        className: classes.customPlaceholder,

                                                    }}
                                                />
                                                {this.FormFieldError(errors.business_name, touched.business_name)}




                                                <Typography className={classes.inputNameSendMesssage}>
                                                    Send This Message:
                                                </Typography>




                                                <Box onChange={handleChange("message_template")}
                                                    component={'div'}

                                                    data-test-id="message" className={classes.textareaAdd} dangerouslySetInnerHTML={{ __html: values.message_template }} />



                                                <TextField
                                                    className={classes.onlyinputField}
                                                    data-test-id="triggerCondition"
                                                    variant="outlined"
                                                    placeholder="Does tomorrow at 2pm work?"
                                                    value={values.reminder_note}
                                                    name="reminder_note"

                                                    onChange={handleChange("reminder_note")}

                                                    error={this.isFieldInvalid(touched, errors, 'reminder_note')}

                                                    onBlur={handleBlur("reminder_note")}

                                                    inputProps={{

                                                        className: classes.customPlaceholder
                                                    }}
                                                />
                                                {this.FormFieldError(errors.reminder_note, touched.reminder_note)}

                                            </Box>
                                            <Box className={classes.btnBox}>
                                                <Button
                                                    data-test-id="continueBtn"
                                                    type="submit"
                                                    variant="contained"
                                                    className={classes.continueBtn}
                                                //   onClick={() => handleSubmit()}
                                                >
                                                    SAVE
                                                </Button>
                                                <Button
                                                    data-test-id="endWaitListButton"

                                                    className={classes.endWaitlistButton}
                                                    onClick={this.openEndWaitListModal}
                                                >
                                                    End Waitlist Subscription
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </>


                        </Box>
                    </Modal>
                    <Modal
                    open={this.state.endWaitlistModal}
                    onClose={this.closeEndWaitListModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-test-id="templateEditModal"
                >
                    <Box className={classes.EndWaitlistmodalBox}>
                        <ResetModalTitle>
                            Would you like to end this folder´s waitlist subscription?
                            <CloseImgIcon data-test-id="closeEndModal" src={CloseIcon} alt="" onClick={this.closeEndWaitListModal} />
                        </ResetModalTitle>

                        <DeleteFooter>
                            <DeleteBtn data-test-id="resetButtonCall" type="button" onClick={this.closeEndWaitListModal} >Cancel</DeleteBtn>
                            <CancelBtn onClick={this.closeModal}>Confirm</CancelBtn>
                        </DeleteFooter>
                    </Box>
                </Modal>
                    <Card
                        className={classes.addautoCards}
                        onClick={this.addFolderModal}
                        data-test-id="automationsCard"
                    >
                        <Box className={classes.addfolder}  >
                            <IconButton
                                data-test-id="addFolderIcon"


                                className="icon"
                                style={{

                                    border: "1px solid black",
                                    borderRadius: "8px",
                                    width: "31px",

                                    height: "31px",

                                }}

                            >
                                <AddIcon style={{ fill: "#000" }} />
                            </IconButton>

                            <Typography className={classes.autoParaName}>
                                Add Waitlist Folder
                            </Typography>

                        </Box>
                    </Card>



                </Box>

                <Dialog
                    open={this.state.openSubscripttionDialogue}
                    //   keepMounted
                    PaperProps={{ style: { background: "#E2E8F0" } }}
                    maxWidth="xl"
                    onClose={this.handleClickOpen}
                >
                    <CreateWaitListContactWeb navigation={this.props.navigation} id={"CreateWaitListContactWeb"} contactId={this.state.waitListContactsData} createId={this.state.modalData} waitlListFoldersName={this.state.folderName} />
                </Dialog>

              
                <Dialog
                    open={this.state.addFolder}

                    classes={{
                        paper: classes.dialogPaper,

                    }}
                    //   keepMounted

                    maxWidth="xl"
                    onClose={this.addFolderModal}
                >
                    <DialogContent style={{ width: "1164px", padding: "0" }}>
                        <WaitListSubscriptionWeb id={""} navigation={this.props.navigation} createId={this.state.waitListContactsData} subscriptionListData={this.state.customizableSubscriptionData} addFolderModalFunction={this.addFolderModal} />

                    </DialogContent>
                </Dialog>

            </Container>
        );
    }

}

const HrLine = styled(Divider)({
    marginTop: "21px",
    marginRight: "27px",
    backgroundColor: "#000",
});
const ResetModalTitle = styled(Typography)({
    fontSize: "20px",
    position: "relative",
    marginBottom: "38px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
})

const DeleteFooter = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px"
})
const CancelBtn = styled("button")({
    backgroundColor: "#ffca29",
    padding: "16px 0",
    color: "black",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})

const DeleteBtn = styled("button")({
    backgroundColor: "black",
    padding: "16px 0",
    color: "white",
    textTransform: "capitalize",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})
const CloseImgIcon = styled("img")({
    position: "absolute",
    right: "-71px",
    bottom: "103px",
    cursor: "pointer",
    width: "24px",
    height: "26px",
    padding: "4px",
    borderRadius: "80px",
    justifyContent: "space-between",
    alignItems: "center",
    border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    background: "#FFF",
    boxShadow: " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
})


const styles = (theme: Theme) =>
    createStyles({
        modalBackdrop: {

            backgroundColor: "none", // Adjust the opacity and color as needed
        },
        customPlaceholderForTomorrowTextField: {
            "&::placeholder": {
                color: "#475569",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "26px",
                opacity: 1
            }
        },
        businesNameSpan: {

            width: "114px",
            height: "13.929px",

            justifyContent: "center",
            alignItems: "center",
            gap: "6px",

            borderRadius: "8px",
            color: "#000",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px",
            background: "#DADADA"
        },
        providerNameSpan: {

            width: "116px",
            height: "13.929px",
            /* padding: 6px 10px; */
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",

            background: "#DADADA",
            borderRadius: "8px",
            color: "#000",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px"
        },
        messageSpan: {

            width: "97px",
            height: "13.929px",

            justifyContent: "center",
            alignItems: "center",
            gap: "6px",

            background: "#DADADA",
            borderRadius: "8px",
            color: "#000",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px"
        },
        textArea: {
            width: "100%",
            margin: "10px 0",
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "8px",
            border: "1px solid #CBD5E1",

            background: "#FFF",
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "#979797",
                },
                "&:hover fieldset": {
                    borderColor: "#979797",
                },
                "& .MuiInputBase-input::placeholder": {
                    color: "#979797",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#ffca29",
                },
                "&.Mui-error fieldset": {
                    borderColor: "red",
                },
            },



        },
        textareaAdd: {
            width: "100%",
            marginTop: "16px",
            minHeight: "243px",
            padding: "8px 29px 16px 28px",
            border: "1px solid #CBD5E1",
            borderRadius: "8px",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Inter",
            "&:focus": {
                outline: "none",
                border: "2px solid #FFC629",
            },

        },
        errorBorder: {
            border: "1px solid red",
        },

        textAreaTypography: {
            color: "#475569",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "26px"

        },
        buttonDiv: {

        },
        dialogPaper: {
            // Customize the styles as needed

            overflow: "hidden",
        },
        customDialogPaperScrollPaper: {

            maxheight: "0px", // or any other value that suits your needs

        },
        autosmileBox: {
            marginTop: "31px",
            display: "flex",
            alignItems: "center"
        },
        addfolder: {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)"
        },
        autoHeading: {
            color: 'black',
            fontSize: "24px",
            fontFamily: 'Inter',
            fontWeight: 700,
            lineHeight: "24px",
            wordWrap: 'break-word',
            marginBottom: "10px",
        },
        autoPara: {
            color: 'black',
            fontSize: "16px",
            fontFamily: 'Inter',
            fontWeight: 400,
            lineHeight: "24px",
            wordWrap: 'break-word',
            marginBottom: "31px"

        },
        autoParaName: {
            color: "#000",

            fontFamily: "Inter",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            marginLeft: "12px"

        },
        autoSubPara: {
            color: 'black',
            fontSize: "20px",
            fontFamily: 'Inter',
            fontWeight: 700,
            lineHeight: "24px",
            wordWrap: 'break-word',
            marginBottom: "17px"
        },
        autoCards: {
            padding: "15px 18px 24px 36px",
            width: "310px",
            height: "230px",
            position: "relative",
            borderRadius: "20px",
            background: "#FFF",
            "&:hover": {
                cursor: "pointer",
                boxShadow: "",
            },
        },
        addautoCards: {
            padding: "15px 18px 24px 36px",
            width: "310px",
            height: "230px",
            position: "relative",
            borderRadius: "20px",
            background: "#FFF",
            "&:hover": {
                cursor: "pointer",
                boxShadow: "",
            },
        },
        waitListButton: {
            width: "110px",
            height: "43px",
            borderRadius: "5px",
            background: "#FFC629",
            textTransform: "none",
            marginBottom: "24px",
            marginTop: "34px",
            color: "#000",
            padding: "9px 27px 10px 28px",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            "&:hover": {
                cursor: "pointer",

                background: "#FFC629",

            },
        },
        AddContactButton: {
            width: "110px",
            height: "43px",
            borderRadius: "5px",
            background: "#FFC629",
            marginBottom: "24px",
            marginTop: "34px",
            marginLeft: "25px",
            color: "#000",
            padding: "8px 6px 10px 7px",
            textTransform: "none",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            "&:hover": {
                cursor: "pointer",

                background: "#FFC629",

            },
        },
        waitListContainer: {
            padding: "50px 0 0 90px",
        },
        continueBtn: {
            display: "flex",
            width: "232px",
            height: "56px",
            padding: "16px 49px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            background: "#FFC629",
            color: "#0F172A",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px"
        },
        customPlaceholder: {
            "&::placeholder": {
                color: " #64748B",

                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22px",
                opacity: 1
            }
        },
        inputField: {
            width: "100%",

            margin: "10px 0",
            marginBottom: "12px",
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "1px solid #CBD5E1",
                },
                "&:hover fieldset": {
                    borderColor: "1px solid #CBD5E1",
                },
                "& .MuiInputBase-input::placeholder": {
                    color: "#979797",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#ffca29",
                },
                "&.Mui-error fieldset": {
                    borderColor: "red",
                },
            },
        },
        onlyinputField: {
            width: "100%",
            margin: "10px 0",
            marginTop: "26px",
            marginBottom: "12px",
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "1px solid #CBD5E1",
                },
                "&:hover fieldset": {
                    borderColor: "1px solid #CBD5E1",
                },

                "&.Mui-focused fieldset": {
                    borderColor: "#ffca29",
                },
                "&.Mui-error fieldset": {
                    borderColor: "red",
                },
            },
        },

        inputNameSendMesssage: {
            color: "#334155",
            marginBottom: "12px",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px"
        },
        inputName: {
            color: "#334155",

            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px"
        },
        crossIcon: {
            top: "10px",
            right: "10px",
            position: "absolute",
        },
        modalHeading: {
            fontFamily: "Inter",
            marginBottom: "48px",
            fontStyle: "normal",
            color: "#000",
            textAlign: "center",
            fontSize: "24px",
            fontWeight: 700, // Note: Numeric values for font weight don't need quotes
            lineHeight: "32px",
            letterSpacing: "-0.12px",
        },
        modalBox: {

            position: "relative",
            backgroundColor: "#fff",
            padding: "40px 64px 52px 64px",
            width: "100%",
            maxWidth: "555px",
            borderRadius: "0px 0px 40px 0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
            maxHeight: "100vh",
            overflow: "auto"
        },
        EndWaitlistmodalBox: {
            position: "relative",
            backgroundColor: "#fff",
            padding: "85px 106px 31px 72px",
            width: "100%",
            maxWidth: "636px",
            borderRadius: "0px 0px 40px 0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
        },
        endWaitlistButton: {
            color: "#334155",

            textAlign: "right",
            fontFamily: "Inter",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px",
            textTransform: "none"
        },
        btnBox: {
            margin: "10px 0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        customDialogContent: {
            // Override or add your own styles here
            padding: "0px", // For example, change the padding
            overflow: "none"
        },
    });

export default withStyles(styles)(WaitList);
// Customizable Area End
