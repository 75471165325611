Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.endPointApiGetEUProfileBrands = "bx_block_dashboard/dashboards/end_user_data";
exports.contentTypeApiGetEUProfileBrands = "application/json";
exports.methodTypeApiGetEUProfileBrands = "GET";

exports.endPointApiGetEUProfile = "bx_block_profile/end_user_profile"
exports.followBrandApiEndpoint = "bx_block_followers/follows/create_follower";
exports.unfollowBrandApiEndpoint = "bx_block_followers/follows/";
exports.contentTypeApiGetEUProfile = "application/json";
exports.methodTypeApiGetEUProfile = "GET";
exports.methodTypeApiUnfollowUser = "DELETE";
exports.endPointApiProfileDashboard = "/bx_block_profile/profile_dashboard";
exports.methodApiProfileDashboard = "GET"

exports.methodTypeApiGetUserProfileImage ="multipart/form-data";
exports.placeHolderEmail = "Email";
exports.EU_AUTH_TOKEN = "EUAuthToken"
exports.AUTH_TOKEN = "authToken"

exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.user_profile = "bx_block_profile/user_profile"
exports.user_profile_view = "bx_block_profile/user_details"
exports.update_profile = "/bx_block_profile/update_profile"
exports.user_profileMethod = "GET"
exports.update_profileMethod = "PUT"
exports.httpPostMethod = "POST"
exports.upload_image = "bx_block_profile/upload_image"
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
// Customizable Area End

