// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
} from "@material-ui/core";
import {
  ThemeProvider,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import EndUserOtpAuthController from "./EndUserOtpAuthController";
import {
  commonStyles,
  themeWhite,
  themeYellow,
} from "../../../framework/src/enduserTheme";
import MobileLoader from "../../../components/src/MobileLoader";
import { Formik } from "formik";
import * as yup from "yup";
import { backArrow } from "./assets";
// @ts-ignore
import OtpInput from "react-otp-input";
import CustomTimer from "../../../components/src/CustomTimer";
import {
  handleAndConditions,
  handleOrConditions,
} from "../../../components/src/CommonFunctions";

export class EndUserOtpAuth extends EndUserOtpAuthController {
  getFormattedNumber(userPhone: any) {
    return (
      userPhone.slice(0, 1) +
      userPhone.slice(1, 4) +
      "-" +
      userPhone.slice(4, 7) +
      "-" +
      userPhone.slice(7)
    );
  }
  render() {
    const { classes }: any = this.props;
    const validation_Schema = yup.object({
      userOtp: yup
        .string()
        .required("Otp is required")
        .matches(/^\d+$/, "Only numbers are allowed")
        .min(6, "must be atleast 6 digits"),
    });

    return (
      <ThemeProvider theme={this.state.themeSwitch ? themeYellow : themeWhite}>
        <MobileLoader loading={this.state.loading} />
        <CssBaseline />
        <Container
          maxWidth="sm"
          className={classes.mainContainer}
          data-test-id="mainContainer"
        >
          <Formik
            initialValues={{
              userOtp: "",
            }}
            validationSchema={validation_Schema}
            onSubmit={(values) => {
              this.checkRedirections(values.userOtp);
            }}
            data-test-id="mainForm"
          >
            {({ errors, touched, handleChange, values, handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.mainFormHtml}>
                <Box className={classes.backIconBox}>
                  <IconButton
                    data-test-id="iconButtonBack"
                    onClick={this.navigateToEndU}
                  >
                    <img src={backArrow} alt="back_arror" />
                  </IconButton>
                </Box>
                <Typography
                  data-test-id="mainText"
                  align="center"
                  className={classes.centerText}
                >
                  {handleOrConditions(
                    this.state.isSignUp,
                    this.state.thoughts,
                    `Enter the code we sent to: + ${this.getFormattedNumber(
                      this.state.userPhone
                    )}`
                  )}
                </Typography>
                <Box>
                  {handleOrConditions(
                    this.state.isSignUp,
                    <Typography className={classes.codeSignup}>
                      Enter the code we sent to: +
                      {this.getFormattedNumber(this.state.userPhone)}
                    </Typography>,
                    null
                  )}
                  <Box className={classes.otpBox}>
                    <OtpInput
                      numInputs={6}
                      inputStyle={{
                        margin: 5,
                        height: 50,
                        width: 50,
                        borderRadius: 5,
                        fontWeight: "bold",
                        backgroundColor: "#ffffff",
                        color: "#64748B",
                        borderColor: handleOrConditions(
                          this.state.otpError,
                          "red",
                          "#000"
                        ),
                      }}
                      inputType="number"
                      data-test-id="userOtp"
                      containerStyle="otpConntainer"
                      onChange={handleChange("userOtp")}
                      value={values.userOtp}
                      renderInput={(props) => <input {...props} />}
                    />
                    {handleAndConditions(
                    touched.userOtp,
                    errors.userOtp,
                    <Typography className={classes.errorMsg}>
                      {errors.userOtp}
                    </Typography>
                  )}
                  {handleOrConditions(
                    this.state.otpError !== "",
                    <Typography data-test-id="otp-error-message" className={classes.errorMsg}>
                      {this.state.otpError}
                    </Typography>,
                    null
                  )}
                  </Box>
                </Box>
                <Box>
                  <Typography className={classes.phntext}>
                    <span
                      data-test-id="changePhoneNumber"
                      onClick={() => this.naviageToPhoneNumber()}
                    >
                      Change the phone number
                    </span>
                  </Typography>
                  <CustomTimer
                    data-test-id="CustomTimer"
                    resendOtp={this.resendOtp}
                  />
                </Box>
                <Button
                  data-test-id="submitButton"
                  type="submit"
                  className={handleOrConditions(
                    values.userOtp,
                    classes.buttonPrimary,
                    classes.buttonDisable
                  )}
                >
                  Continue
                </Button>
              </form>
            )}
          </Formik>
        </Container>
      </ThemeProvider>
    );
  }
}

const styles = () =>
  createStyles({
    codeSignup: {
      fontSize: "15px",
      textAlign: "center",
      margin: "5px 0px",
    },
    mainFormHtml: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    otpBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    phntext: {
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0px",
      textAlign: "center",
      paddingBottom: "30px",
    },
    errorMsg: {
      ...commonStyles.errorMsg,
    },
    marginAuto: {
      margin: "auto",
    },
    countryCode: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
    },
    flexDisplay: {
      display: "flex",
      flexDirection: "row",
    },
    lockText: {
      fontSize: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "14px",
      padding: "0 5px",
    },
    lockIcon: {
      height: "16px",
      width: "16px",
      margin: "auto 5px",
    },
    nameInput: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
      border: "none",
      outline: "none",
      background: "transparent",
      padding: "10px 5px",
      margin: "20px 0",
      width: "90%",
      borderBottom: "1px solid #000",
    },
    textOne: {
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "14px",
      padding: "10px 0",
    },
    textTwo: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14px",
      padding: "5px 0",
      textAlign: "center",
    },
    buttonPrimarySignUp: {
      ...commonStyles.mobileButton,
      marginBottom: "20px",
      width: "100%",
    },
    centerText: {
      color: "#000",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
    },
    mainContainer: {
      ...commonStyles.mobileContainer,
      paddingLeft: "29px",
      paddingRight: "29px",
    },
    buttonPrimary: {
      ...commonStyles.mobileButton,
      backgroundColor: "#000000",
    },
    buttonDisable: {
      ...commonStyles.mobileButton,
      backgroundColor: "#7f6415",
      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#7f6415",
      },
    },
    backIconBox: {
      margin: "10px 0",
      position: "relative",
      right: "20px",
    },
  });
export default withStyles(styles)(EndUserOtpAuth);
// Customizable Area End
