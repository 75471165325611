// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Card,
  Box,
  IconButton,
  Popover,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  TextareaAutosize,
  Snackbar
} from "@material-ui/core";
import TeamMatesController from "./TeammatesController.web";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import { EverybrandIcon, resendIcon, trashIcon } from "./assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { handleAndConditions } from "../../../components/src/CommonFunctions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik } from "formik";
import * as yup from "yup";
import MuiAlert from "@material-ui/lab/Alert";

export const configJSON = require("./config")

const webStyles = {
  phoneInput: {
    width: "100%",
    height: "56px",
    fontWeight: 400,
    fontSize: "12px !importent",
    color: "#64748B",
    // border:"1px solid #FFC629",
    margin: "10px 0",
  },
};

const validation_Schema_newTeammate = yup.object({
  first_name: yup
    .string()
    .required("First Name is required")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed"),
  last_name: yup
    .string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  phone: yup
    .string()
    .required("Mobile number is required")
    .min(10, "Must be at least 10 digits"),
});

export class Teammates extends TeamMatesController {
  render() {
    const open = Boolean(this.state.anchorEll);
    const id = open ? "simple-popover" : undefined;
    const { classes }: any = this.props;

    return (
      <Container maxWidth={false} className={classes.teammatesMainCOntainer}>
         <Snackbar
          open={this.state.showAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={this.state.errorAlertMessage ? "error" : "success"}
          >
            {this.state.showSuccess}
          </MuiAlert>
        </Snackbar>
        <Modal
          open={this.state.addTeammatesModal}
          onClose={this.closeTeammateModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div className={classes.addteammadeModalBox}>
            <Typography className={classes.addteammadetitle}>
              Teamwork makes your customer's dreams work
            </Typography>
            <Typography className={classes.addteammadedescription}>
              Invite members of your team to make customer communication a
              breeze. They will be invited as Page Admins with the ability to
              engage with customers through your platform.
            </Typography>
            <Typography className={classes.addteammadedescription}>
              Your team
            </Typography>
            <Box className={classes.addteammadeinvitesContainer}>
              <TextField
                data-test-id="employeeOneInput"
                variant="outlined"
                className={classes.addteammadeinviteInput}
                fullWidth
                placeholder={`Employee 1's email`}
                value={this.state.employeeOne}
                onChange={(e) => {
                  this.setState({ employeeOne: e.target.value });
                }}
              />
              <TextField
                data-test-id="employeeTwoInput"
                variant="outlined"
                className={classes.addteammadeinviteInput}
                fullWidth
                placeholder={`Employee 2's email`}
                value={this.state.employeeTwo}
                onChange={(e) => {
                  this.setState({ employeeTwo: e.target.value });
                }}
              />
              <TextField
                data-test-id="employeeThreeInput"
                variant="outlined"
                className={classes.addteammadeinviteInput}
                fullWidth
                placeholder={`Employee 3's email`}
                value={this.state.employeeThree}
                onChange={(e) => {
                  this.setState({ employeeThree: e.target.value });
                }}
              />
              <TextField
                data-test-id="employeeFourInput"
                variant="outlined"
                className={classes.addteammadeinviteInput}
                fullWidth
                placeholder={`Employee 4's email`}
                value={this.state.employeeFour}
                onChange={(e) => {
                  this.setState({ employeeFour: e.target.value });
                }}
              />
            </Box>
            <Box className={classes.addTeammateBtnContianer}>
              <Button
                data-test-id="closeTeammateModalBtn"
                onClick={() => this.closeTeammateModal()}
                className={classes.modalBtnDark1}
              >
                Skip
              </Button>
              <Button
                data-test-id="inviteBtn"
                onClick={() => this.handleBulkInvite()}
                id="sendInviteButton"
                className={classes.modalBtn3}
              >
                Invite
              </Button>
            </Box>
          </div>
        </Modal>
        <Modal
          open={this.state.teammatesModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div className={classes.teammadeModalBox}>
            <Typography
              className={classes.infotextHdg}
              style={{ textAlign: "center" }}
            >
              Are you sure you want to remove user?
            </Typography>
            <Button
              data-test-id="No_button"
              onClick={() => this.closeModal()}
              className={classes.modalBtnDark}
            >
              Cancel
            </Button>
            <Button
              data-test-id="yesBtn"
              onClick={() => {
                this.handleRemoveUser();
              }}
              className={classes.modalBtn}
            >
              Confirm
            </Button>
          </div>
        </Modal>
        <Modal
          open={this.state.resendInviteModal}
          onClose={this.closeResendIviteModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div className={classes.teammadeModalBox}>
            <IconButton className={classes.crossIconBtn} onClick={this.closeResendIviteModal}>
              <ClearRoundedIcon />
            </IconButton>
            <Typography className={classes.infotextHdg}>
              {this.state.isResesndInvite
                ? "Resend inivte to your friend"
                : "Resend password email"}
            </Typography>
            <Typography className={classes.infotext}>Send to</Typography>
            <TextField
              fullWidth
              id="outlined-select-currency-native"
              select
              data-test-id="nameDropdown"
              value={this.state.userItem?.attributes?.name}
              onChange={(e) => this.handleNameDropdownChange(e,this.state.userItem)}
              SelectProps={{
                native: true,
                style: {
                  color: '#0F172A',
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 700
                }
              }}
              variant="outlined"
            >
              {this.state.teammates.map((option: any) => (
                <option
                  key={option.attributes.name}
                  value={option.attributes.name}
                >
                  {option.attributes.name}
                </option>
              ))}
            </TextField>
            {!this.state.isResesndInvite && (
              <>
                <Typography className={classes.infotext}>Subject</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  data-test-id="legal_business_name"
                  fullWidth
                />
              </>
            )}
            <Typography style={{padding:"30px 0 5px 0"}} className={classes.infotext}>Message:</Typography>
            <TextareaAutosize
              value={this.state.invitationSubject}
              data-test-id="textAreaField"
              minRows={5}
              className={classes.textAreaAutoSize}
            />
            <Typography style={{padding:"30px 0 5px 0"}} className={classes.infotext}>Autolink</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              data-test-id="legal_business_name"
              fullWidth
              disabled
              value={`${window.location.origin}${this.state.autoLink}`}
            />
            <div className={classes.inviteBtnContianer}>
              <Button
                data-test-id="sendBtn"
                onClick={() => {
                  this.handleModalSendClick();
                }}
                className={classes.modalBtn2}
              >
                {this.state.isResesndInvite ? "Send Invite" : "Send email"}
              </Button>
            </div>
          </div>
        </Modal>
        <Box className={classes.everyBrandBox}>
          <Typography className={classes.webHeading}>Teammates</Typography>
          <img src={EverybrandIcon} alt="" />
        </Box>
        <Button
          variant="contained"
          data-test-id="addnewWebBtnTeam"
          className={classes.addnewWebBTnTEam}
          onClick={() => this.openAddNewTeammatesModal()}
        >
          Add new team member
        </Button>
        <Typography className={classes.teamParaBtnB}>TEAMMATES</Typography>
        {this.state.teammates.map((item: any, index: any) => (
          <Card
            key={item.id}
            className={classes.teamCards}
            data-test-id="teamCards"
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box className={classes.smileBoxteam}>
                <PersonIcon style={{ height: "32px", width: "32px" }} />
              </Box>
              <Box sx={{ margin: "0 20px" }}>
                <Typography
                  className={classes.cardHeadingTeam}
                  data-test-id="cardHeadingTeam"
                >
                  {item.attributes.name}
                </Typography>
                <Typography
                  className={classes.cardParaTeam}
                  data-test-id="cardParaTeam"
                >
                  {item?.attributes?.websites[0]?.url}
                </Typography>
              </Box>
            </Box>
            <div>
              <span data-test-id="cardInviteSentTxt" className={classes.cardHeadingTeam}>
                {item.attributes.is_invitation_sent ?  configJSON.invitationSentTitle : ""}
              </span>
              <IconButton
                onClick={(e) => this.handleClickPopOver(e, item)}
                data-test-id="threeDotIconTeammate"
                aria-label="post-settings"
                className={classes.postSettingsBtn}
              >
                <MoreVertIcon />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEll}
                onClose={this.handleClosePopOver}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                classes={{
                  paper: classes.popOverPaper,
                }}
              >
                <StyledMenuItem
                  data-test-id="resendInvite"
                  onClick={() => this.openResendInviteModal(true)}
                >
                  <img src={resendIcon} alt="" className={classes.menuIcons} />{" "}
                  <ListItemText primary="Resend Invite" />
                </StyledMenuItem>
                <StyledMenuItem
                  data-test-id="removeUser"
                  onClick={() => this.openModal()}
                >
                  <img src={trashIcon} className={classes.menuIcons} alt="" />{" "}
                  <ListItemText primary={"Remove User"} />
                </StyledMenuItem>
              </Popover>
            </div>
          </Card>
        ))}
        {!this.state.teammates.length && !this.state.loading && (
          <Box>
            <Typography align="center" className={classes.noRecord}>
              No Teammate found
            </Typography>
          </Box>
        )}
        <Modal
          open={this.state.addNewTeammateModal}
          onClose={this.closeAddNewTeammateModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="newTeammateModal"
        >
          <Box
            className={classes.newContectModalBox}
            data-test-id="newTeammateModalMainBox"
          >
            <IconButton
              className={classes.crossIconBtn}
              onClick={this.closeAddNewTeammateModal}
            >
              <ClearRoundedIcon />
            </IconButton>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
              }}
              validationSchema={validation_Schema_newTeammate}
              onSubmit={(values) => {
                this.addNewTeammateCall(values);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit,
              }) => (
                <>
                  <Typography className={classes.addnewconH}>
                    Add New Teammate
                  </Typography>
                  <Box>
                    <Typography className={classes.inputLable}>
                      First Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      className={classes.addNewContectInput}
                      name="first_name"
                      data-test-id="secondStepInput"
                      placeholder="First Name"
                      value={values.first_name}
                      onChange={handleChange("first_name")}
                      onBlur={handleBlur("first_name")}
                    />
                    {handleAndConditions(
                      touched.first_name,
                      errors.first_name,
                      <Typography className={classes.errorMsg}>
                        {errors.first_name}
                      </Typography>
                    )}
                    <Typography className={classes.inputLable}>
                      Last Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      className={classes.addNewContectInput}
                      name="last_name"
                      data-test-id="secondStepInput"
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleChange("last_name")}
                      onBlur={handleBlur("last_name")}
                    />
                    {handleAndConditions(
                      touched.last_name,
                      errors.last_name,
                      <Typography className={classes.errorMsg}>
                        {errors.last_name}
                      </Typography>
                    )}
                    <Typography className={classes.inputLable}>
                      Email
                    </Typography>
                    <TextField
                      variant="outlined"
                      className={classes.addNewContectInput}
                      name="email"
                      data-test-id="secondStepInput"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                    />
                    {handleAndConditions(
                      touched.email,
                      errors.email,
                      <Typography className={classes.errorMsg}>
                        {errors.email}
                      </Typography>
                    )}
                    <Typography className={classes.inputLable}>
                      Mobile Number
                    </Typography>
                    <Box style={{ padding: "5px 0" }}>
                      <PhoneInput
                        data-test-id="phone"
                        country={"us"}
                        value={values.phone}
                        onChange={handleChange("phone")}
                        onBlur={handleBlur("phone")}
                        placeholder="Enter Phone Number"
                        inputStyle={webStyles.phoneInput}
                        specialLabel={""}
                      />
                    </Box>
                    {handleAndConditions(
                      touched.phone,
                      errors.phone,
                      <Typography className={classes.errorMsg}>
                        {errors.phone}
                      </Typography>
                    )}
                    <Button
                      onClick={() => handleSubmit()}
                      className={classes.addBtn}
                      variant="contained"
                      data-test-id="addContactBtn"
                    >
                      Send Invite
                    </Button>
                    <Button
                      onClick={this.closeAddNewTeammateModal}
                      className={classes.backBtn}
                      variant="contained"
                      data-test-id="backBtn"
                    >
                      Back
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        </Modal>
      </Container>
    );
  }
}

export const commonCss: any = {
  button: {
    backgroundColor: "#FFC629",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    padding: "5px 40px",
  },
};

const styles = (theme: Theme) =>
  createStyles({
    addBtn: {
      textTransform: "none",
      width: "100%",
      backgroundColor: "#FFC629",
      color: "#000",
      fontSize: "16px",
      fontWeight: 600,
      padding: "15px 0",
      margin: "10px 0",
      fontFamily:"Inter",
      "&:hover": {
        backgroundColor: "#FFC629",
        color: "#000",
      },
      borderRadius: "8px",
    },
    backBtn: {
      width: "100%",
      backgroundColor: "#000",
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: 600,
      padding: "15px 0",
      margin: "10px 0",
      "&:hover": {
        backgroundColor: "#000",
        color: "#ffffff",
      },
      borderRadius: "8px",
      fontFamily:"Inter"
    },
    inputLable: {
      fontSize: "17px",
      lineHeight: "22px",
      color: "#334155",
      paddingTop: "20px",
      paddingBottom:"10px",
      fontWeight:600,
      fontFamily:"Inter"
    },
    addnewconH: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",
      fontFamily:"Inter"
    },
    errorMsg: {
      color: "red",
      fontSize: "12px",
      paddingLeft: "2px",
    },
    addNewContectInput: {
      width: "100%",
    },
    newContectModalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "40px 50px",
      width: "30%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 24px 0",
    },
    crossIconBtn: {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    everyBrandBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    teamParaBtnB: {
      color: "#64748B",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "18px",
      margin: "50px auto",
      fontFamily:"Inter"
    },
    menuIcons: {
      paddingRight: "10px",
    },
    noRecord: {
      color: "#64748B",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "18px",
    },
    smileBoxteam: {
      backgroundColor: "#FFC629",
      borderRadius: "50%",
      padding: "0 7px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    addteammadetitle: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 700,
      marginBottom: "10px",
    },

    addteammadedescription: {
      fontSize: "16px",
      fontWeight: 700,
      marginBottom: "10px",
    },
    teamCards: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderRadius: "8px",
      background: "#FFF",
      padding: "24px",
      margin: "10px 0",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    addnewWebBTnTEam: {
      padding: "10px 48px 10px 50px",
      borderRadius: "8px",
      background: "#FFC629",
      margin: "20px 0",
      color: "#0F172A",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      fontFamily:"Inter"
    },
    teammatesMainCOntainer: {
      padding: "50px",
    },
    addteammadeModalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "32px 48px 24px",
      width: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 40px 0",
    },
    webHeading: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      fontFamily:"Inter"
    },
    cardHeadingTeam: {
      color: "#0F172A",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily:"Inter"
    },
    textArea: {
      width: "100%",
    },
    textAreaAutoSize:{
      width:"100%",
      fontSize:"16px",
      lineHeight:"26px",
      fontFamily:"inter",
      fontWeight:600,
      padding:"19px",
      borderRadius:"8px",
      borderColor:"#E2E8F0"
    },
    cardParaTeam: {
      color: "#64748B",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily:"Inter"
    },
    popOverPaper: {
      padding: "4px 4px 2px 4px",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      minWidth: "192px",
      background: "#FFF",

      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    postSettingsBtn: {
      color: "#0F172A",
    },
    addTeammateBtnContianer: {
      display: "flex",
      justifyContent: "space-around",
    },
    teammadeModalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "32px 48px 24px",
      width: "555px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 32px 8px",
    },
    infotext: {
      font: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      padding: "30px 0",
    },
    inviteBtnContianer: {
      paddingTop: "20px",
    },
    addteammadeinvitesContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "17px",
      paddingRight: "23px",
      overflowY: "auto",

      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: "39px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    addteammadeinviteInput: {
      height: "40px",
      width: "100%",

      "& *": {
        fontSize: "16px",
        fontWeight: 700,
      },

      "& > *": {
        height: "100%",
        borderRadius: "20px",
      },
    },
    infotextHdg: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      fontFamily: "Inter",
      textAlign:"center",
      color:"#000000"
    },
    modalBtn: {
      ...commonCss.button,
      padding: "20px 80px",
      width: "45%",
      margin: "10px 0",
      marginLeft: "20px",
    },
    modalBtn2: {
      ...commonCss.button,
      padding: "20px 80px",
      margin: "10px 0",
      color:"#0F172A"
    },
    modalBtnDark: {
      ...commonCss.button,
      backgroundColor: "#000",
      color: "#FFFFFF",
      padding: "20px 80px",
      margin: "10px 0",
      width: "45%",

      "&:hover": {
        color: "#000",
      },
    },
    modalBtn3: {
      ...commonCss.button,
      padding: "20px 80px",
      margin: "10px 0",
    },
    modalBtnDark1: {
      ...commonCss.button,
      backgroundColor: "#000",
      color: "#FFFFFF",
      padding: "20px 80px",
      margin: "10px 0",

      "&:hover": {
        color: "#000",
      },
    },
  });
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F172A",
      },
    },
  },
}))(MenuItem);

export default withStyles(styles)(Teammates);
// Customizable Area End
