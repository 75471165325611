// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getErrorMessage } from "../../../components/src/CommonFunctions";

export const configJSON = require("./config");

const staticTemplate = {
  id: "7",
  type: "automations",
  attributes: {
    id: 7,
    name: "Third automation new",
    business_name: null,
    trigger_condition: "When user loggin",
    action_type: "send email",
    message_template: {
      content:
        "Thank you for sign up our team will get back to you as soon as possible",
      subject: "new user sign up message",
    },
  },
};

export interface Props {
  navigation: any;
  id: string;
  classes: any
}

interface S {
  defaultautomations: any;
  userautomtions:any,
  displayItem:number,
  isModalOpen: boolean;
  loading: boolean;
  selectedTemplate: any;
  showAlert: boolean;
  alertMsg: string;
  isdefaultAutomation: boolean;
}
interface SS {
  id: any;
}
export default class AutomationController extends BlockComponent<Props, S, SS> {
  getAutomationTemplatesApiId: any = "";
  updateAutomationTemplateApiId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      defaultautomations: [],
      userautomtions:[],
      displayItem: 6,
      isModalOpen: false,
      loading: false,
      selectedTemplate: staticTemplate,
      showAlert: false,
      alertMsg: "",
      isdefaultAutomation: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleClose = () => {
    this.setState({ isModalOpen: false });
  }

  openModal(data: any) {
    console.log(data);
    this.setState({ isModalOpen: true, selectedTemplate: data });
  }
  setAutomation(val: boolean) {
    this.setState({ isdefaultAutomation: val })
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getAutomationTemplatesApiId) {          
          this.setState({ defaultautomations: responseJson.data.default_automations.data });
          this.setState({ userautomtions: responseJson.data.user_automations.data })
        }

        if (apiRequestCallId === this.updateAutomationTemplateApiId) {
          this.setState({
            isModalOpen: false,
            alertMsg: "Template updated successfully! ",
            showAlert: true,
          });
          this.getTemplates();

          setTimeout(() => {
            this.setState({showAlert:false})
          },2000)
        }
      } else if (responseJson.errors) {
        this.setState({ isModalOpen: false });
        const err = getErrorMessage(responseJson.errors[0]);
        alert(err);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    this.getTemplates();
  }

  getTemplates() {
    const headerData = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAutomationTemplatesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.automationTemplatesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  onUpDateTemplate(value: any) {
    const httpBody = {
      automation: {
        name: this.state.selectedTemplate.attributes.name,
        trigger_condition:
          this.state.selectedTemplate.attributes.trigger_condition,
        action_type: this.state.selectedTemplate.attributes.action_type,
        message_template: {
          content: value.content,
          subject: value.subject,
        },
      },
    };

    const headerData = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAutomationTemplateApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.automationTemplatesApiEndPoint +
        "/" +
        this.state.selectedTemplate.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  updateSeemore() {

    const { displayItem } = this.state;
    this.setState({ displayItem: displayItem + 6 });
  }
  updateAutomationActive(id: number, active: boolean) {
    const headerData = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    const httpBody = {
      automation: {
        is_active: active   
      }
    };
    // Log the payload to verify
    console.log('Payload:', JSON.stringify(httpBody));
  
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateAutomationTemplateApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.automationTemplatesApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}
// Customizable Area End
