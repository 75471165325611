// Customizable Area Start
import React, { useEffect, ChangeEvent, useState } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  withStyles,
  Box,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";

type Props = {
  invitesContacts: string[];
  classes: any;
  sendInvites: () => void;
  closeModal: () => void;
  setInvitesContacts: (invites: string[]) => void;
};

export const AfterSignupInvites = ({
  invitesContacts,
  classes,
  sendInvites,
  closeModal,
  setInvitesContacts
}: Props) => {

  const handleSendInvites = () => {
    sendInvites();
  };

  const inputs = [];

  for (let i = 0; i < 4; i++) {
    inputs.push(
      <TextField
        key={i}
        id={`inviteInput-${i}`}
        variant="outlined"
        className={classes.inviteInput}
        value={invitesContacts[i]}
        fullWidth
        placeholder={`Employee  #${i + 1}´s mobile phone or email`}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          const values = [...invitesContacts]
          values[i] = event.target.value;
          setInvitesContacts(values)
        }}
      />
    );
  }

  return (
    <Box className={classes.container} id="policy">
      <Typography className={classes.title}>
        Teamwork makes your customer's dreams work
      </Typography>
      <Typography className={classes.description}>
        Invite members of your team to make customer communication a breeze.
        They will be invited as Page Admins with the ability to engage with
        customers through your platform.
      </Typography>
      <Typography className={classes.description}>Your team</Typography>
      <Box className={classes.invitesContainer}>{inputs}</Box>
      <Box className={classes.controllsContainer}>
        <span id="skipInviteButton" onClick={closeModal} className={classes.skipButton}>
          Skip
        </span>
        <Button
        id="sendInviteButton"
        className={classes.inviteButton}
        onClick={handleSendInvites}
      >
        Invite
      </Button>
      </Box>
      
    </Box>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flexShrink: 1,
      gap: "15px",
      color: "#0F172A",
      overflowY: "auto",
    },

    title: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 700,
    },

    description: {
      fontSize: "16px",
      fontWeight: 700,
    },

    invitesContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "17px",
      paddingRight: "23px",
      overflowY: "auto",

      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: "39px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    inviteInput: {
      height: "40px",
      width: "100%",

      "& *": {
        fontSize: "16px",
        fontWeight: 700,
      },      

      "& > *": {
        height: "100%",
        borderRadius: "20px"
      }
    },

    controllsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    skipButton: {
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
    },

    inviteButton: {
      alignSelf: "flex-end",
      width: "253px",
      height: "44px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#FFC629",
      fontWeight: 700,
      fontSize: "16px",
    },
  });

export default withStyles(styles)(AfterSignupInvites);
// Customizable Area End
