// Customizable Area Start
import React, { CSSProperties } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  withStyles,
  Switch,
} from "@material-ui/core";
import { message, socialMedia } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import NewPostSettingsController, {
  Props,
} from "./NewPostSettingsController.web";
import Select from "react-select";

const styles = (theme: Theme) =>
  createStyles({
    settingsModalContainer: {
      display: "flex",
      position: "relative",
      width: "809px",
      padding: "60px 44px 40px",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: "22px",
      flexShrink: 1,
      flexGrow: 0,
      background: "#FFF",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
    },

    closeSettingsModalButton: {
      position: "absolute",
      right: "11px",
      top: "20px",
    },

    settingTitle: {
      flexGrow: 1,
      fontSize: "24px",
      fontWeight: 700,
      textAlign: "left",
    },

    socialSelect: {
      width: "280px",
      height: "43px",
    },

    postToSocialsContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "133px",
      borderTop: "1px solid #AEAEB2",
      borderBottom: "1px solid #AEAEB2",
    },

    settingsContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "40px",
    },

    messageIcon: {
      width: "57px",
      height: "57px",
    },

    allowCommentsContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    switch: {
      width: "60px",
      height: "40px",
      padding: "5px",

      "& .MuiSwitch-thumb": {
        width: "26px",
        height: "26px",
        color: "#FFF",
      },

      "& .MuiSwitch-track": {
        border: "1px solid #D1D3D9",
        borderRadius: "14px",
      },

      "& .MuiSwitch-switchBase": {
        padding: "7px",
      },

      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "#FFF",
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#FFC629",
        opacity: 1,
      },
    },

    addSocialsContainer: {
      display: "flex",
      width: "100%",
      padding: "35px 33px 20px 40px",
      alignItems: "center",
      justifyContent: "space-between",
    },

    addSocialsText: {
      fontSize: "16px",
      color: "#000",
    },

    addSocialButton: {
      width: "227px",
      minWidth: "227px",
      height: "44px",
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      color: "#000",
      textTransform: "none",
      fontWeight: 700,
    },
  });

const webStyles: Record<string, CSSProperties> = {};

class NewPostAddCaption extends NewPostSettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      closeModal,
      allowComments,
      isCommentsAllowed,
    } = this.props;

    return (
      <Container
        id="newPostAdvansedSettingsModal"
        maxWidth={false}
        className={classes.settingsModalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          id="closeSettingsModalButton"
          className={classes.closeSettingsModalButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>

        <Box className={classes.settingTitle}>Advanced settings</Box>

        <Box className={classes.postToSocialsContainer}>
          <Box className={classes.settingsContainer}>
            <img
              className={classes.messageIcon}
              src={socialMedia}
              alt="social media icon"
            />
            <Typography className={classes.settingTitle}>
              Post to socials
            </Typography>
            <Select placeholder="Select an option" className={classes.socialSelect} />
          </Box>
        </Box>

        <Box className={classes.allowCommentsContainer}>
          <Box className={classes.settingsContainer}>
            <img
              className={classes.messageIcon}
              src={message}
              alt="message icon"
            />
            <Typography className={classes.settingTitle}>
              Allow Comments
            </Typography>
            <Switch
              id="allowCommentsSwitch"
              onClick={allowComments}
              checked={isCommentsAllowed}
              className={classes.switch}
              focusVisibleClassName=".Mui-focusVisible"
              disableRipple
            />
          </Box>
        </Box>

        <Box className={classes.addSocialsContainer}>
          <Typography className={classes.addSocialsText}>
            Don't have a social profile connected yet?
          </Typography>
          <Button className={classes.addSocialButton}>
            Add Social Profile
          </Button>
        </Box>
      </Container>
    );
  }
}

export { NewPostAddCaption };
export default withStyles(styles)(NewPostAddCaption);
// Customizable Area End
