import React from "react";

// Customizable Area Start
import { Box, Button, Typography, TextField } from "@material-ui/core";
import InformationSignupController, {
  Props,
  configJSON,
} from "./InformationSignupController.web";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import EmailAccountLoginErrorBlock from "./EmailAccountRegistrationErrorBlock.web";
const styles = (theme: any) => ({
  nextHomePage: {
    backgroundColor: "#FFC629",
    marginTop: "37px",
    width: "100%",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    height: "56",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "black",
    },
  },
  BackHomePage: {
    color: "#fff",
    marginTop: "45px",
    backgroundColor: "#0F172A",
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    fontSize: "16px",
    fontWeight: 700,
    height: "56px",
    "&:hover": {
      backgroundColor: "#0F172A",
      color: "#fff",
    },
  },
  marginCommon: {
    marginBottom: "7px",

    "& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFC629",
    },
  },

  boldFontweight: {
    fontSize: "23px",
    fontWeight: 700,
    lineHight: "32px",
  },
  captionDetail: {
    fontSize: "11px",
    color: "#0F172A",
    marginTop: "10px",
    fontWeight: 400,
    letterSpacing: "0px",
  },
  fieldMnumber: {
    marginBottom: "7px",
    width: "100%",
    height: "56px",

    "& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFC629",
    },
  },
  marginTextField: {
    marginTop: "7px",
    fontWeight: 700,
    color: "#334155",
    fontSize: "16px",
  },
  MainContainerInfoOuter: {
    width: "370px",
    marginTop: "25px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-around",
  },
  radioGroupBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "24px",
  },
  MainContainerInfo: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "360px",
    marginTop: "25px",
    justifyContent: "space-around",

    "& .react-tel-input .form-control:focus": {
      borderColor: "#FFC626",
      boxShadow: "0 0 0 0 #FFC626",
      borderWidth: "2px"
    }
  },
  policyDetailBelow: {
    fontSize: "0.54rem",
    color: "#0F172A",
    marginTop: "10px",
  },
  conditionRatesNote: {
    textAlign: "center" as "center",
    paddingTop: "10px",
    fontSize: "10px",
    paddingBottom: "13px",
  },
});

const webStyles = {
  phoneInput: {
    width: "100%",
    height: "56px",
    fontWeight:400,
    fontSize:"12px !importent"
  },
  buttonSelect:{
    borderRight:"none",
    backgroundColor:"#ffffff"
  }
};
class InformationSignup extends InformationSignupController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes }: any = this.props;
    const {
      zipcode,
      mobileNumber,
      formError,
      firstName,
      lastName,
      businessWebsite,
      formGlobalError,
      currentStep,
    } = this.state;
    return (
      <Box className={classes.MainContainerInfoOuter}>
        <Box className={classes.MainContainerInfo}>
          {formGlobalError[currentStep] && (
            <EmailAccountLoginErrorBlock
              errorMsg={formGlobalError[currentStep]}
            />
          )}
          <Typography gutterBottom className={classes.boldFontweight}>
            {configJSON.informationData}
          </Typography>

          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.marginTextField}
          >
            {configJSON.firstName}
          </Typography>
          <TextField
            className={classes.marginCommon}
            id="firstname"
            variant="outlined"
            size="small"
            placeholder={configJSON.placeholderFirstName}
            name="firstName"
            onChange={(e) => this.handleFieldChange(e)}
            value={firstName}
            error={Boolean(formError.firstName)}
            helperText={formError.firstName}
            data-test-id="infoFirstname"
            InputProps={{
              style: {
                height: "56px",
              },
            }}
          />
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.marginTextField}
          >
            {configJSON.lastName}
          </Typography>
          <TextField
            className={classes.marginCommon}
            id="lastname"
            variant="outlined"
            size="small"
            placeholder={configJSON.placeholderLastName}
            name="lastName"
            onChange={(e) => this.handleFieldChange(e)}
            value={lastName}
            error={Boolean(formError.lastName)}
            helperText={formError.lastName}
            data-test-id="infoLastname"
            InputProps={{
              style: {
                height: "56px",
              },
            }}
          />
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.marginTextField}
          >
            {configJSON.businesswebsite}
          </Typography>
          <TextField
            className={classes.marginCommon}
            id="businessWebsite"
            variant="outlined"
            size="small"
            placeholder={configJSON.businessWebsites}
            name="businessWebsite"
            onChange={(e) => this.handleFieldChange(e)}
            value={businessWebsite}
            error={Boolean(formError.businessWebsite)}
            helperText={formError.businessWebsite}
            data-test-id="infoBusinesswebsite"
            InputProps={{
              style: {
                height: "56px",
              },
            }}
          />
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.marginTextField}
          >
            {configJSON.zipcode}
          </Typography>
          <TextField
            className={classes.marginCommon}
            id="zipcode"
            variant="outlined"
            size="small"
            placeholder={configJSON.pleaceholderZip}
            name="zipcode"
            onChange={(e) => this.handleFieldChange(e)}
            value={zipcode}
            error={Boolean(formError.zipcode)}
            helperText={formError.zipcode}
            InputProps={{
              style: {
                height: "56px",
              },
            }}
            data-test-id="infoZipcode"
          />
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.marginTextField}
          >
            {configJSON.mobileNumber}
          </Typography>
          <PhoneInput
            country={"us"}
            value={mobileNumber}
            placeholder="Enter mobile phone"
            specialLabel={""}
            data-test-id="phoneInput"
            onChange={(value) => {
              const changedEvent = {
                target: {
                  name: "mobileNumber",
                  value,
                },
              };
              this.handleFieldChange(changedEvent);
            }}
            isValid={!formError.mobileNumber}
            inputProps={{
              id: "phone",
              name: "phone"
            }}
            inputStyle={webStyles.phoneInput}
            buttonStyle={webStyles.buttonSelect}
          />
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            className={classes.captionDetail}
          >
            {configJSON.publicProfiles}
          </Typography>

          <Box>
            <Button
              variant="contained"
              onClick={this.handleNextClick}
              className={classes.nextHomePage}
              data-test-id="nextButton"
            >
              {configJSON.next}
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={this.props.handleGoBackClick}
              className={classes.BackHomePage}
            >
              {configJSON.back}
            </Button>
          </Box>
        </Box>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          className={classes.policyDetailBelow}
        >
          {configJSON.tappingIcon} <b> {configJSON.privacyPlicy}</b>
          {configJSON.authorization}
        </Typography>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          className={classes.conditionRatesNote}
        >
          {configJSON.applyTime}
        </Typography>
      </Box>
    );
  }
}
export { InformationSignup };
export default withStyles(styles)(InformationSignup);
// Customizable Area End
