// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Cfembedintegration2 from '../../blocks/cfembedintegration2/src/Cfembedintegration2';
import Likeapost2 from '../../blocks/likeapost2/src/Likeapost2';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Customform from '../../blocks/customform/src/Customform';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Cfmobileresponsivedesigns from '../../blocks/cfmobileresponsivedesigns/src/Cfmobileresponsivedesigns';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import DesktopNotifications from '../../blocks/desktopnotifications/src/DesktopNotifications';
import Contentmanagement3 from '../../blocks/contentmanagement3/src/Contentmanagement3';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import {NotificationWeb} from '../../blocks/notifications/src/Notifications.web';
import {OpenNotificationWeb} from '../../blocks/notifications/src/OpenNotification.web';
import Formapprovalworkflow from '../../blocks/formapprovalworkflow/src/Formapprovalworkflow';
import Paymentadmin2 from '../../blocks/paymentadmin2/src/Paymentadmin2';
import Realtimeupdates2 from '../../blocks/realtimeupdates2/src/Realtimeupdates2';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Cfbackendintegration from '../../blocks/cfbackendintegration/src/Cfbackendintegration';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import CreateComment from '../../blocks/comments/src/CreateComment';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import StripePayments from '../../blocks/stripepayments/src/StripePayments';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Analytics from '../../blocks/analytics/src/Analytics';
import VisualAnalytics from '../../blocks/dashboard/src/Analytics';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import EndUserEmailSignUp from '../../blocks/email-account-registration/src/EndUserEmailSignUp.web';
import EndUserOtpAuth from '../../blocks/email-account-registration/src/EndUserOtpconformation.web';
import EndUserSignIn from '../../blocks/email-account-login/src/EndUserSignIn';
import EnduserFeed from '../../blocks/postcreation/src/EnduserFeed.web';
import Login from '../../blocks/email-account-login/src/Login.web';
import PhoneLogin from '../../blocks/email-account-login/src/PhoneLogin.web';
import PhoneLoginOTP from '../../blocks/email-account-login/src/PhoneLoginOTP.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ResetPassword from '../../blocks/forgot-password/src/ResetPassword.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Home from '../../blocks/dashboard/src/Home.web';
import EuDashboard from "../../blocks/dashboard/src/EuDashboard.web";
import EuTrending from "../../blocks/dashboard/src/EuTrending.web";
import EuPostCreate from "../../blocks/postcreation/src/PostCreationEu.web";
import EuProfile from "../../blocks/user-profile-basic/src/UserProfileBasicEu.web";
import EuNotifications from "../../blocks/notifications/src/EuNotifications.web";
import Settings2 from '../../blocks/settings2/src/Settings2.web';
import UnderDevelopmentMessage from '../../blocks/dashboard/src/UnderDevelopmentMessage.web';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfileBlock.web';
import UserProfileBasicBu from '../../blocks/user-profile-basic/src/UserProfileBasicBu.web'
import InviteFriends from '../../blocks/dashboard/src/InviteFriends.web';
import ContactsList from '../../blocks/dashboard/src/ContactsList.web';
import CreateEveryreel from '../../blocks/postcreation/src/CreateEveryreel.web';
import NewPost from "../../blocks/postcreation/src/NewPost.web";
import AppLayout from '../../blocks/dashboard/src/AppLayout.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistrationBlock.web';
import AutomationTemplates from '../../blocks/settings2/src/AutomationTemplates.web';
import Messages from '../../blocks/comments/src/Messages.web';

import WaitList from "../../blocks/contactlist/src/Waitlist.web"

import WaitListChat from "../../blocks/contactlist/src/WaitListChat.web"
import { getStorageData } from '../../framework/src/Utilities';


const routeMap = {
  UnderDevelopmentMessage: {
    component: UnderDevelopmentMessage,
    path: '/message'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/reset-password' 
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  PhoneLoginOTP: {
    component: PhoneLoginOTP,
    path: '/PhoneLoginOTP'
  },
  PhoneLogin: {
    component: PhoneLogin,
    path: '/PhoneLogin'
  },
  EndUserOtpAuth: {
    component: EndUserOtpAuth,
    path: '/EndUserOtpAuth'
  },
  EnduserFeed: {
    component: EnduserFeed,
    path: '/EnduserFeed'
  },
  Login: {
    component: Login,
    path: '/Login',
    exact: true
  },
  EndUserSignIn: {
    component: EndUserSignIn,
    path: '/EndUserSignIn',
    exact: true
  },

  EndUserEmailSignUp: {
    component: EndUserEmailSignUp,
    path: '/EndUserEmailSignUp',
    exact: true
  },
  Cfembedintegration2: {
    component: Cfembedintegration2,
    path: '/Cfembedintegration2'
  },
  Likeapost2: {
    component: Likeapost2,
    path: '/Likeapost2'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  Cfmobileresponsivedesigns: {
    component: Cfmobileresponsivedesigns,
    path: '/Cfmobileresponsivedesigns'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  DesktopNotifications: {
    component: DesktopNotifications,
    path: '/DesktopNotifications'
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: '/Contentmanagement3'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  
  Formapprovalworkflow: {
    component: Formapprovalworkflow,
    path: '/Formapprovalworkflow'
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2'
  },
  Realtimeupdates2: {
    component: Realtimeupdates2,
    path: '/Realtimeupdates2'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  Cfbackendintegration: {
    component: Cfbackendintegration,
    path: '/Cfbackendintegration'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  AddReview: {
    component: AddReview,
    path: '/AddReview'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },

  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },

  Home: {
    component: Home,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const privateRoutes = {
  InviteFriends: {
    component: InviteFriends,
    path: '/InviteFriends',
    layout: true
  },
  AutomationTemplates: {
    component: AutomationTemplates,
    path: '/automations',
    layout: true
  },
  WaitList: {
    component: WaitList,
    path: '/WaitList',
    layout: true
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics',
    layout: true
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics',
    layout: true
  },
  WaitListChat: {
    component: WaitListChat,
    path: '/WaitListChat/:id',
    layout: true
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile',
    layout: true
  },
  UserProfileBu : {
    component: UserProfileBasicBu,
    path:'/userprofileBu',
    layout:true
  },
  PauseSubscription: {
    component: Settings2,
    path: "/settings/:id",
    layout: true
  },
  Settings2: {
    component: Settings2,
    path: "/settings",
    layout: true
  },

  Notifications: {
    component: NotificationWeb,
    path: '/Notifications',
    layout: true
  },

  OpenNotification: {
    component: OpenNotificationWeb,
    path: '/OpenNotification',
    layout: true
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard',
    layout: true
  },
  ContactsList: {
    component: ContactsList,
    path: '/contacts/:view?',
    layout: true
  },
  CreateEveryreel: {
    component: CreateEveryreel,
    path: '/CreateEveryreel',
    layout: true
  },
  NewPost: {
    component: NewPost,
    path: '/NewPost',
    layout: true
  },
  Messages: {
    component: Messages,
    path: '/messages',
    layout: true
  },
  ProfileView: {
    component: UserProfileBasicBu,
    path: '/profile/:navigationBarTitleText',
    layout: true
  },
};

const EUPrivateRoutes = {
  EuDashboard: {
    component: EuDashboard,
    path: '/EuDashboard',
  },
  EuTredning: {
    component: EuTrending,
    path: '/EuTredning',
  },
  EupostCreate: {
    component:EuPostCreate,
    path: '/EuCreatepost'
  },
  EuProfile: {
    component: EuProfile,
    path: '/EuProifle'
  },
  EuNotification: {
    component: EuNotifications,
    path: '/EuNotification'
  },
  EndUserProfile: {
    component: UserProfile,
    path: '/EndUserProfile'
  },
  EuProfileView: {
    component: EuProfile,
    path: '/profile/:navigationBarTitleText',
  },
}

const ProtectedComponent = () => {
  return (
    <Switch>
      {WebRoutesGenerator({ routeMap: privateRoutes })}
      <Route render={() => <Redirect to="/Dashboard" />} />
    </Switch>
  );
};

const EUProtectedComponent = () => {
  return (
    <Switch>
      {WebRoutesGenerator({ routeMap: EUPrivateRoutes })}
      <Route render={() => <Redirect to="/EUDashboard" />} />
    </Switch>
  );
};

const AppLayoutComponents = () => {
  return (
      <Switch>
        {WebRoutesGenerator({ routeMap })}
        <Route render={() => <Redirect to="/Login" />} />
      </Switch>
  );
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  state = {
    loggedin: false
  };

  async componentDidMount() {
    document.addEventListener('isLoggedIn', ({ detail }) => {
      this.setState({
        loggedin: detail
      });
    });
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const authToken = localStorage.getItem('authToken');
    const EUAuthToken = localStorage.getItem('EUAuthToken');
    console.log(authToken || this.state.loggedin ? "true app": 'false app', "test app")
    return (
      <div>
        {authToken || this.state.loggedin
          ? ProtectedComponent()
          : EUAuthToken ? EUProtectedComponent() :AppLayoutComponents()}
        <ModalContainer />
      </div>
    );
  }
}

export default App;
