// Customizable Area Start
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Container, withStyles } from "@material-ui/core";
import ContactsListController from "./ContactsListController.web";
import PrivacyPolicyModal from "./PrivacyPolicyModal.web";
import ContactsListContainer from "./ContactsListContainer.web";

const styles = (theme: Theme) =>
  createStyles({
    modalContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      zIndex: 99999,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: "auto",
      background: "rgba(0, 0, 0, 0.38)",
    },

    ContactsListContainer: {
      position: "fixed",
      top: "64px",
      bottom: "0",
      left: 0,
      zIndex: 99999,
      width: "100%",
      background: "#F1F5F9",
    },
  });

export class ContactsList extends ContactsListController {
  render() {
    const { classes } = this.props;
    const { showPolicy } = this.state;

    return (
      <>
        <CssBaseline />
        <ContactsListContainer />
      </>
    );
  }
}

export default withStyles(styles)(ContactsList);
// Customizable Area End
