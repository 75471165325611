// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleSuccessResponse: (data: any) => void;
  emailAddress: any;
}

interface S {
  emailAddress: string | null;
  emailError: string;
  formError: any;
  currentStep: number;
  responseJson: any;
  emailValidationResult: any;
  formGlobalError: any;
}
interface SS {
  id: any;
}
export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailSignupVerificationId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      emailAddress: this.emailAddressValue,
      emailError: "",
      responseJson: "",
      currentStep: 1,
      formError: {},
      formGlobalError: {},
      emailValidationResult: "q@q.com",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  emailAddressValue = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("emailAddress")
    : "";

  handleFieldChange = (e: any) => {
    const { name, value }: { name: string; value: string } = e.target;
    this.setState(({
      [name]: value,
    } as unknown) as Pick<S, keyof S>);
  };

  validateStep1 = () => {
    const errors: any = {};
    if (this.state.emailAddress === "") {
      errors.emailAddress = "Email address field is required.";
    } else if (
      !this.state.emailAddress ||
      !this.state.emailAddress.match(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      )
    ) {
      errors.emailAddress = "Invalid email address.";
    }
    return errors;
  };
  handleNextClick = () => {
    const errors = this.validateStep1();
    this.setState({
      formError: errors,
    });
    if (Object.keys(errors).length === 0) {
      this.doEmailSignUpVerification();
    }
  };
  async doEmailSignUpVerification() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.emailValidationResult !== this.state.emailAddress) {
      this.apiEmailSignupVerificationId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.signupEmailVerificationEndpoint}?email=${this.state.emailAddress}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.signupVerificationAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }));
    }
  }

  handlGlobalApiError = (responseJson: any) => {
    if (responseJson && (responseJson.error || responseJson.errors)) {
      this.setState({
        formGlobalError: { [this.state.currentStep]: "Something went wrong!" },
      });
      return true;
    }
  };

  apicallresponseData = (responseJson: any) => {
    console.log(responseJson, "rconsole_response");
    this.handlGlobalApiError(responseJson);
    if (responseJson.valid === false) {
      this.setState({
        formGlobalError: {
          [this.state.currentStep]: "Email already exists.",
        },
      });
    } else {
      this.state.emailAddress &&
        sessionStorage.setItem("emailAddress", this.state.emailAddress);
      console.log("passed");
      sessionStorage.setItem("valuesBackStep", "false");
      this.props.handleSuccessResponse(responseJson);
      this.setState({
        responseJson: responseJson,
        emailValidationResult: this.state.emailAddress,
      });
    }
  };

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiEmailSignupVerificationId
      ) {
        this.apicallresponseData(responseJson);
      }
    }
  }
}
// Customizable Area End
