// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getErrorMessage } from "../../../components/src/CommonFunctions";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  teammates: any;
  loading: any;
  anchorEll: any;
  teammatesModal: boolean;
  resendInviteModal: boolean;
  userItem: any;
  message: string;
  isResesndInvite: boolean;
  addTeammatesModal: boolean;
  employeeOne: string;
  employeeTwo: string;
  employeeThree: string;
  employeeFour: string;
  invitationData: any;
  invitationSubject: string;
  addNewTeammateModal: boolean;
  showAlert: boolean;
  errorAlertMessage: boolean;
  showSuccess: string;
  autoLink: string;
  openFlag: boolean
}
interface SS {
  id: any;
}
export default class TeamMatesController extends BlockComponent<Props, S, SS> {
  getTeamamtesAPIID: any = "";
  removeTeammatesApiId: any = "";
  sendInviteApiId: any = "";
  updateInviteStatusApiId: any = "";
  bulkInviteApiId: any = "";
  getInvitationAPIID: any = "";
  addnewTeammatesAPIID: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      teammates: [],
      loading: true,
      anchorEll: null,
      teammatesModal: false,
      resendInviteModal: false,
      userItem: { attributes: { name: "" } },
      message: "",
      isResesndInvite: true,
      addTeammatesModal: false,
      employeeOne: "",
      employeeTwo: "",
      employeeThree: "",
      employeeFour: "",
      invitationData: {},
      invitationSubject: "",
      addNewTeammateModal: false,
      showAlert: false,
      errorAlertMessage: false,
      showSuccess: "",
      autoLink: "",
      openFlag: false
    };
    this.handleClosePopOver = this.handleClosePopOver.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeResendIviteModal = this.closeResendIviteModal.bind(this);
    this.closeTeammateModal = this.closeTeammateModal.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  closeModal() {
    this.setState({ teammatesModal: false });
  }
  openModal() {
    this.setState({ teammatesModal: true });
  }
  closeTeammateModal() {
    this.setState({ addTeammatesModal: false });
  }

  openAddNewTeammatesModal() {
    this.setState({ addNewTeammateModal: true });
  }

  closeAddNewTeammateModal = () => {
    this.setState({ addNewTeammateModal: false });
    if(this.state.openFlag){
      this.setState({ openFlag: false });
      const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Settings2');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    }
  };

  closeResendIviteModal() {
    this.setState({ resendInviteModal: false });
  }
  openResendInviteModal(value: boolean) {
    this.setState({ isResesndInvite: value });
    this.setState({ resendInviteModal: true });
  }

  handleNameDropdownChange = (e: any, value: any) => {
    this.setState({
      userItem: {
        id:value.id,
        attributes: { name: e.target.value, email: value.attributes.email },
      },
    });
  };

  checkRequestCallId(apiRequestCallId: any) {
    if (apiRequestCallId === this.sendInviteApiId) {
      this.handleUpdateInviteStatus(true);
    }
    if (apiRequestCallId === this.updateInviteStatusApiId) {
      this.setState({ loading: false });
      this.closeResendIviteModal();
      this.handleClosePopOver();
      this.getTeammates();
    }
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.removeTeammatesApiId) {
        this.closeModal();
        this.handleClosePopOver();
        this.getTeammates();
      }

      if (apiRequestCallId === this.bulkInviteApiId) {
        this.closeTeammateModal();
        this.getTeammates();
      }

      if (apiRequestCallId === this.getInvitationAPIID) {
        this.setState({ invitationData: responseJson.data });
        this.setState({ autoLink: responseJson.auto_link })
        this.extractSubject(responseJson.data.message_template);
      }

      if (apiRequestCallId === this.addnewTeammatesAPIID) {
            this.handleAddNewTeammatesResponse(responseJson)
      }

      if (responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getTeamamtesAPIID) {
          this.setState({ teammates: responseJson.data });
        }
        this.checkRequestCallId(apiRequestCallId);
      } else if (responseJson.errors) {
        this.setState({ loading: false });
        let err = getErrorMessage(responseJson.errors[0]);
        alert(err);
      }
    }
  }

  handleAddNewTeammatesResponse = (res: any) => {
    this.setState({
      showSuccess: "New teammates added successfully!",
      showAlert: true,
      addNewTeammateModal: false
    });
    this.getTeammates();
    setTimeout(() => {
      this.setState({
        showSuccess: "",
        showAlert: false,
      })
    },2000)
  }

  handleModalSendClick() {
    if (this.state.isResesndInvite) {
      this.handleResendInvite();
    }
  }

  addNewTeammateCall = (data: any) => {
    const newData = {
      teammate: {
        name: data.first_name + " " + data.last_name,
        mobile_number: data.phone,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
      },
    };
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addnewTeammatesAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teammatesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  extractSubject = (str: any) => {
    console.log(str);
    const regex = /"subject": "([^"]*)"/;
    const match = str.match(regex);
    if (match && match.length >= 2) {
      this.setState({ invitationSubject: match[1] });
      console.log(match[1]);
    } else {
      this.setState({ invitationSubject: "Subject not found" });
    }
  };

  handleClickPopOver = (event: any, item: any) => {
    this.setState({ userItem: item });
    this.setState({ anchorEll: event.currentTarget });
  };

  handleClosePopOver() {
    this.setState({ anchorEll: null });
  }

  async componentDidMount(): Promise<void> {
    this.getTeammates();
    this.getInvitationData();
    this.setState({openFlag:!!new URLSearchParams(window.location.search).get('open')!})
  }

  componentDidUpdate = async (prevProps: Props, prevState: S) => {
    if (
      prevState.openFlag !== this.state.openFlag &&
      this.state.openFlag === true
    ) {
      this.openAddNewTeammatesModal()
    }
  };

  handleResendInvite() {
    this.setState({ loading: true });

    const data = {
      invite: {
        recipient_phones: {
          email: [this.state.userItem.attributes.email],
          invite_message: this.state.message,
        },
      },
    };
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendInviteApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendInviteEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleUpdateInviteStatus(inviteStatus: boolean) {
    let updateInviteUrl =
      configJSON.updateInvitaitonStatusEndPoint + this.state.userItem.id;
    const data = {
      teammate: {
        is_invitation_sent: inviteStatus,
      },
    };
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateInviteStatusApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateInviteUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleBulkInvite() {
    const data = {
      teammate: {
        teammates: [
          this.state.employeeOne,
          this.state.employeeTwo,
          this.state.employeeThree,
          this.state.employeeFour,
        ],
      },
    };
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkInviteApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkInvitesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleRemoveUser() {
    let removeTeammateUrl =
      configJSON.removeTeammateEndPoint + this.state.userItem.id;
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeTeammatesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      removeTeammateUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getTeammates() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeamamtesAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teammatesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getInvitationData() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInvitationAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.invitationAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}

// Customizable Area End
