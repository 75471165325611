import React from 'react';
import NavigationMenuEUWeb from '../../../components/src/NavigationMenuEU.web';
import { Avatar, Box, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TopActiveUsers from '../../dashboard/src/TopActiverUsers.web';
import SuggestedBrands from '../../../components/src/SuggestedBrands';
import Rating from '@material-ui/lab/Rating';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import PostCreationCommonController from './PostCreationCommonController';


export default class PostCreationEu extends PostCreationCommonController {
  render() {
    const { navigation } = this.props
    const { top6CreatePost } = this.state

    return (
      <div style={webStyle.root}>
        <NavigationMenuEUWeb brands={this.state.brandsIFollowCreate}/>
        <Box style={webStyle.mainContainer}>
          <Grid container style={webStyle.gridContainer} >
            <Grid item xs={6} md={8} style={webStyle.gridItem} >
              {
                this.state.eupoststep > 1 ? <Box style={webStyle.arrowIconContainer} onClick={this.SetEuPoststep.bind(this,this.state.eupoststep-1)}>
                  <ArrowBackIosOutlinedIcon />
                </Box> : ''
              }

              <Box style={webStyle.contentBox}>

                <Typography style={webStyle.fontinfo}>
                  Where would you like to post?
                </Typography>
                <Box style={webStyle.searchBox}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={!this.state.selectedBrand ? "Search" : `Selected: ${this.state.selectedBrand}`}
                    style={webStyle.searchInput}
                    InputProps={{
                      style: { borderRadius: '98px' },
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            !this.state.selectedBrand ? <SearchIcon style={webStyle.searchIcon} /> : ''
                          }

                        </InputAdornment>
                      ),
                      // classes: { input: webStyle.textFieldPadding }
                    }}
                    data-test-id="txtInputSearch"
                    onChange={(e)=>this.SearchValue(e.target.value)}
                  />

                </Box>
                {
                  this.state.eupoststep === 1 && <Grid container spacing={2} style={webStyle.brandGrid}>
                    {
                      this.state.brands.length > 0 ?
                        this.state.brands.map((brand, index) => (
                          <Grid item xs={3} key={index}>
                            <Box style={webStyle.brandBox}
                              onClick={this.handleBrandSelection.bind(this, brand.name)}
                            >
                              <Avatar src={brand.logo} style={webStyle.brandLogo} />
                              <Typography style={webStyle.brandName}>{brand.name}</Typography>
                            </Box>
                          </Grid>
                        ))
                        :
                        <Box>
                          can't find any data
                        </Box>
                    }
                  </Grid>
                }
                {
                  this.state.eupoststep === 2 &&
                  // 
                  <Grid container spacing={3} style={{ width: '100%', paddingLeft: "8px" }}>
                    <Grid item md={6} xs={6}>
                      <Box style={webStyle.createPostContainer}>
                        <Box>1</Box>
                        <Box>
                          <Typography variant='h6' style={webStyle.createPostText}>
                            Create New Post
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={webStyle.infoText}>
                        Ready to make your voice heard? Click here to share your thoughts and experiences with us! Your feedback helps us improve and lets others know about your amazing experiences.
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box style={webStyle.leaveReviewContainer}>
                        <Box>
                          <Rating
                            name="size-large" 
                            defaultValue={2}
                            size='large'
                            value={this.state.starRating}
                            style={{ width: "33px", height: "32px", color: "#FFC629" }}
                            data-test-id="RatingChange"
                            onChange={(event, newValue) => {
                              this.ChangeRating(newValue);
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography variant='h6' style={webStyle.leaveReviewText}>
                            Leave a Review
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={webStyle.infoText} >
                        Get ready to make waves! Click this button to craft and share your own captivating posts. Whether it's a story, a photo, or a video, your content will inspire and engage our community. Let's start creating together!
                      </Box>
                    </Grid>
                  </Grid>

                }

              </Box>
            </Grid>
            <Grid item xs={6} md={3} style={{...webStyle.gridItem,maxHeight: "700px"}} >
              <Box>
                <TopActiveUsers
                  top6={top6CreatePost}
                  navigation={navigation}
                />
              </Box>
              <Box>
                <SuggestedBrands
                  brands={this.state.suggestedBrandsCreate}
                  followBrand={this.followBrandPostApi}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}


const webStyle = {
  root: {
    backgroundColor: "#f1f4f9",
    minHeight: "100vh"
  },
  mainContainer: {
    width: "100vw",
    margin: "15px auto",
    maxWidth: "1240px"
  },
  gridContainer: {
    marginTop: '10px',
    gap: '15px',
    backgroundColor: "#f1f4f9",
    justifyContent: "center"
  },
  gridItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: '4px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  contentBox: {
    margin: "35px 15px 20px 50px",
  },
  fontinfo: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginBottom: '16px',
    borderBottom: "1px solid #64748B33",
    paddingBottom: '16px'
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  searchInput: {
      background: "#F7F8FC",
      marginTop: "13px"
  },
  textFieldPadding: {
    padding: "10px"
  },
  brandGrid: {
    marginTop: '16px',
  },
  brandBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as React.CSSProperties,
  brandLogo: {
    width: '64px',
    height: '64px',
    border: `2px solid #FFC629`,
    padding: '8px',
    cursor: "pointer"
  },
  brandName: {
    marginTop: '8px',
    textAlign: 'center',
    color: "#334155",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: '16px',
  } as React.CSSProperties,
  createPostContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "199px",
    borderRadius: "20px",
    backgroundColor: "#FFC629",
    justifyContent:"center",
    gap:"40px"
  } as  React.CSSProperties,
  leaveReviewContainer: {
    display: "flex",
    flexDirection: "column",
    height: "199px",
    borderRadius: "20px",
    backgroundColor: "#000000",
    justifyContent:"center",
    paddingLeft: "42px",
    gap:"33px"
  } as React.CSSProperties,
  createPostText: {
    color: "#000000",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "32px"
  },
  leaveReviewText: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "32px"
  },
  infoText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#0F172A",
    marginTop: "26px"
  },
  arrowIconContainer: {
    width: "24px",
    height: "24px",
    textAlign: "center",
    borderRadius: "50%",
    padding: "5px",
    border: "1px solid #000",
    margin: "39px 0 0 31px",
    cursor:"pointer"
  } as React.CSSProperties
}