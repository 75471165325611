// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { ChoiceOptions, NewEveryreelSteps, PostTypes } from "./enums";
import { File, NormalizedFile, Choice, FunnelFileData } from "./types";
import React from "react";
import { getToken } from "../../../components/src/AuthService";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  files: File[];
  setStep: (step: NewEveryreelSteps) => void;
  closeModal: () => void;
  addFiles: (files: File[]) => void;
  postType: PostTypes;
  savedFunnelFiles: FunnelFileData[];
};

interface S {
  files: NormalizedFile[];
  selectedFile: NormalizedFile;
  choices: Choice[];
  currentChoiceOption: ChoiceOptions;
  error: string;
  allowMultipleSelection: boolean;
  choicesArriveTime: number;
}

interface SS {
  navigation: any;
  stepType: NewEveryreelSteps;
}

export default class CreateEveryreelConfirmSettings extends BlockComponent<
  Props,
  S,
  SS
> {
  addFileButtonRef: React.RefObject<HTMLInputElement>;
  videoPreviewRef: React.RefObject<HTMLVideoElement>;

  sendSocialScrollApiCallId = "";
  sendFunnelApiCallId = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.addFileButtonRef = React.createRef();
    this.videoPreviewRef = React.createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      files: this.normalizeFiles(this.props.files),
      selectedFile: this.normalizeFiles(this.props.files)[0],
      choices: [],
      currentChoiceOption: 0,
      allowMultipleSelection: true,
      choicesArriveTime: 3,
      error: "",
    };
  }

  postPreview = configJSON.postPreview;
  postSocialScrollText = configJSON.postSocialScroll;
  postEveryreelText = configJSON.postEveryreelText;
  upload = configJSON.upload;
  linkButton = configJSON.linkButton;
  videos = configJSON.videos;
  editChoices = configJSON.editChoices;

  selectFile = (fileIndex: number) => {
    this.setState((prevState) => {
      let selectedFile = prevState.selectedFile;

      const updatedFiles = prevState.files.map((file, i) => {
        if (file.selected && fileIndex !== i) {
          return {
            ...prevState.selectedFile,
            selected: false,
          };
        } else if (fileIndex === i) {
          selectedFile = file;
          return {
            ...file,
            selected: true,
          };
        } else {
          return file;
        }
      });

      return {
        files: updatedFiles,
        selectedFile,
      };
    });

    this.videoPreviewRef.current?.load();
  };

  setPostTitle = (value: string) => {
    this.setState((prevState) => {
      return {
        selectedFile: { ...prevState.selectedFile, title: value },
      };
    });
  };

  setPostCaption = (value: string) => {
    this.setState((prevState) => {
      return {
        selectedFile: { ...prevState.selectedFile, caption: value },
      };
    });
  };

  setLinkText = (value: string) => {
    this.setState((prevState) => {
      return {
        selectedFile: { ...prevState.selectedFile, linkText: value },
      };
    });
  };

  setLinkURL = (value: string) => {
    this.setState((prevState) => {
      return {
        selectedFile: { ...prevState.selectedFile, link: value },
      };
    });
  };

  allowLink = () => {
    this.setState((prevState) => ({
      selectedFile: {
        ...prevState.selectedFile,
        isLinkAllowed: !prevState.selectedFile.isLinkAllowed,
      },
    }));
  };

  addChoice = () => {
    this.setState((prevState) => {
      return {
        choices: [
          ...prevState.choices,
          {
            name: "Write your choice description",
            buttonType: "",
            showMenu: false,
          },
        ],
      };
    });
  };

  setChoiceType = (type: string, choiceIndex: number) => {
    this.setState((prevState) => {
      return {
        choices: prevState.choices.map((choice, i) => {
          if (choiceIndex === i) {
            return {
              ...choice,
              buttonType: type,
            };
          }

          return choice;
        }),
      };
    });
  };

  showChoiceMenu = (choiceIndex: number) => {
    this.setState((prevState) => {
      return {
        choices: prevState.choices.map((choice, i) => {
          if (choiceIndex === i) {
            return {
              ...choice,
              showMenu: true,
            };
          } else {
            return {
              ...choice,
              showMenu: false,
            };
          }
        }),
      };
    });
  };

  closeChoiceMenu = () => {
    this.setState((prevState) => {
      return {
        choices: prevState.choices.map((choice, i) => {
          return {
            ...choice,
            showMenu: false,
          };
        }),
      };
    });
  };

  changeChoiceName = (value: string, index: number) => {
    this.setState((prevState) => {
      return {
        choices: [
          ...prevState.choices.map((choice, i) => {
            if (i === index) {
              return {
                ...choice,
                name: value,
              };
            }

            return choice;
          }),
        ],
      };
    });
  };

  setMultipleSelection = () => {
    this.setState((prevState) => {
      return {
        allowMultipleSelection: !prevState.allowMultipleSelection,
      };
    });
  };

  setChoiceArriveTime = (value: number) => {
    this.setState({
      choicesArriveTime: value,
    });
  };

  normalizeFiles = (files: File[]) => {
    console.log("files: ", files);

    return files.map((file: File, i: number) => {
      return {
        meta: file,
        type: file.type,
        selected: i === 0,
        title: `Video ${i + 1}`,
        caption: "",
        link: "",
        linkText: "Follow Us On EveryBrand",
        isLinkAllowed: true,
      };
    });
  };

  runPostFunction = () => {
    if (this.props.postType === PostTypes.SOCIAL_SCROLL) {
      this.postSocialScroll();
    } else {
      this.postFunnel();
    }
  };

  postSocialScroll = () => {
    const formData = new FormData();

    this.state.files.forEach((file) => {
      formData.append(
        "social_scroll[social_videos_attributes][][body]",
        file.caption || file.title
      );
      formData.append(
        "social_scroll[social_videos_attributes][][file]",
        (file.meta as unknown) as Blob
      );

      if (file.isLinkAllowed) {
        formData.append(
          "social_scroll[social_videos_attributes][][button_name]",
          file.linkText
        );
        formData.append(
          "social_scroll[social_videos_attributes][][button_url]",
          file.link
        );
      }
    });

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendSocialScrollApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSocialScrollEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  postFunnel = () => {
    const funnel = {
      social_videos_attributes: this.state.files.map((file) => {
        return {
          id: this.props.savedFunnelFiles.find((funnelFile) =>
            funnelFile.attributes.files.includes(file.meta.name)
          )?.id,
          name: file.title,
          body: file.caption,
          post_setting_attributes: {
            choices_arrival_time: this.state.choicesArriveTime,
          },
        };
      }),
      choices_attributes: [],
    };

    const httpBody = {
      funnel,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendFunnelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.postContentType,
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFunnelEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.sendSocialScrollApiCallId) {
      this.handleSendSocialScrollAPIResponse(message);
    }

    if (apiRequestCallId === this.sendFunnelApiCallId) {
      this.handleSendFunnelAPIResponse(message);
    }
  }

  handleSendSocialScrollAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson.data) {
      this.props.closeModal();
    }
  };

  handleSendFunnelAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.data) {
      this.props.closeModal();
    }
  };
}
// Customizable Area End
