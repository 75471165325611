// Customizable Area Start
import React, { CSSProperties } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateEveryreelConfirmController, {
  Props,
} from "./CreateEveryreelConfirmController.web";
import { CheckRounded, CloseRounded } from "@material-ui/icons";
import { NewEveryreelSteps } from "./enums";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      display: "flex",
      flexBasis: "809px",
      padding: "60px 44px 40px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "22px",
      flexShrink: 0,
      borderRadius: "8px 8px 32px 8px",
      background: "#FFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
    },

    closeButton: {
      position: "absolute",
      top: "20px",
      right: "11px",
    },

    modalTitle: {
      alignSelf: "flex-start",
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 700,
      letterSpacing: "-0.12px",
    },

    previewContainer: {
      position: "relative",
    },

    imagePreview: {
      display: "flex",
      maxWidth: "320px",
      minWidth: "280px",
      height: "506px",
      borderRadius: "20px",
      overflow: "hidden",
      background: "#F2F2F2",

      "& > img": {
        objectFit: "cover",
      },

      "& > video": {
        width: "100%",
      },
    },

    buttonsContainer: {
      position: "absolute",
      bottom: "29px",
      left: "50%",
      transform: "translate(-50%, 0)",
      display: "flex",
      gap: "41px",
    },

    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "55px",
      minWidth: "55px",
      height: "55px",
      borderRadius: "8px",
      backgroundColor: "#FFF",
    },
  });

const webStyles: Record<string, CSSProperties> = {};

class CreateEveryreelConfirm extends CreateEveryreelConfirmController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { files, classes, setStep, closeModal } = this.props;

    return (
      <Container
        id="confirmFileContainer"
        maxWidth={false}
        className={classes.modal}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          id="closeModalButton"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.modalTitle}>
          {this.goodChoice}
        </Typography>
        <Box className={classes.previewContainer}>
          <Box className={classes.imagePreview}>
            {Boolean(files.length) &&
              (files[0].type.includes("image") ? (
                <img
                  className={classes.imagePreview}
                  src={files[0].preview}
                  alt="image preview"
                />
              ) : (
                <video controls={false}>
                  <source src={files[0].preview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ))}
          </Box>

          <Box className={classes.buttonsContainer}>
            <Button
              id="confirmFilesButton"
              className={classes.button}
              onClick={() => {
                this.handleSendFunnelFiles()
                setStep(NewEveryreelSteps.POST_SETTINGS);
              }}
            >
              <CheckRounded style={{ color: "#059669", width: 25 }} />
            </Button>
            <Button
              id="rejectFilesButton"
              className={classes.button}
              onClick={() => setStep(NewEveryreelSteps.SELECT_FILE)}
            >
              <CloseRounded style={{ color: "#DC2626", width: 25 }} />
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }
}

export { CreateEveryreelConfirm };
export default withStyles(styles)(CreateEveryreelConfirm);
// Customizable Area End
