// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { NewEveryreelSteps, PostTypes } from "./enums";
import { File } from "./types";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  files: File[];
  postType: PostTypes;
  setStep: (step: NewEveryreelSteps) => void;
  closeModal: () => void;
  sendFunnelFiles: () => void;
};

interface S {}

interface SS {
  navigation: any;
  stepType: NewEveryreelSteps;
}

export default class CreateEveryreelConfirmController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  goodChoice = configJSON.goodChoice;

  handleSendFunnelFiles = () => {
    if(this.props.postType === PostTypes.INTERACTIVE_FUNNEL) {
      this.props.sendFunnelFiles()
    }
  }

}
// Customizable Area End
