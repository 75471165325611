import React, { useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";

const CountdownTimer = ({ resendOtp }: { resendOtp: any }) => {
  const initialTime = 60; // Initial time in seconds
  const [seconds, setSeconds] = useState(initialTime);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  useEffect(() => {
    const storedTime = localStorage.getItem("countdownTime");
    const storedTimestamp = localStorage.getItem("countdownTimestamp");
    if (storedTime && storedTimestamp) {
      const elapsedTime = Math.floor(
        (Date.now() - Number(storedTimestamp)) / 1000
      );
      const remainingTime = Math.max(initialTime - elapsedTime, 0);

      setSeconds(remainingTime);
      if (remainingTime > 0) {
        setIsTimerRunning(true);
      } else {
        setIsTimerRunning(false); // Ensure the timer stops if it reaches 0
      }
    } else {
      startTimer(); // Start the timer if there's no stored data
    }
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isTimerRunning && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsTimerRunning(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isTimerRunning, seconds]);

  const startTimer = () => {
    setIsTimerRunning(true);
    localStorage.setItem("countdownTime", String(seconds));
    localStorage.setItem("countdownTimestamp", String(Date.now()));
  };

  const handleResendClick = () => {
    console.log("handleResendClick ==> ");
    setSeconds(initialTime);
    setIsTimerRunning(true); // Set the timer to running state when "Resend" is clicked
    localStorage.setItem("countdownTime", String(initialTime));
    localStorage.setItem("countdownTimestamp", String(Date.now()));
    resendOtp();
  };

  const renderSeconds = () => {
    if (seconds > 0) {
      return `${seconds < 10 ? "0" : ""}${seconds}s`;
    } else {
      return null;
    }
  };

  return (
    <div data-test-id="timerDiv">
      {seconds > 0 ? (
        <Typography
          // @ts-ignore
          style={styles.text}
        >
          Resend in {renderSeconds()}
        </Typography>
      ) : (
        <Box data-test-id="timerBox">
          <Typography
            // @ts-ignore
            style={styles.text}
          >
            <span data-test-id="timerText" onClick={handleResendClick}>
              Resend Code
            </span>
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default CountdownTimer;

const styles = {
  text: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "center",
    cursor: "pointer",
  },
};
