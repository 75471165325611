export enum NewEveryreelSteps {
  INITIAL_CHOICE = "INITIAL_CHOICE",
  SELECT_FILE = "SELECT_FILE",
  FILE_CONFIRM = "FILE_CONFIRM",
  POST_SETTINGS = "POST_SETTINGS",
  EVERYREEL_LIBRARY = "EVERYREEL_LIBRARY"
}

export enum PostTypes {
  SOCIAL_SCROLL = "SOCIAL_SCROLL",
  INTERACTIVE_FUNNEL = "INTERACTIVE_FUNNEL"
}

export enum ChoiceOptions {
  VIDEO_PARTS,
  CONTACT_FORM,
  LINK,
  CHAT,
  CALENDAR,
  END
}

export enum ChoiceLetters {
  A,
  B,
  C
}

export enum NewPostCreationSteps {
  SELECT_FILE = "SELECT_FILE",
  ADD_CAPTION = "ADD_CAPTION",
  COVER_SELECTION = "COVER_SELECTION",
  ADVANCED_SETTINGS = "ADVANCED_SETTINGS",
  POST_PREVIEW = "POST_PREVIEW",
  EVERYREEL_LIBRARY = "EVERYREEL_LIBRARY"
}

export enum CaptionViews {
  CAPTION = "CAPTION",
  POLL = "POLL",
  NONE = "NONE",
}