// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  ThemeProvider,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import EndUserSignInController from "./EndUserSignInController";
import {
  themeWhite,
  themeYellow,
  commonStyles,
  webStyles,
} from "../../../framework/src/enduserTheme";
import { backArrow, lockIconSignIn } from "./assets";
import MobileLoader from "../../../components/src/MobileLoader";
import { Formik } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { handleAndConditions, handleOrConditions } from "../../../components/src/CommonFunctions";

const ThemeSwitch = withStyles((theme) => ({  
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #000`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  root: {
    width: 46,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  
  thumb: {
    width: 24,
    height: 24,
  },
  focusVisible: {},
  switchBase: {
    padding: 1,
    color: "#FFC629",
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white, 
      backgroundColor: "#FFC629",
      "& + $track": {
        backgroundColor: "#FFC629",
        opacity: 1,
        border: "1px solid #000",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.common.white, 
      border: "6px solid #fff",
    },
  },
  // @ts-ignore
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export class EndUserSignIn extends EndUserSignInController {
  render() {
    const { classes }: any = this.props;
    const validation_Schema_signin = yup.object({
      userNumber: yup
        .string()
        .required("Phone number is required ")
        .min(10, "Must be at least 10 digits "),
    });

    return (
      <ThemeProvider theme={this.state.themeSwitch ? themeYellow : themeWhite}>
        <MobileLoader loading={this.state.loading} />
        <CssBaseline />
        <Container
          data-test-id="mainContainerSignIn"
          maxWidth="sm"
          className={classes.mainContainerSignIn}
        >
          <Formik
            initialValues={{
              userNumber: "",
            }}
            validationSchema={validation_Schema_signin}
            onSubmit={(values) => {
              this.senOtpToUserSignINFlow(values.userNumber);
            }}
            data-test-id="mainFormSignIN"
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              handleSubmit,
            }) => (
              <>
                <Box className={classes.backIconBoxSignIn}>
                  <IconButton
                    onClick={() =>
                      this.setState({ stepThree: false, stepTwo: true })
                    }
                  >
                    <img src={backArrow} alt="back_arrow" />
                  </IconButton>
                  <Box className={classes.switchBox}>
                    <FormControlLabel
                      control={
                        <ThemeSwitch
                          // @ts-ignore
                          checked={this.state.themeSwitch}
                          onChange={this.handelThemeChange}
                          name="checkedB"
                        />
                      }
                      label=""
                    />
                  </Box>
                </Box>
                <Typography
                  data-test-id="headingTextSignIN"
                  align="center"
                  className={classes.centerTextmain}
                >
                  Welcome Back! Sign In With Your Number.
                </Typography>
                <Box>
                  <Typography className={classes.textTwo}>
                    We protect our community by making sure everyone is real.
                  </Typography>
                  <Box className={classes.flexDisplaySigninCol}>
                    <Box className={classes.marginAutoSignIN}>
                      <PhoneInput
                        data-test-id="userNumber"
                        country={"us"}
                        value={values.userNumber}
                        onChange={handleChange("userNumber")}
                        onBlur={handleBlur("userNumber")}
                        placeholder="Enter Phone Number"
                        specialLabel={""}
                        buttonStyle={handleOrConditions(
                          this.state.themeSwitch,
                          webStyles.buttonStyleYellow,
                          webStyles.buttonStyleLight
                        )}
                        inputStyle={handleOrConditions(
                          this.state.themeSwitch,
                          webStyles.phoneInputYellow,
                          webStyles.phoneInputLight
                        )}
                      />
                    </Box>
                    {handleAndConditions(touched.userNumber , errors.userNumber , (
                      <Typography className={classes.errorMsgSignIN}>
                        {errors.userNumber}
                      </Typography>
                    ))}
                    {handleOrConditions(this.state.phoneNumberError , (
                      <Typography className={classes.errorMsg}>
                        Please check your phone number and try again.
                      </Typography>
                    ) , null)}
                  </Box>
                </Box>
                <Box style={{ marginBottom: "100px" }}>
                  <Button
                    onClick={() => handleSubmit()}
                    className={classes.buttonPrimarySignUp}
                    data-test-id="submitButtonSignIN"
                  >
                    Continue
                  </Button>
                  <Box className={classes.flexDisplaySignin}>
                    <img
                      src={lockIconSignIn}
                      alt="lockIconSignIn"
                      className={classes.lockIconSignIn}
                    />
                    <Typography className={classes.lockTextSignIN}>
                      We never share this with anyone and it won't be on your
                      public profile. We'll only verify your number to make sure
                      you are you.
                    </Typography>
                    
                  </Box>
                  <Typography className={classes.signUpContainer}>
                      Don't have an account? <span data-test-id="eu-redirect-signup-btn" onClick={this.handleSignUpClick}>Sign Up</span>
                    </Typography>
                </Box>
              </>
            )}
          </Formik>
        </Container>
      </ThemeProvider>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    switchBox: {
      position: "absolute",
      top: "7px",
      right: "-45px",
    },
    errorMsgSignIN: {
      color: "red",
      fontSize: "12px",
      padding: "5px 2px",
      textAlign: "center"
    },
    marginAutoSignIN: {
      margin: "10px auto",
      border: "1px solid #cacaca",
      borderRadius: "5px",
      maxWidth: "400px",
      "& .react-tel-input .form-control": {
        border: "none",
        "&:focus": {
          boxShadow: "none",
          border: "none",
        },
        "&:hover": {
          border: "none"
        }
      },
      "&:hover": {
        border: "1px solid #1976d2",
        "& .flag-dropdown": {
          borderColor: "#1976d2"
        }
      },
      "& .flag-dropdown": {
        borderRight: "1px solid #cacaca",
        border: "none",
      },
      "&:focus-within": {
        border: "2px solid #1976d2",
        "& .flag-dropdown": {
          borderRight: "2px solid #1976d2",
          borderColor: "#1976d2",
        }
      }
    },
    flexDisplaySignin: {
      display: "flex",
      flexDirection: "row",
    },
    flexDisplaySigninCol: {
      display: "flex",
      flexDirection: "column",
    },
    lockTextSignIN: {
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "14px",
      padding: "0 5px",
      fontFamily: "Inter",
    },
    signUpContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      marginTop: "30px",
      color: "#0F172A",
      lineHeight: "24px",
      fontFamily: "Inter",
      fontWeight: 400,
      "&>span": {
        fontWeight: '700',
        lineHeight: "24px",
      fontFamily: "Inter",
      cursor: "pointer"
      }
    },
    lockIconSignIn: {
      height: "16px",
      width: "16px",
      margin: "auto 5px",
    },
    textTwo: {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "14px",
      fontFamily: "Inter",
      textAlign: "center"
    },
    centerTextmain: {
      color: "#000",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      fontFamily: "Inter",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    mainContainerSignIn: {
      ...commonStyles.mobileContainer,
      paddingLeft: "29px",
      paddingRight: "29px",
      gap: "30px"
    },
    buttonPrimarySignUp: {
      ...commonStyles.mobileButton,
      marginBottom: "30px",
      width: "100%",
    },
    buttonDisableSignIn: {
      ...commonStyles.mobileButton,
      marginBottom: "30px",
      width: "100%",
      backgroundColor: "#7f6415",
    },
    backIconBoxSignIn: {
      margin: "10px 0",
      position: "relative",
      right: "20px",
    },
  });

export default withStyles(styles)(EndUserSignIn);
// Customizable Area End
