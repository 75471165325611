export const logoEverybrand = require('../assets/LogoEverybrand.png');
// Customizable Area Start
export const UserIcon = require('../assets/user.svg');
export const AddTeamMemberIcon = require('../assets/add_team_member.svg');
export const HomeIcon = require('../assets/home.svg');
export const ContactsIcon = require('../assets/contacts.svg');
export const ChatBubbleIcon = require('../assets/speech_bubble.svg');
export const PageAnalyticsIcon = require('../assets/page_analytics.svg');
export const EveryReelIcon = require('../assets/everyreel.svg');
export const EverybrandIcon = require('../assets/everybrand_icon.svg');
export const SettingsIcon = require('../assets/settings.svg');
export const HeartIcon = require('../assets/heart.svg');
export const StarIcon = require('../assets/star.svg');
export const HeartSmallIcon = require('../assets/heart_small.svg');
export const ChatBubbleSmallIcon = require('../assets/chat_bubble_small.svg');
export const ChatBubbleLargeIcon = require('../assets/chat_bubble_large.svg');
export const ClockIcon = require('../assets/clock.svg');
export const smileYellow = require('../assets/smile_yellow.svg');
export const image1 = require('../assets/images.jpg');
export const image2 = require('../assets/images1.jpg');
export const image3 = require('../assets/images2.jpg');
export const image4 = require('../assets/image4.jpeg');
export const image5 = require('../assets/uu.jpeg');
export const stepImageFirst = require('../assets/step_Image_First.svg');
export const cloudIcon = require('../assets/cloud_icon.svg');
export const addIcon = require('../assets/image_add.svg');
export const selectedChatIcon = require('../assets/image_comment.svg');
export const contactsPlay = require('../assets/contacts_play.svg');
export const groupButton = require('../assets/group_button.svg');
export const uploadIcon = require('../assets/OPTIONS_ICON.svg');
export const sendMedssageIcon = require('../assets/SEND_MESSAGE_ICON.svg');
export const redHeart = require("../assets/red-heart.svg")
export const reelIcon = require('../assets/reel.svg');
export const plusInCircle = require('../assets/plus-in-circle.svg')
export const fallbackAvatar = require('../assets/icon_avatar.svg')
// Customizable Area End
