// Customizable Area Start
import React, {CSSProperties,useState} from "react";
import { useDropzone } from "react-dropzone";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography, Button, Snackbar } from "@material-ui/core";
import { cloudIcon } from "./assets";
import Alert from "@material-ui/lab/Alert";

const configJSON = require("./config.js");

type Props = {
  classes: any;
  setStep: () => void;
  setContactsFiles: (file: any) => void;
  sendContactsFile: () => void;
  contactFile: any
};

export const ContactsListAddContactsFile = ({ classes, setContactsFiles, sendContactsFile, setStep, contactFile }: Props) => {
  const [fileupload , setFileupload] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/*": [".csv", ".xlsx"],
    },
    maxSize: 1000000000,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFileupload(true);
      setContactsFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleNextStep = () => {
    sendContactsFile()
    setStep()
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>You have a csv file?</Typography>
      {
        contactFile && contactFile.length > 0 ? (
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} id="fileInput" />
            <img
              src={cloudIcon}
              style={{ width: 72, color: "#E0E0E0" }}
            />

            <Box className={classes.fileDescriptionContainer}>
              <Typography className={classes.fileName}>
                {contactFile[0].path}
              </Typography>
              <Typography className={classes.fileSize}>
                {Math.ceil(contactFile[0].size / (1024 * 1024))} MB
              </Typography>
            </Box>

            <Button className={classes.acceptButton} style={{ ...webStyles.button }}>
              Select File
            </Button>
          </div>
        ) : (
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} id="fileInput" />
            <img
              src={cloudIcon}
              style={{ width: 72, color: "#E0E0E0" }}
            />
            <Box className={classes.dropzoneInnerContainer}>
              <Typography className={classes.dropzoneTitle}>
                Select file to upload
              </Typography>
              <Typography className={classes.dropzoneText}>
                or drag and drop file
              </Typography>
            </Box>

            <Box className={classes.fileDescriptionContainer}>
              <Typography className={classes.limitsText}>CSV file or .XMLS file</Typography>
              <Typography className={classes.limitsText}>
                Less than 1 GB
              </Typography>
            </Box>

            <Button className={classes.acceptButton} style={{ ...webStyles.button }}>
              Select File
            </Button>
          </div>
        )
      }
       <Snackbar
        open={Boolean(fileupload)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={"success"}
        >
          {"File uploaded succesfully"}
        </Alert>
      </Snackbar>
      <Button id="acceptFileButton" className={classes.acceptButton} style={{ ...webStyles.button }} onClick={handleNextStep}>Accept</Button>
      <Button id="backButton" className={classes.backButton} style={{ ...webStyles.button }} onClick={setStep}>Back</Button>
    </Box>
  );
};

const webStyles: Record<string, CSSProperties> = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "360px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
  },
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "auto",
      gap: "17px",

      "&::-webkit-scrollbar": {
        width: "10px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    title: {
      alignSelf: "flex-start",
      paddingLeft: "14px",
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px"
    },

    dropzone: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "stretch",
      alignItems: "center",
      padding: "18px 25px 25px",
      gap: "20px",
      borderRadius: "10px",
      border: "2px dashed #ABB0BC",
    },

    dropzoneInnerContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "4px",
    },

    fileDescriptionContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "10px",
      paddingBottom: "22px"
    },
    fileName: {
      fontFamily: "inter",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "center",
      letterSpacing: "-0.11999999731779099px"
    },
    fileSize: {
      fontFamily: "inter",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "13px",
      textAlign: "center",
      color: "#000000",
      paddingTop: "11px"
    },

    dropzoneTitle: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },

    dropzoneText: {
      fontSize: "14px",
      lineHeight: "20px",
    },

    limitsText: {
      fontSize: "14px",
      lineHeight: "25px",
      fontWeight: 700,
      fontFamily: "Inter",
      color: "#000000"

    },

    acceptButton: {
      backgroundColor: "#FFC629",
      color: "#0F172A",

      "&:hover": {
        backgroundColor: "#FFC629",
      }
    },
  
    backButton: {
      backgroundColor: "#000",
      color: "#FFF",

      "&:hover": {
        backgroundColor: "#000",
      },
    },
  });

export default withStyles(styles)(ContactsListAddContactsFile);
// Customizable Area End
