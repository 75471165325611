// Customizable Area Start
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";

import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  logoEverybrand,
  addIcon,
  ChatBubbleLargeIcon,
  StarIcon,
  UserIcon,
  EveryReelIcon,
} from "./assets";
import { NavigationMenuItem } from "./interfaces";
import { NavLink } from "react-router-dom";
import { AddRounded } from "@material-ui/icons";
import { WebsiteNormalized } from "./AppLayoutController.web";

export const configJSON = require("./config.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.drawer + 1,
      overflowX: "auto",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      borderRadius: 0,
      fontWeight: 700,
      fontSize: "16px",
      fontStyle: "normal",
      fontFamily: "Inter",
      transition: `all 0.3s ${theme.transitions.easing.easeInOut} 0ms`,
    },
    active: {
      "& .MuiButton-label": {
        borderBottom: `6px solid ${theme.palette.primary.main}`,
      },
    },
    activeIcon: {
      borderWidth: "20px",
      borderBottom: `6px solid ${theme.palette.primary.main}`,
    },
    navigationContainer: {
      "& :not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    websiteListContainer: {
      display: "flex",
      alignItems: "center",
      "& hr": {
        backgroundColor: "#64748B",
        margin: theme.spacing(0, 2),
      },
    },
    quickAccessBtn: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.spacing(1),
      minWidth: 0,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    menuButton: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    icon: {
      color: "#000000",
    },

    quickAccessMenu: {
      display: "flex",
      flexDirection: "column",
      width: "248px",
      gap: "5px",
      borderRadius: "8px",

      "& .MuiMenuItem-root": {
        whiteSpace: "unset",
      },

      "& > .MuiMenu-paper": {
        minWidth: "248px",
      },
    },

    menuItem: {
      display: "flex",
      height: "71px",
      width: "100%",
      padding: "5px 10px 5px 15px",
    },

    menuLink: {
      display: "flex",
      flexWrap: "nowrap",
      flexGrow: 1,
      alignItems: "center",
      gap: "10px",
      color: "#000",
      textDecoration: "none",
    },

    menuIconContainer: {
      display: "flex",
      alignItems: "center",
      width: "24px",
      height: "24px",
      minWidth: "24px",

      "& img": {
        width: "100%",
      },
    },

    menuItemContent: {
      display: "flex",
      flexDirection: "column",
    },

    menuTitle: {
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: 700,
    },

    menuDescription: {
      fontSize: "12px",
      fontFamily: "Inter",
    },

    websitesMenu: {
      width: "164px",
      borderRadius: "5px",
    },

    websitesMenuItem: {
      borderRadius: "5px",

      "&.Mui-selected": {
        backgroundColor: "#000",

        "& a": {
          color: "#fff",
        },

        "&:hover": {
          backgroundColor: "#000",
        }
      },

      "& a": {
        fontFamily: "Inter",
        color: "#000",
        textDecoration: "none",
        textTransform: "capitalize",
      }
    }
  })
);

interface Props {
  navigationMenus: NavigationMenuItem[];
  selectedWebsite?: WebsiteNormalized;
  websitesList: WebsiteNormalized[];
  onWebsiteSelect: (selectedWebsite: WebsiteNormalized) => void;
  onDrawerToggle: () => void;
}

const ITEM_HEIGHT = 48;

function NavigationMenu({
  navigationMenus,
  websitesList,
  selectedWebsite,
  onWebsiteSelect,
  onDrawerToggle,
}: Props) {
  const [
    websiteSelectionAnchorEl,
    setWebsiteSelectionAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const [
    quickAccessAnchorEl,
    setQuickAccessAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const handleWebsiteMenuItemClick = (selectedWebsite: WebsiteNormalized) => {
    onWebsiteSelect(selectedWebsite);
    setWebsiteSelectionAnchorEl(null);
  };
  const [isActive, setIsActive] = React.useState(false);

  const handleToggleActive = () => {
    setIsActive(!isActive);
  };
  const classes = useStyles();

  return (
    <AppBar className={classes.appbar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          edge="start"
          aria-label="open drawer"
          className={classes.menuButton}
          onClick={onDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6">
          <img src={logoEverybrand} alt="Everybrand logo" />
        </Typography>
        <div className={classes.navigationContainer}>
          {navigationMenus && navigationMenus.length > 0
            ? navigationMenus.map((menu) => (
                <NavLink
                  key={menu.name}
                  to={menu.path}
                  activeClassName={classes.active}
                >
                  <Button
                    startIcon={<img src={menu.icon} />}
                    className={classes.button}
                  >
                    {menu.name}
                  </Button>
                </NavLink>
              ))
            : null}
        </div>
        <div className={classes.websiteListContainer}>
          <Button
            aria-controls="quick-access-menu"
            aria-haspopup="true"
            onClick={(event) => setQuickAccessAnchorEl(event.currentTarget)}
          >
            <img src={addIcon} alt="addIcon" />
          </Button>
          <Menu
            id="quick-access-menu"
            anchorEl={quickAccessAnchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(quickAccessAnchorEl)}
            onClose={() => setQuickAccessAnchorEl(null)}
            className={classes.quickAccessMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              data-test-id="newPostMenuItem"
              className={classes.menuItem}
              onClick={() => {
                setQuickAccessAnchorEl(null);
              }}
            >
              <NavLink className={classes.menuLink} to="/NewPost">
                <Box
                  className={classes.menuIconContainer}
                  style={{ width: "10px", height: "10px" }}
                >
                  <AddRounded />
                </Box>
                <Box className={classes.menuItemContent}>
                  <Typography className={classes.menuTitle}>Post</Typography>
                  <Typography className={classes.menuDescription}>
                    {configJSON.menuPostsDescription}
                  </Typography>
                </Box>
              </NavLink>
            </MenuItem>

            <MenuItem
              data-test-id="newEveryreelMenuItem"
              className={classes.menuItem}
              onClick={() => {
                setQuickAccessAnchorEl(null);
              }}
            >
              <NavLink className={classes.menuLink} to="/CreateEveryreel">
                <Box className={classes.menuIconContainer}>
                  <img src={EveryReelIcon} alt="menu icon" />
                </Box>
                <Box className={classes.menuItemContent}>
                  <Typography className={classes.menuTitle}>
                    Everyreel
                  </Typography>
                  <Typography className={classes.menuDescription}>
                    {configJSON.menuEveryreelDescription}
                  </Typography>
                </Box>
              </NavLink>
            </MenuItem>

            <MenuItem
              data-test-id="newMessageMenuItem"
              className={classes.menuItem}
              onClick={() => {
                setQuickAccessAnchorEl(null);
              }}
            >
              <NavLink className={classes.menuLink} to="/messages">
                <Box className={classes.menuIconContainer}>
                  <img src={ChatBubbleLargeIcon} alt="menu icon" />
                </Box>
                <Box className={classes.menuItemContent}>
                  <Typography className={classes.menuTitle}>Message</Typography>
                  <Typography className={classes.menuDescription}>
                    {configJSON.menuMessageDescription}
                  </Typography>
                </Box>
              </NavLink>
            </MenuItem>

            <MenuItem
              data-test-id="newContactMenuItem"
              className={classes.menuItem}
              onClick={() => setQuickAccessAnchorEl(null)}
            >
              <NavLink className={classes.menuLink} to="/contacts/add">
                <Box className={classes.menuIconContainer}>
                  <img src={UserIcon} alt="menu icon" />
                </Box>
                <Box className={classes.menuItemContent}>
                  <Typography className={classes.menuTitle}>Contact</Typography>
                  <Typography className={classes.menuDescription}>
                    {configJSON.menuContactDescription}
                  </Typography>
                </Box>
              </NavLink>
            </MenuItem>

            <MenuItem
              data-test-id="newReviewMenuItem"
              className={classes.menuItem}
              onClick={() => setQuickAccessAnchorEl(null)}
            >
              <NavLink className={classes.menuLink} to="#">
                <Box className={classes.menuIconContainer}>
                  <img src={StarIcon} alt="menu icon" />
                </Box>
                <Box className={classes.menuItemContent}>
                  <Typography className={classes.menuTitle}>Review</Typography>
                  <Typography className={classes.menuDescription}>
                    {configJSON.menuReviewDescription}
                  </Typography>
                </Box>
              </NavLink>
            </MenuItem>
          </Menu>
          <Divider orientation="vertical" flexItem />
          <Button
            aria-controls="website-selection-menu"
            aria-haspopup="true"
            onClick={(event) =>
              setWebsiteSelectionAnchorEl(event.currentTarget)
            }
            startIcon={<KeyboardArrowDownOutlinedIcon />}
            className={classes.button}
          >
            {selectedWebsite?.name}
          </Button>
          <Menu
            id="website-selection-menu"
            anchorEl={websiteSelectionAnchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(websiteSelectionAnchorEl)}
            onClose={() => setWebsiteSelectionAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}
            className={classes.websitesMenu}
          >
            {websitesList && websitesList.length > 0
              ? websitesList.map((website, i) => (
                  <MenuItem
                    id={`menuItem-${i}`}
                    key={website.id}
                    onClick={() => handleWebsiteMenuItemClick(website)}
                    selected={website.id === selectedWebsite?.id}
                    className={classes.websitesMenuItem}
                  >
                    <a href={website.url}>{website.name}</a>
                  </MenuItem>
                ))
              : null}
          </Menu>
        </div>
        <NavLink
          to="/Notifications"
          style={{ textDecoration: "none" }}
          activeClassName={classes.activeIcon}
        >
          <IconButton
            aria-label="notifications"
            className={classes.icon}
            style={{ padding: "5px 2px" }}
            onClick={handleToggleActive}
          >
            <NotificationsOutlinedIcon />
          </IconButton>
        </NavLink>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationMenu;
// Customizable Area End
