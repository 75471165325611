// Customizable Area Start
import React, { useEffect, useRef } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography, Button } from "@material-ui/core";
import { AfterSignupModalSteps } from "./enums";

type Props = {
  classes: any;
  privacyPolicyText: string;
  setStep: (step: AfterSignupModalSteps) => void;
};

export const AfterSignupPolicy = ({ classes, privacyPolicyText, setStep }: Props) => {
  const policyContainerRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null)

  useEffect(() => {
    if(policyContainerRef) {
      policyContainerRef.current?.insertAdjacentHTML("afterbegin", privacyPolicyText)
    }
  }, [])

  const handleNextStep = () => {
    setStep(AfterSignupModalSteps.NUMBER_ACCOUNT);
  };

  return (
    <Box className={classes.container} id="policy">
      <Typography className={classes.title}>
        Privacy Policy and Terms of Service
      </Typography>
      <Typography ref={policyContainerRef} className={classes.policyContainer} />
      <Button
        id="goToNumberButton"
        className={classes.confirmButton}
        onClick={handleNextStep}
      >
        Confirm
      </Button>
    </Box>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flexShrink: 1,
      gap: "40px",
      color: "#0F172A",
      overflowY: "auto",
    },

    title: {
      fontSize: "24px",
      fontWeight: 700,
    },

    policyContainer: {
      display: "flex",
      flexDirection: "column",
      paddingRight: "23px",
      overflowY: "auto",

      "& > p": {
        margin: 0,
      },

      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: "39px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    confirmButton: {
      alignSelf: "flex-end",
      width: "253px",
      height: "44px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#FFC629",
      fontWeight: 700,
      fontSize: "16px",
    },
  });

export default withStyles(styles)(AfterSignupPolicy);
// Customizable Area End
