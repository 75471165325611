// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getErrorMessage } from "../../../components/src/CommonFunctions";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  plans: any;
  loading: boolean;
  activeFilter: string;
  normalPlans: any;
  currentPlan: any;
  showSuccessAlert: boolean;
  alertMessage: string;
  subscriptionModal: boolean;
  pauseModal: boolean;
  selectedPlan: any;
  pauseReason: string;
  otherReason: string;
  pausedAccount: any;
  isPaused: boolean;
}
interface SS {
  id: any;
}
export default class UpdatesubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  subscriptionPlansAPIID: any = "";
  getCurrentBuPlanAPIID: any = "";
  updateCurrentBuPlanAPIID: any = "";
  pauseAccountAPIID: any = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      plans: [],
      loading: false,
      activeFilter: "month",
      normalPlans: [],
      currentPlan: {},
      showSuccessAlert: false,
      alertMessage: "Plan Updated Successfully! ",
      subscriptionModal: false,
      pauseModal: false,
      selectedPlan: [],
      pauseReason: "",
      otherReason: "",
      pausedAccount: {},
      isPaused: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleCurrentBuPlan(responseJson: any) {
    let filteredData = this.filterData(responseJson.data, "month");
    let filteredPlans = filteredData.filter(
      (e: any) =>
        e.attributes.name === "Essentials" ||
        e.attributes.name === "Standard" ||
        e.attributes.name === "Professional"
    );
    this.setState({
      plans: filteredPlans,
      normalPlans: responseJson.data,
    });

    const account = filteredData.filter(
      (e: any) => e.attributes.name === "Paused_Account"
    )[0];
    this.setState({ pausedAccount: account });
  }
  handlePauseReasonChange = (value: any) => {
    this.setState({ pauseReason: value, otherReason: value });
  };

  handleOtherReason = (value: any) => {
    this.setState({ otherReason: value });
  };

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.pauseAccountAPIID) {
        this.setState({ alertMessage: responseJson.message });
        this.setState({ showSuccessAlert: true });
        this.closePauseModal();
        setTimeout(() => {
          this.setState({ showSuccessAlert: false });
        }, 2000);
      }

      if (responseJson.data) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.subscriptionPlansAPIID) {
          this.handleCurrentBuPlan(responseJson);
        }

        if (apiRequestCallId === this.getCurrentBuPlanAPIID) {
          this.handlePause(responseJson)
          this.setState({ currentPlan: responseJson.data.attributes.plan.id });
        }

        if (apiRequestCallId === this.updateCurrentBuPlanAPIID) {
          this.redirectPause()
          this.setState({ alertMessage: "Plan Updated Successfully! " });
          this.setState({ showSuccessAlert: true });
          this.closeFormModal();
          this.getCurrentBuPlan();
          setTimeout(() => {
            this.setState({ showSuccessAlert: false });
          }, 2000);
        }
      } else if (responseJson.errors) {
        this.setState({ loading: false });
        let err = getErrorMessage(responseJson.errors[0]);
        alert(err);
      }
    }
  }

  handlePause(responseJson:any){
    if (responseJson.data.attributes.status === "paused") {
      this.setState({ isPaused: true });
    }
  }

  redirectPause(){
    if (this.state.isPaused) {
      window.location.href = "/Dashoard";
    }
  }

  async componentDidMount(): Promise<void> {
    this.getuserSubscriptionPlans();
    this.getCurrentBuPlan();
  }

  closeFormModal = () => {
    this.setState({ subscriptionModal: false });
  };
  openFormModal = () => {
    this.setState({ subscriptionModal: true });
  };

  closePauseModal = () => {
    this.setState({ pauseModal: false });
  };
  openPauseModal = () => {
    this.setState({ pauseModal: true });
  };

  platformToggling(id: any) {
    const newData = this.state.plans.map((x: any) => {
      if (x.attributes.id === id) {
        x.isPlatformManagement = !x.isPlatformManagement;
      } else {
        x.isPlatformManagement = false;
      }
      return x;
    });
    this.setState({ plans: newData });
  }

  handleFilter(type: string) {
    let filteredData = this.filterData(this.state.normalPlans, type);
    let filteredPlans = filteredData.filter(
      (e: any) =>
        e.attributes.name === "Essentials" ||
        e.attributes.name === "Standard" ||
        e.attributes.name === "Professional"
    );
    this.setState({ activeFilter: type, plans: filteredPlans });
    const account = filteredData.filter(
      (e: any) => e.attributes.name === "Paused_Account"
    )[0];
    this.setState({ pausedAccount: account });
  }

  filterData(data: any, interval: string) {
    return data
      .map((product: any) => {
        const filteredPlans = product.attributes.plans.filter(
          (plan: any) => plan.interval === interval
        );
        return {
          ...product,
          attributes: {
            ...product.attributes,
            plans: filteredPlans,
          },
        };
      })
      .filter((product: any) => product.attributes.plans.length > 0);
  }

  getuserSubscriptionPlans() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subscriptionPlansAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.settingsSubscriptionPlansEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getCurrentBuPlan() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrentBuPlanAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentBuPlanEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  updateBuPlan(plan: any) {
    const headerData = {
      "Content-Type": configJSON.contentType,
      Token: localStorage.getItem("authToken"),
    };
    const platform_management = !!plan?.attributes?.isPlatformManagement;

    const attrs = {
      stripe_price_id: plan.attributes.plans[0].stripe_plan_id,
      plan_id: plan.attributes.plans[0].id,
      platform_management: platform_management,
    };

    const httpBody = {
      subscription: attrs,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCurrentBuPlanAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCurrentBuPlanEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  pauseBuAccout = () => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let data = {
      reason: this.state.otherReason,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pauseAccountAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pauseBuAccountEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
}

// Customizable Area End
