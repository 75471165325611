import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { StepType } from "./enums";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepType: StepType;
  email: string;
  isOpenResendMessage: boolean;
  newPassword: string;
  confirmNewPassword: string;
  displayNewPassword: boolean;
  displayConfirmNewPassword: boolean;
  resetPasswordError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiForgotPasswordCallId: string = "";
  apiResetPasswordCallId: string = "";

  labelEverybrand = configJSON.labelEverybrand;
  labelForgotPassword = configJSON.labelForgotPassword;
  labelNoWorries = configJSON.labelNoWorries;
  labelEmail = configJSON.firstInputPlaceholder;
  labelEmailPlaceholder = configJSON.labelEmailPlaceholder;
  labelSendRecoveryLink = configJSON.labelSendRecoveryLink;
  labelBackTo = configJSON.labelBackTo;
  labelLogIn = configJSON.labelLogIn;
  labelPasswordRecovery = configJSON.labelPasswordRecovery;
  labelCheckYourEmail = configJSON.labelCheckYourEmail;
  labelResetLink = configJSON.labelResetLink;
  labelResendEmail = configJSON.labelResendEmail;
  labelSuccessResend = configJSON.labelSuccessResend;
  labelConfirmEmailText = configJSON.labelConfirmEmailText;
  labelOpenMail = configJSON.labelOpenMail;
  labelNewPassword = configJSON.labelNewPassword;
  labelEnterNewPasswordFor = configJSON.labelEnterNewPasswordFor;
  labelNewPasswordPlaceholder = configJSON.labelNewPasswordPlaceholder;
  labelConfirmNewPassword = configJSON.labelConfirmNewPassword;
  labelConfirmNewPasswordPlaceholder =
    configJSON.labelConfirmNewPasswordPlaceholder;
  labelYourPasswordMustContain = configJSON.labelYourPasswordMustContain;
  labelAtLeastOneCapitalLetter = configJSON.labelAtLeastOneCapitalLetter;
  labelAtLeastOneLoweraseLetter = configJSON.labelAtLeastOneLoweraseLetter;
  labelAtLeastOneNumber = configJSON.labelAtLeastOneNumber;
  labelMinLengt = configJSON.labelMinLengt;
  labelSetNewPassword = configJSON.labelSetNewPassword;
  labelPasswordIsRequired = configJSON.labelPasswordIsRequired;
  labelPasswordMustMatch = configJSON.labelPasswordMustMatch;
  labelConfirmPasswordIsRequired = configJSON.labelConfirmPasswordIsRequired;
  labelPasswordIsInvalid = configJSON.labelPasswordIsInvalid;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      stepType: StepType.FORGOT_PASSWORD,
      email: "",
      isOpenResendMessage: false,
      newPassword: "",
      confirmNewPassword: "",
      displayNewPassword: false,
      displayConfirmNewPassword: false,
      resetPasswordError: ""
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({email:new URLSearchParams(document.location.search).get('email')!})
  }

  emailSchema = {
    email: Yup.string()
      .email(configJSON.invalidEmailAddress)
      .required(configJSON.emailIsRequired)
  };

  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  passwordSchema = {
    newPassword: Yup.string()
      .required(this.labelPasswordIsRequired)
      .matches(this.passwordRegex, this.labelPasswordIsInvalid),
    confirmNewPassword: Yup.string()
      .required(this.labelConfirmPasswordIsRequired)
      .oneOf([Yup.ref("newPassword"), null], this.labelPasswordMustMatch)
  };

  passwordMustContain = [
    { label: this.labelAtLeastOneCapitalLetter, regex: /^(?=.*[A-Z]).+$/ },
    { label: this.labelAtLeastOneLoweraseLetter, regex: /^(?=.*[a-z]).+$/ },
    { label: this.labelAtLeastOneNumber, regex: /^(?=.*\d).+$/ },
    { label: this.labelMinLengt, regex: /^.{8,}$/ }
  ];

  setEmail = (value: string) => {
    this.setState({ email: value });
  };

  openResendMessageDialog = () => {
    this.handleResendPasswordLink();
    this.setState({ isOpenResendMessage: true });
  };

  closeResendMessageDialog = () => {
    this.setState({ isOpenResendMessage: false });
  };

  handleSubmitForgotPasswordForm = () => {
    this.sendResetPasswordLink();

    this.setState({
      stepType: StepType.PASSWORD_RECOVERY
    });
  };

  handleResendPasswordLink = () => {
    this.sendResetPasswordLink();
  };

  handleSubmitResetPasswordForm = async () => {
    try {
      await this.doResetPassword();
      this.goToLogin();
    } catch (error) {}
  };

  setNewPassword = (value: string) => {
    this.setState({
      newPassword: value
    });
  };

  setConfirmNewPassword = (value: string) => {
    this.setState({
      confirmNewPassword: value
    });
  };

  toggleNewPasswordVisibility = () => {
    this.setState({
      displayNewPassword: !this.state.displayNewPassword
    });
  };

  toggleConfirmNewPasswordVisibility = () => {
    this.setState({
      displayConfirmNewPassword: !this.state.displayConfirmNewPassword
    });
  };

  handleOpenMail = () => {
    const mailtoLink = `mailto:${this.state.email}`;
    window.open(mailtoLink, "_blank");
  };

  goToLogin() {
    this.props.navigation.navigate("Login")
  }

  async sendResetPasswordLink() {
    const data = {
      email: this.state.email
    };

    const header = {
      "Content-Type": configJSON.forgotPasswordApiContentType
    };

    const method = configJSON.httpPutMethod;

    const endPoint = configJSON.forgotPasswordEndPoint;

    const body = {
      data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.apiForgotPasswordCallId = requestMessage.messageId;
  }

  async doResetPassword() {
    this.setState({
      resetPasswordError: ""
    });

    const searchParams = new URLSearchParams(document.location.search); // eslint-disable-line

    const token = searchParams.get("token");

    if (!token) {
      this.setState({
        resetPasswordError: configJSON.labelResetPasswordTokenError
      });
      return;
    }

    const header = {
      "Content-Type": configJSON.resetPasswordApiContentType
    };

    const method = configJSON.httpPutMethod;

    const endPoint = configJSON.resetPasswordEndPoint;

    const body = {
      new_password: this.state.newPassword,
      password_confirmation: this.state.confirmNewPassword,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.apiResetPasswordCallId = requestMessage.messageId;
  }
  // Customizable Area End
}
