import React from "react";

export const Pricing = ({currency, amount, interval}: any) => {
  // Define all styles in a single object
  const styles: any = {
    indPlanRates: {
      display: "flex",
      alignItems: "center",
      maxWidth: "100%",
    },
    price: {
      fontSize: "42px",
      fontWeight: "bold",
      display: "inline-flex",
      alignItems: "center",
    },
    curSign: {
      fontSize: "22px",
      fontWeight: "600",
      paddingTop:'12px'
    },
    currencyType: {
      display: "inline-flex",
      flexDirection: "column",
    },
    currencyTypeSpanFirst: {
      paddingLeft: 10,
      fontSize: "16px",
      fontWeight: "bold",
      textTransform:"capitalize",
      position: "relative",
      top: "5px"
    },
    currencyTypeSpanLast: {
      fontSize: "20px",
      fontWeight: "bold",
      textTransform:"capitalize",
      position: "relative",
      bottom: "5px"
    },
  };

  return (
    <h4 style={styles.indPlanRates}>
      <div style={styles.price}>
        <span style={styles.curSign}>$</span>
        <span>{amount}</span>
      </div>
      <div style={styles.currencyType}>
        <span style={styles.currencyTypeSpanFirst}>{currency}</span>
        <span style={styles.currencyTypeSpanLast}>/{interval}</span>
      </div>
    </h4>
  );
};
