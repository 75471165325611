// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Card,
  Grid,
  Box,
  Divider,
  Snackbar,
  Modal,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextareaAutosize,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import UpdatesubscriptionController from "./UpdatesubscriptionController.web";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Pricing } from "../../../components/src/SubscriptionsPrice.web";
import { EverybrandIcon, infoIcon, radioButtonOff, radioButtonOn } from "./assets";
import { pauseSubscriptionReasons } from "./constants";

export class Updatesubscription extends UpdatesubscriptionController {
  getPlanInterval(interval: string) {
    return interval === "year" ? "Yearly" : interval
  }

  renderTextArea(reason: string) {
    if (reason === "other") {
      return <TextareaAutosize data-test-id="textAreaField" minRows={5} onChange={(e)=>{this.handleOtherReason(e.target.value)}}/>
    }
    return ""
  }
  render() {
    const { classes }: any = this.props;
    return (
      <Container maxWidth={false} className={classes.subscritpionMainCOntainer}>
        <Modal
          open={this.state.subscriptionModal}
          onClose={this.closeFormModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div
            className={classes.modalBox}>
            <Typography className={classes.infotextS2} style={{textAlign:"center"}}>
              Are you sure you want to update subscription?
            </Typography>
            <Button
              onClick={() => { this.updateBuPlan(this.state.selectedPlan) }}
              className={classes.modalBtn2}
            >
              Yes
            </Button>
            <Button
              data-test-id="No_button"
              onClick={() => this.closeFormModal()}
              className={classes.modalBtnDark2}
            >
              No
            </Button>
          </div>
        </Modal>
        <Modal
          open={this.state.pauseModal}
          onClose={this.closePauseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div
            className={classes.pauseModal}>
            <Typography className={classes.infotextS2}>
              Why do you want to pause your subscription?
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                data-test-id="radioOnChange"
                value={this.state.pauseReason}
                onChange={(e) => this.handlePauseReasonChange(e.target.value)}
              >
                {pauseSubscriptionReasons.map((reason) => {
                  return <FormControlLabel
                    key={reason}
                    value={reason}
                    control={<Radio style={{ color: "#000" }} />}
                    label={reason}
                  />
                })}
                <FormControlLabel
                  value="other"
                  control={<Radio style={{ color: "#000" }} />}
                  label="Other"
                />

              </RadioGroup>
              {this.renderTextArea(this.state.pauseReason)}
            </FormControl>
            <Box className={classes.pauseModalBtn}>
              <Button
                data-test-id="pauseAccountBtn"
                onClick={() => this.pauseBuAccout()}
                className={classes.modalBtn3}
              >
                Accept
              </Button>
              <Button
                data-test-id="closePauseModalBtn"
                onClick={() => this.closePauseModal()}
                className={classes.modalBtnDark3}
              >
                Cancel
              </Button>
            </Box>
          </div>
        </Modal>
        <Snackbar
          open={this.state.showSuccessAlert}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            data-test-id="webUpdateAlert"
            elevation={6}
            variant="filled"
            severity={"success"}
            style={{backgroundColor: "#000"}}
          >
            {this.state.alertMessage}
          </MuiAlert>
        </Snackbar>
        <Box className={classes.everyBrandBox}>
          <Typography
            className={classes.webHeadingSus}
            data-test-id="webHeadingSus"
          >
            Update Subscription
          </Typography>
          <img src={EverybrandIcon} alt="" />
        </Box>
        <Box className={classes.buttonsBox}>
          <Button
            data-test-id="annualButton"
            className={
              this.state.activeFilter === "year"
                ? classes.activeSelecetBtn
                : classes.selectBtns
            }
            startIcon={
              this.state.activeFilter === "year" ? (
                <img src={radioButtonOn} alt=""/>
              ) : (
                <img src={radioButtonOff} alt=""/>
              )
            }
            onClick={() => this.handleFilter("year")}
          >
            Annual Pricing
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />{" "}
          <Button
            data-test-id="monthlyButton"
            className={
              this.state.activeFilter === "month"
                ? classes.activeSelecetBtn
                : classes.selectBtns
            }
            startIcon={
              this.state.activeFilter === "month" ? (
                <img src={radioButtonOn} alt=""/>
              ) : (
                <img src={radioButtonOff} alt=""/>
              )
            }
            onClick={() => this.handleFilter("month")}
          >
            Monthly Pricing
          </Button>
        </Box>
        <Grid container>
          {this.state.plans.map((x: any) => (
            <Grid key={x.id} item md={6} lg={4} sm={12}>
              <Card
                data-test-id="plansCard"
                className={
                  x.attributes.plans[0].id === this.state.currentPlan
                    ? classes.activeplanCard
                    : classes.plansCard
                }
              >
                <Typography className={classes.webHeading}>
                  {x.attributes.name}
                </Typography>
                <Box sx={{ ...commonCss.dFlex }}>
                  <FiberManualRecordIcon style={{ ...commonCss.bulletSmall }} />
                  <Typography>
                    <span style={{ ...commonCss.spantag }}>
                      {x.attributes.description.split(":")[0]}
                      {": "}
                    </span>{" "}
                    {x.attributes.description.split(":")[1]}
                  </Typography>
                </Box>
                <Pricing
                  currency={x.attributes.plans[0].currency}
                  amount={x.isPlatformManagement ? (parseFloat(x.attributes.plans[0].price) + 1000).toString() + ".0" : x.attributes.plans[0].price}
                  interval={this.getPlanInterval(x.attributes.plans[0].interval)}
                />
                <Typography className={classes.cardPara}>
                  <span style={{ ...commonCss.spantag }}>
                    {x.attributes?.service?.max_contacts_count}
                  </span>{" "}
                  contacts
                </Typography>
                <Typography className={classes.cardPara}>
                  <span style={{ ...commonCss.spantag }}>
                    {x.attributes.service?.max_members_count}
                  </span>{" "}
                  team members
                </Typography>
                <Typography className={classes.cardPara}>
                  {" "}
                  <span style={{ ...commonCss.spantag }}>
                    {x.attributes.name === "Essentials"
                      ? "Includes"
                      : "Everything in Essential, plus:"}
                  </span>
                </Typography>
                {x.attributes.service?.permissions.map((item: string) => (
                  <Box key={item} sx={{ ...commonCss.dFlex }}>
                    <FiberManualRecordIcon
                      style={{ ...commonCss.bulletSmall }}
                    />
                    <Typography className={classes.cardPara}>{item}</Typography>
                  </Box>
                ))}
                {x.attributes.name === "Essentials" ? null : (
                  <Box
                    sx={{ ...commonCss.dFlex, justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        ...commonCss.dFlex,
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={infoIcon} alt="" className={classes.infoIcon} />
                      <Typography className={classes.cardPara}>
                        Add platform <br />
                        management
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => this.platformToggling(x.attributes.id)}
                      className={
                        x.isPlatformManagement
                          ? classes.activeSwitch
                          : classes.inActiveSwitch
                      }
                    >
                      <FiberManualRecordIcon style={{ color: "#FFF" }} />
                    </Box>
                  </Box>
                )}
                <Button
                  onClick={() => {
                    this.setState({ selectedPlan: x })
                    this.openFormModal()
                  }}
                  variant="contained"
                  className={classes.selectBtnBottom}
                  data-test-id="selectBTN"
                >
                  Select
                </Button>
              </Card>
            </Grid>
          ))}

        </Grid>
        <Card
          data-test-id="plansCard"
          className={classes.pauseCard}
        >
          <Grid container spacing={8} justifyContent="center">
            <Grid item md={9} lg={9} sm={9}><Typography className={classes.webHeading}>
              Pause my subscription
            </Typography>
              <Box sx={{ ...commonCss.dFlex }}>
                <FiberManualRecordIcon style={{ ...commonCss.bulletSmall }} />
                <Typography>
                  <span style={{ ...commonCss.spantag }}>
                    Considering a break
                    {"? "}
                  </span>{" "}
                  Pause your subscription instead of canceling. Give yourself the flexibility to return without losing your current plan.  you can maintain your benefits. Contact us to pause or cancel your account. We're here to assist you!
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} lg={3} sm={3}>
              <Pricing
                currency={this.state.pausedAccount?.attributes?.plans[0]?.currency}
                amount={this.state.pausedAccount?.attributes?.plans[0]?.price}
                interval={this.getPlanInterval(this.state.pausedAccount?.attributes?.plans[0]?.interval)}
              />
              <Button
                onClick={() => { this.openPauseModal() }}
                variant="contained"
                className={classes.textusBottom}
                data-test-id="openPauseModalBtn"
              >
                Text Us
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    );
  }
}

export const commonCss: any = {
  activeSwitch: {
    backgroundColor: "#FFC629",
    width: "47px",
    height: "25px",
    borderRadius: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    cursor: "pointer",
    transition: "background-color 0.8s ease, justify-content 0.8s ease",
  },
  inActiveSwitch: {
    backgroundColor: "#ABB0BC",
    width: "47px",
    height: "25px",
    borderRadius: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    cursor: "pointer",
    transition: "background-color 0.8s ease, justify-content 0.8s ease",
  },
  spantag: {
    fontWeight: "bold",
    color: "#475569",
    textTransform: "capitalize",
  },
  bulletSmall: {
    fontSize: "12px",
    margin: "6px 5px 0 0",
    color: "#475569",
  },
  cardPara: {
    textTransform: "capitalize",
  },
  greyColor: {
    color: "#475569",
  },
  button: {
    backgroundColor: "#FFC629",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    padding: "5px 40px",
  },
  btnsSelect: {
    color: "#0F172A",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    padding: "20px",
  },
  dFlex: {
    display: "flex",
    flexDirection: "row",
  },
  plansCard: {
    borderRadius: "20px",
    background: "#FFF",
    padding: "20px 30px",
    margin: "10px",
    height: "650px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
const styles = (theme: Theme) =>
  createStyles({
    infoIcon: {
      height: "18px",
      width: "18px",
      margin: "5px 5px 0 0"
    },
    everyBrandBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    webHeading: {
      color: "#0F172A",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
    activeSwitch: {
      backgroundColor: "#FFC629",
      width: "47px",
      height: "25px",
      borderRadius: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      cursor: "pointer",
    },
    modalBtn2: {
      ...commonCss.button,
      padding: "20px 80px",
      width: "100%",
      margin: "10px 0",
    },
    modalBtn3: {
      ...commonCss.button,
      display: "block",
      padding: "20px 80px",
      width: "40%",
      margin: "10px 0",
    },
    modalBtnDark2: {
      ...commonCss.button,
      backgroundColor: "#000",
      color: "#FFFFFF",
      padding: "20px 80px",
      margin: "10px 0",
      width: "100%",
      "&:hover": {
        color: "#000",
      },
    },
    modalBtnDark3: {
      ...commonCss.button,
      backgroundColor: "#000",
      color: "#FFFFFF",
      display: "block",
      padding: "20px 80px",
      margin: "10px 0",
      width: "40%",
      "&:hover": {
        color: "#000",
      },
    },
    inActiveSwitch: {
      backgroundColor: "#ABB0BC",
      width: "47px",
      height: "25px",
      borderRadius: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      cursor: "pointer",
    },
    selectBtnBottom: {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      padding: "10px 0",
      color: "#0F172A",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
    },
    textusBottom: {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      padding: "10px",
      color: "#0F172A",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      width: "100%"
    },
    cardParaSubTextStyle: {
      fontWeight: "bold",
      letterSpacing: "0.075em",
    },
    cardParaStyle: {
      textTransform: "capitalize",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000",
    },
    cardParaSpanStyle: {
      fontSize: "14px",
      verticalAlign: "super",
    },
    cardParas: {
      ...commonCss.greyColor,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "19.5px",
      padding: "20px 0",
    },
    plansCard: {
      ...commonCss.plansCard,
      border: "1px solid #ABB0BC",
    },
    pauseCard: {
      borderRadius: "20px",
      background: "#FFF",
      padding: "20px 30px",
      margin: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid #ABB0BC",
    },
    activeplanCard: {
      ...commonCss.plansCard,
      border: "6px solid #000",
    },
    btnDivider: {
      height: "auto",
      alignSelf: "stretch",
      border: "1px solid #C0C4CD",
      margin: "0 15px",
    },
    buttonsBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "20px 0",
    },
    selectBtns: {
      ...commonCss.btnsSelect,
    },
    activeSelecetBtn: {
      ...commonCss.btnsSelect,
      borderRadius: "5px",
      backgroundColor: "#D9D9D9",
    },
    teamParaBtnB: {
      color: "#64748B",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "18px",
    },
    subscritpionMainCOntainer: {
      padding: "50px",
    },
    modalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "32px 48px 24px",
      width: "450px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 40px 0",
    },
    infotextS2: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "30px 0",
    },
    webHeadingSus: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
    cardHeadingTeam: {
      color: "#0F172A",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
    },
    cardParaTeam: {
      color: "#64748B",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
    pauseModal: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "32px 48px 24px",
      width: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 40px 0",
    },
    pauseModalBtn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  });

export default withStyles(styles)(Updatesubscription);
// Customizable Area End
