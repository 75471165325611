// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from '../../../framework/src/BlockComponent';
export const configJSON = require('./config.js');
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { thumbnail } from "./assets";
import SwiperCore from 'swiper';
import { getStorageData } from "../../../framework/src/Utilities";
interface BrandAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_name: string | null;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  is_first_login: boolean;
  profile_pic: {
    url : string
  } | null;
  cover_photo: {
    url : string
  } | null;
  follows: {
      followers: string;
      followings: string;
  };
  subscription_paused: boolean;
}
interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}

type IFollowBrandApiResponse =
  { data: Brand } | {
    errors: string[]
  } | { error: { message: string }}


interface ProfileEU {
  id: number | null;
  country: string | null;
  city: string | null;
  postal_code: string | null;
  account_id: number | null;
  personality_prompt: string[];
  headline: string | null;
  name: string | null;
  email: string | null;
  reviews: number;
  average_rating: number;
  photo: string | null;
  followers: number | string | null;
  following: number | string | null;
}

interface WorkingHour {
  id: number;
  account_id: number;
  day: number;
  active: boolean;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  day_name: string;
}

interface AccountProfile {
  url: string;
  content_type: string;
}

interface PollOption {
  id: number;
  post_poll_id: number;
  name: string;
  vote_count: number;
  created_at: string;
  updated_at: string;
}

interface AccountAttributes {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  full_name: string;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role_id: string | null;
  gender: string | null;
  date_of_birth: string | null;
  age: string | null;
  sign_up_step: number;
  business_website: string | null;
  zip_code: string | null;
  role_name: string;
  pin: number;
  valid_until: string | null;
  working_hours: WorkingHour[];
  holiday_hours: any[];
  holiday_hours_activated: boolean;
  is_accepted_privacy_policy: boolean;
  ip_address: string | null;
  privacy_policy_accepted_at: string | null;
  social_phone_number: string | null;
  is_first_login: boolean;
  widget_code: string;
  is_chat_clicked: string | null;
}

interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface CommentAttributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  visibility: string;
  likes_count: number;
  comments_count: number;
  created_at: string;
  archived: boolean;
  account: { data: Account };
  replies: { data: any[] };
  already_liked: boolean | null;
  files: any;
}

interface Comment {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface Comments {
  data: Comment[];
}

interface Poll {
  id: number;
  post_id: number;
  question: string;
  created_at: string;
  updated_at: string;
}

interface Setting {
  id: number;
  settable_type: string;
  settable_id: number;
  social_id: number | null;
  choices_arrival_time: string | null;
  multiple_selection: boolean;
  quality_upload: boolean;
  comment_allowed: boolean;
  created_at: string;
  updated_at: string;
}

interface File {
  link: string;
}

interface Attributes {
  body: string;
  description: string;
  account_id: number;
  likes_count: number;
  comments_count: number;
  is_direct_post: string | null;
  created_at: string;
  account: AccountAttributes;
  account_profile: AccountProfile;
  model_name: string;
  poll: Poll;
  setting: Setting;
  files: File[];
  thumbnail: string | null;
  poll_options: PollOption[];
  comments: Comments;
  reviews: any[];
  is_liked: boolean | null;
}

interface PostsEU {
  id: string;
  type: string;
  attributes: Attributes;
}


export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}
interface S {
  personalities: any;
  name:string;
  email:string;
  currentcity:string;
  currentstate:string;
  aboutprofile:string;
  isLoading:boolean;
  profileImage:any;
  userToken:string | null;
  hoverImg: boolean;
  isShowAlert: boolean;
  showSucess:string;
  isProfileLoading:boolean;
  selectedFile: any,
  selectedProfileImage: any,
  reels: {
    id: number;
    thumbnailUrl: string;
    tag?: 'Trending' | 'Newest';
    views: number;
  }[];
  currentSlide: number;
  brandsIFollow: Brand[];
  suggestedBrands: Brand[];
  top6BrandProfile: Brand[]
  profileDataEU: ProfileEU;
  profileReview:any;
  followers:number;
  following:number;
  posts:any;
  profile:any;
  followersData:any,
  userDetails:any

  isEndUser: boolean;
  postsDataEU: PostsEU[];
  userID: string | null;
  isFollowing: boolean;
  selfProfile: string
}
interface SS {
  id: any;
}

export default class UserProfileController extends BlockComponent<Props, S, SS> {

  userProfileGetApiId: any = "";
  updateprofileapiId: any = "";
  updateImgProfileApiId : any = ""
  euProfileBrandsApiCallId: string ="";
  euProfileApiCallId: string = "";
  userDetailsApiCallId: any = ""
  followersApiCallId: any = ""
  swiper: SwiperCore | null = null;
  followBrandProfileApiCallId: string = ""


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
  ];

    this.state = {
      personalities: ['Introverted or extroverted', 'Get in early to work, or stay late...', 'My real-life super power is...', 'The best lesson I´ve learned from failure...','If I could have an extra hour in the day, I´d...','I quote too much from...'],
      name:"",
      email:"",
      currentcity:"",
      currentstate:"",
      aboutprofile:"",
      userToken:this.userTokenVal,
      isLoading:true,
      profileImage:"",
      hoverImg: false,
      isShowAlert: false,
      showSucess:"",
      isProfileLoading:false,
      selectedFile:null,
      selectedProfileImage: "",
      reels: [
        { id: 1, thumbnailUrl: thumbnail, tag: 'Trending', views: 1050 },
        { id: 2, thumbnailUrl: thumbnail, views: 5000 },
        { id: 3, thumbnailUrl: thumbnail, tag: 'Trending', views: 975 },
        { id: 4, thumbnailUrl: thumbnail, tag: 'Newest', views: 450 },
        { id: 5, thumbnailUrl: thumbnail, views: 50 },
        { id: 6, thumbnailUrl: thumbnail, views: 50 },
        { id: 7, thumbnailUrl: thumbnail, views: 456 },
        { id: 8, thumbnailUrl: thumbnail, views: 368 },
        { id: 9, thumbnailUrl: thumbnail, views: 368 },
      ],
      currentSlide: 0,
      brandsIFollow: [],
      suggestedBrands: [],
      top6BrandProfile: [],
      profileDataEU: {
        id: null,
        country: null,
        city: null,
        postal_code: null,
        account_id: null,
        personality_prompt: [],
        headline: null,
        name: "",
        email: null,
        reviews: 0,
        average_rating: 0,
        photo: null,
        followers: 0,
        following: 0
      },
      profileReview: {},
      followers:0,
      following:0,
      posts:[],
      profile:[],
      followersData: [],
      userDetails:[],
    isEndUser: false,
    postsDataEU: [],
    userID: null,
    isFollowing: false,
    selfProfile: "false"
    };
  
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    let euToken = await getStorageData("EUAuthToken");
    const id = this.props.navigation.getParam('navigationBarTitleText')!
    this.setState({userID: id})
    this.setState({userToken: euToken || this.state.userToken, isEndUser: !!euToken})
    this.user_profile_api()
    setTimeout(() => {
      this.setState({
        isLoading:false
      })
    }, 7000);
    this.getUserDetails(id);
    this.getFollowers();
    if(this.state.isEndUser || euToken){
      this.getSuggestedAndFollowBrand();
      this.getEUProfileData(id);
    }
  }

  componentDidUpdate(prevProps :Props, prevState: S) {
    if (prevState.userToken !== this.state.userToken) {
      this.user_profile_api()
    }
    const id = this.props.navigation.getParam('navigationBarTitleText')!
    if(prevState.userID && prevState.userID !== id ){
      this.setState({userID: id})
      if(this.state.isEndUser){
        this.getEUProfileData(id);
      }
      else{
        this.getUserDetails(id)
      }
    }
  }

  getEUProfileData = async(id : string | undefined) => {
    const EuToken = await getStorageData("EUAuthToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      id ? configJSON.user_profile_view + `/${id}` : configJSON.endPointApiGetEUProfile
    ); 
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUProfile,
      token: EuToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSuggestedAndFollowBrand = async () => {
    const EuToken = await getStorageData("EUAuthToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euProfileBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetEUProfileBrands
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUProfileBrands,
      token: EuToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUProfileBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNextSlide = () => {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  };

  handlePrevSlide = () => {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  };

  onSlideChange = (swiper: SwiperCore) => this.setState({ currentSlide: swiper.activeIndex });
  onSwipe=(swiper:SwiperCore) => (this.swiper = swiper);

  userTokenVal= localStorage.getItem('authToken');
  handleFieldChange = (e:any) => {
    const { name, value }: { name: string; value: string } = e.target;
    this.setState({
        [name]: value,
    } as unknown as Pick<S, keyof S>)

};

showHoverImg = () =>{
  this.setState({
      hoverImg: true,
    });
}
hideHoverImg = () =>{
  this.setState({
      hoverImg: false,
    });
}
handleFileChange = (event: any) => {
  const file = event && event.target && event.target.files[0];
  
  console.log(file, "fileee");

  // Update the component state with the newly selected profile image
  this.setState({
    selectedFile: file,
    selectedProfileImage: URL.createObjectURL(file), // Set the URL for preview
  });
  
}
handleSaveChanges=()=>{
  if (this.state.selectedFile) {
    // If a new image is selected, upload it and then call the update_profile_api function
    const formData = new FormData();
    formData.append('photo', this.state.selectedFile);
    
    // Call the function to upload the image
    this.upload_image_api(formData)
   
  }
  
    this.update_profile_api()
  
}
async update_profile_api() {
  this.setState({isLoading: true})
  const attrs = {
      name: this.state.name,
      email: this.state.email,
      profile_attributes:{
        country:this.state.currentstate,
        city:this.state.currentcity,
        headline:this.state.aboutprofile,
      },
  };
 
  const httpBody = {
      profile: attrs,
  };
  
  const headerData={
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token":this.state.userToken
  }
  let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateprofileapiId =  requestMessage.messageId;
  requestMessage.addData (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headerData)
);
requestMessage.addData (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.update_profile
);
       requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.update_profileMethod,
);
   requestMessage.addData(
       getName(MessageEnum.RestAPIRequestBodyMessage),
       JSON.stringify(httpBody)
    );
runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;

}

async user_profile_api() {
  const headerData={
      "token":this.state.userToken,
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
  }
  let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.userProfileGetApiId =  requestMessage.messageId;
  requestMessage.addData (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headerData)
);

       requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.user_profileMethod,
);
requestMessage.addData (
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.user_profile
);
runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;

}

async upload_image_api(formData:any) { 
  this.setState({ isProfileLoading: true,hoverImg:false });
  const headerData={
      "token":this.state.userToken,
  }
  let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateImgProfileApiId =  requestMessage.messageId;
  requestMessage.addData (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headerData)
);
requestMessage.addData (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upload_image
);
       requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.update_profileMethod,
);
   requestMessage.addData(
       getName(MessageEnum.RestAPIRequestBodyMessage),
       formData
    );
runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;

}

apicallresponseData = (responseJson: any, apiRequestCallId: string) => {
   if(!(responseJson.error || responseJson.errors)) {
    if(apiRequestCallId === this.updateImgProfileApiId){
      this.update_profile_api()
    }
    this.setState({
      isLoading: false,
      name:responseJson.data.attributes.name,
      email:responseJson.data.attributes.email,
      aboutprofile:responseJson.data.attributes.headline,
      currentcity:responseJson.data.attributes.city,
      currentstate:responseJson.data.attributes.country,
      profileImage:responseJson.data.attributes.photo !== null ? responseJson.data.attributes.photo : "",
      isProfileLoading:false
    })
  }
}

handleFollowBrandApiSuccessResponse = (responseJson: IFollowBrandApiResponse) => {
  this.setState(prevState => ({...prevState, isFollowing: !prevState.isFollowing}))
  if(typeof responseJson === "object" && "data" in responseJson) {
    this.getSuggestedAndFollowBrand()
  }
}

isShowCell = () => {
  setTimeout(() => this.setState({ isShowAlert: false }), 3000);
}

handleCancleChanges = () =>{
  this.user_profile_api()
  
  this.setState({ isShowAlert: true,showSucess:"Changes Reverted Successfully!",
  name: "",  // Reset name field
    email: "", // Reset email field
    currentcity: "", // Reset currentcity field
    currentstate: "", // Reset currentstate field
    aboutprofile: "", // Reset aboutprofile field
    selectedProfileImage:""
 }, () => {
    this.isShowCell()
  })
  if(this.state.isEndUser) this.handleNavigateToProfileBack()
};

sendInfoToMainLayout = (data: any) => {
  const msg = new Message(getName(MessageEnum.ConnectTOSidebar))
  msg.addData(getName(MessageEnum.ShouldUpdate), data)
  this.send(msg)
}

//added the new API call for userDetails

getUserDetails = (id : string | undefined) => {

  const headers = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem('authToken') || '',
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.userDetailsApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    id ? configJSON.user_profile_view + `/${id}` : configJSON.endPointApiProfileDashboard
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodApiProfileDashboard
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

};
isVideo = (file: any) => {
  return (
    file.link.toLowerCase().endsWith(".mp4") || file.mime_type === "video/mp4"
  );
};
getFollowers = ()=> {
  const headers = {
    token: window.localStorage.getItem('authToken') || '',
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.followersApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    '/bx_block_followers/follows/follower_list'
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage)
};

followBrandApiProfile = async (followBrandId: string | null, isEndUser: boolean = true, isFollowing: boolean = false) => {
  const token = await getStorageData(isEndUser ? configJSON.EU_AUTH_TOKEN : configJSON.AUTH_TOKEN)
  const httpBody = {
    data:{
        attributes:{
            followed_user_id: followBrandId
        }
    }
  }
  const headerData = {
    token,
    "Content-Type": configJSON.contentTypeApiGetUserProfile,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.followBrandProfileApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headerData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    isFollowing ? configJSON.methodTypeApiUnfollowUser : configJSON.httpPostMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    isFollowing ? configJSON.unfollowBrandApiEndpoint + followBrandId : configJSON.followBrandApiEndpoint
  );
  !isFollowing && requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
 )
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}


async receive(from: string, message: Message) {
  const messageType = getName(MessageEnum.RestAPIResponceMessage);
  
  if (messageType === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId == this.updateprofileapiId) {
        if (responseJson && !responseJson.errors) {
          this.sendInfoToMainLayout({refresh:true})
          this.setState({ isShowAlert: true,showSucess:responseJson.meta.message }, () => {
            this.isShowCell()
          })
          this.handleNavigateToProfileBack()
        }
      }else if (apiRequestCallId === this.euProfileBrandsApiCallId) {
        this.setState({
          brandsIFollow: responseJson.followings.data,
          suggestedBrands: responseJson.suggested_brands.data,
          top6BrandProfile: responseJson.top6.data
        })
      }else if (apiRequestCallId === this.euProfileApiCallId) {
        this.setState({profileDataEU: responseJson.data.profile.data.attributes, postsDataEU: responseJson.data.posts.data, isFollowing:responseJson.data?.following_status, selfProfile: responseJson.data?.self_profile})
      } else if (apiRequestCallId === this.userDetailsApiCallId) {
        this.setState({userDetails:responseJson, isFollowing:responseJson.data?.following_status, selfProfile: responseJson.data?.self_profile});
      } else if (apiRequestCallId === this.followersApiCallId) {
        this.setState({ followersData:responseJson});
      }

      const idToFunctionMapping = {
          [this.userProfileGetApiId]:this.apicallresponseData,
          [this.updateprofileapiId]:this.apicallresponseData,
          [this.updateImgProfileApiId]:this.apicallresponseData,
          [this.followBrandProfileApiCallId]: this.handleFollowBrandApiSuccessResponse
      };

      if (apiRequestCallId != null && idToFunctionMapping.hasOwnProperty(apiRequestCallId)) {
          const correspondingFunction = idToFunctionMapping[apiRequestCallId];
          correspondingFunction.call(this, responseJson, apiRequestCallId);
      }
  }

}

handleNavigateEditProfile = () => {
  const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EndUserProfile");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserSignupMessage)
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
};

handleNavigateToProfileBack = () => {
  const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EuProfile");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserSignupMessage)
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
}

}
// Customizable Area End
