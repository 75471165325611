// Customizable Area Start
import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Avatar, Box } from '@material-ui/core';
import { smileYellow } from './assets';
import TopActiveUsersController from './TopActiveUsersController.web';
const configJSON = require("./config.js")

const MainPaper = styled(Paper)(({
  theme
}) => ({
  padding: theme.spacing(2,2),
}))

const MainHeading = styled(Typography)({
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
})

const SubHeading = styled(Typography)({
  color: "#000",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
})

const ContentContainer = styled(Grid)(({
  theme
}) => ({
  padding: theme.spacing(2),
  justifyContent: 'center'
}))

const ProfileContainer = styled(Grid)({
  display:"grid",
  placeItems:"center",
  overflow:"hidden"
})

const BrandAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  border: '4px solid #FFC629',
  marginBottom: theme.spacing(1),
  backgroundColor: '#FFFFFF',
  cursor: "pointer"
}))

const BrandImage = styled('img')({
  width: '56px',
  height: '56px',
  objectFit:'cover'
})

const BrandName = styled(Typography)({
  color:"#0F172A",
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: "100%",
  fontFamily: "Inter",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "center",
})

const EmptyWrapper = styled(Box)({
  height: "150px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})

class TopActiveUsers extends TopActiveUsersController {
  
  render() {
    const { top6 } = this.props

    return (
      <MainPaper square elevation={0}>
        <MainHeading data-test-id="main-heading" gutterBottom variant="subtitle2">
          Your top 6
        </MainHeading>
        <SubHeading data-test-id="sub-heading" gutterBottom>
          The most active users in your platform
        </SubHeading>
        <ContentContainer container spacing={2}>
          {top6.length > 0 ? (
            top6.map((brand) => {
              const {
                id,
                attributes: { profile_pic, first_name, last_name },
              } = brand;
              const fullName = `${first_name} ${last_name}`;

              return (
                <ProfileContainer key={id} item sm={6} md={4}>
                  <BrandAvatar data-test-id="brand-avatar">
                    <BrandImage
                      src={profile_pic ? profile_pic.url : smileYellow}
                      alt="Brand Logo"
                    />
                  </BrandAvatar>
                  <BrandName title={fullName}>{fullName}</BrandName>
                </ProfileContainer>
              );
            })
          ) : (
            <EmptyWrapper>
              <Typography data-test-id="empty-list-message" align="center">{configJSON.noDataAvailableText}</Typography>
            </EmptyWrapper>
          )}
        </ContentContainer>
      </MainPaper>
    );
}
}

export default TopActiveUsers;
// Customizable Area End
