// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box } from "@material-ui/core";
import BaseModalController from "./BaseModalController.web";

export class BaseModal extends BaseModalController {
  render() {
    const { classes, children, closeModal } = this.props;

    return (
      <Box className={classes.modalContainer} onClick={closeModal}>
        {children}
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    modalContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      zIndex: 99999,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: "auto",
      background: "rgba(0, 0, 0, 0.38)",
    },
  });

export default withStyles(styles)(BaseModal);

// Customizable Area End
