// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleSuccessResponse: (data: any) => void;
  handleGoBackClick: () => void;
}

interface S {
  formError: any;
  currentStep: number;
  hasLowerCaseLetter: any;
  hasNumber: any;
  hasMinLength: any;
  userToken: string;
  formGlobalError: any;
  showPassword: boolean;
  businessPassword: string | null;
  businessConfirmPassword: string | null;
  showConfirmPassword: boolean;
  passwordsMatch: boolean;
  hasCapitalLetter: any | null;
  passWordInformation: string;
}
interface SS {
  id: any;
}
export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailSignupCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      businessPassword: this.businessPasswordValue,
      businessConfirmPassword: this.businessConfirmPassword,
      showPassword: false,
      showConfirmPassword: false,
      passwordsMatch: true,
      hasCapitalLetter: this.parsedData
        ? this.parsedData.hasCapitalLetter
        : false,
      hasLowerCaseLetter: this.parsedData
        ? this.parsedData.hasLowerCaseLetter
        : false,
      hasNumber: this.parsedData ? this.parsedData.hasNumber : false,
      hasMinLength: this.parsedData ? this.parsedData.hasMinLength : false,
      currentStep: 1,
      formError: {},
      formGlobalError: {},
      passWordInformation: "",
      userToken: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  emailAddressValue = sessionStorage.getItem("emailAddress");
  businessPasswordValue = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("businessPassword")
    : "";
  businessConfirmPassword = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("businessConfirmPassword")
    : "";

  hascriteriatrue = sessionStorage.getItem("hascriteria");
  parsedData = this.hascriteriatrue && JSON.parse(this.hascriteriatrue);
  handleFieldChange = (e: any) => {
    const { name, value }: { name: string; value: string } = e.target;
    this.setState(({
      [name]: value,
    } as unknown) as Pick<S, keyof S>);

    this.handlePasswordChange(e.target.value);
  };

  validateStep2 = () => {
    const errors: any = {};
    const {
      businessPassword,
      businessConfirmPassword,
      hasCapitalLetter,
      hasLowerCaseLetter,
      hasNumber,
      hasMinLength,
    } = this.state;
    if (!businessPassword) {
      errors.businessPassword = "Password Field is required.";
    } else if (businessPassword !== businessConfirmPassword) {
      errors.businessConfirmPassword =
        "Password and confirm password should be the same.";
    }
    if (!businessConfirmPassword) {
      errors.businessConfirmPassword = "Confirm password Field is required.";
    }
    if (businessPassword) {
      if (
        !hasCapitalLetter ||
        !hasLowerCaseLetter ||
        !hasNumber ||
        !hasMinLength
      ) {
        errors.businessPassword = "Password does not meet the requirements.";
        errors.businessConfirmPassword =
          "Password does not meet the requirements.";
      }
    }

    return errors;
  };

  handleNextClick = () => {
    const errors = this.validateStep2();
    this.setState({
      formError: errors,
    });
    if (Object.keys(errors).length === 0) {
      this.doEmailSignUp();
    }
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevstate) => ({
      showConfirmPassword: !prevstate.showConfirmPassword,
    }));
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handlePasswordChange = (text: string) => {
    const password = text;

    this.setState({
      hasCapitalLetter: /[A-Z]/.test(password),
      hasLowerCaseLetter: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasMinLength: password.length >= 8,
    });
  };

  getCheckCircleIconStyle = (condition: any, styles: any) => {
    const style = { ...styles };

    if (condition) {
      style.color = "green";
    } else {
      style.color = "#94A3B8";
    }
    return style;
  };

  async doEmailSignUp() {
    let emailAddressFromLocalStorage = sessionStorage.getItem("emailAddress");
    const attrs = {
      email: emailAddressFromLocalStorage,
      password: this.state.businessPassword,
      password_confirmation: this.state.businessConfirmPassword,
      role_name: "business_user",
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (this.state.passWordInformation !== this.state.businessPassword) {
      this.apiEmailSignupCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.SignUpEmailEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.signupAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return;
    } else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }));
    }
    return true;
  }

  handlGlobalApiError = (responseJson: any) => {
    if (responseJson && (responseJson.error || responseJson.errors)) {
      const errMsg = "Something went wrong!";
      this.setState({ formGlobalError: { [this.state.currentStep]: errMsg } });
      return true;
    }
  };

  apicallresponseData = (responseJson: any) => {
    this.handlGlobalApiError(responseJson);
    if (!(responseJson.error || responseJson.errors)) {
      this.props.handleSuccessResponse(responseJson);
      const data = {
        hasCapitalLetter: this.state.hasCapitalLetter,
        hasLowerCaseLetter: this.state.hasLowerCaseLetter,
        hasNumber: this.state.hasNumber,
        hasMinLength: this.state.hasMinLength,
      };
      this.state.businessPassword &&
        sessionStorage.setItem("businessPassword", this.state.businessPassword);
      this.state.businessConfirmPassword &&
        sessionStorage.setItem(
          "businessConfirmPassword",
          this.state.businessConfirmPassword
        );
      sessionStorage.setItem("hascriteria", JSON.stringify(data));
      sessionStorage.setItem("valuesBackStep", "false");
    }
  };

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiEmailSignupCallId
      ) {
        this.apicallresponseData(responseJson);
      }
    }
  }
}
// Customizable Area End
