// Customizable Area Start
import React, { useRef, useState } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  Box,
  Button,
  Typography,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { File } from "./types";
import { NewPostCreationSteps } from "./enums";
import { VolumeUpRounded, MoreHorizRounded } from "@material-ui/icons";
import {
  phoneStatusIcons,
  everyBrandLogo,
  avatarExample,
  heartIcon,
  message,
} from "./assets";

const styles = (theme: Theme) =>
  createStyles({
    newPostModalContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexBasis: "808px",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
      padding: "60px 43px 40px",
      gap: "21px",
      borderRadius: "8px 8px 32px 8px",
      background: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
    },

    closeNewPostModal: {
      position: "absolute",
      top: "22px",
      right: "13px",
    },

    previewContainer: {
      position: "relative",
      borderRadius: "20px",
      overflow: "hidden",
    },

    previewModalTitle: {
      alignSelf: "flex-start",
      fontSize: "32px",
      fontWeight: 700,
      letterSpacing: "-0.11px",
    },

    imagePreviewContainer: {
      display: "flex",
      height: "606px",
      minWidth: "281px",
      maxWidth: "376px",
      overflow: "hidden",
      borderRadius: "20px",
      backgroundColor: "#F2F2F2",

      "& > img": {
        objectFit: "cover",
      },

      "& > video": {
        width: "100%",
        objectFit: "cover",
      },
    },

    overlayGradiend: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "40%",
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 29.36%, #000 116.29%)"
    },

    previewTopOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "16px",
      padding: "14px 0 2px",
    },

    overlayTop: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "0 25px",
    },

    imagePreviewPhoneInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    imagePreviewPhoneTime: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      color: "#FFF",
    },

    imagePreviewPhoneStatusIcons: {
      display: "flex",
      alignItems: "center",
    },

    imagePreviewSecondaryInfo: {
      position: "relative",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",

      "& > svg": {
        position: "absolute",
        right: 0,
        color: "#FFF",
      },
    },

    secondaryInfoTextContainer: {
      display: "flex",
      flexWrap: "nowrap",
      justifySelf: "center",
      gap: "10px"
    },

    secondaryInfoText: {
      fontSize: "10px",
      fontWeight: 900,
      textTransform: "uppercase",
      color: "#FFF",
    },

    overlayBottom: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    postInfoContainer: {
      display: "flex",
      gap: "15px",
      justifyContent: "space-between",
      padding: "10px 25px",
    },

    postTitleAndCaption: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      justifyContent: "flex-end",
    },

    dotsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      paddingLeft: "50px",
      paddingBottom: "21px"
    },

    filesChoiceDots: {
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      cursor: "pointer"
    },

    postOverlayTitle: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#FFF",
      opacity: "0.5",
    },

    postOverlayCaption: {
      fontSize: "14px",
      opacity: "0.5",
      color: "#FFF",
    },

    showMoreLink: {
      fontWeight: 700,
      color: "#FFF",
      opacity: "0.5",
    },

    postSocialStats: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "5px",
    },

    avatarContainer: {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      overflow: "hidden",
    },

    avatar: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      opacity: "0.5",
    },

    socialStatsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "#FFF",
      opacity: "0.5",
    },

    likesIcon: {
      width: "33px",
      height: "30px",
    },

    commentsIcon: {
      width: "21px",
      height: "21px",
      fill: "#FFF",
    },

    everybrandLogoContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "6px",
      fontSize: "8px",
      fontWeight: 700,
      color: "#FFF",
      opacity: "0.5",
    },

    everybrandText: {
      fontSize: "9px",
      fontWeight: 700,
      opacity: "0.5",
      paddingTop: "5px"
    },

    videoProgressBarContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },

    videoProgressBar: {
      width: "90%",
      height: "3px",
      backgroundColor: "#FFF",
    },

    videoProgressBarStatus: {
      position: "relative",
      width: "30%",
      height: "100%",
      backgroundColor: "#FFC629",
    },

    videoProgressControl: {
      position: "absolute",
      top: "-2px",
      right: "0",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#FFC629",
    },

    buttonsContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "14px",
    },

    stepButton: {
      width: "132px",
      height: "44px",
      borderRadius: "8px",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
  });

type Props = {
  classes: any;
  files: File[];
  cover: string;
  closeModal: () => void;
  setStep: (step: NewPostCreationSteps) => void;
  sendPost: () => void;
};

export const NewPostPreview = ({
  classes,
  files,
  cover,
  closeModal,
  setStep,
  sendPost,
}: Props) => {
  const videoPreviewRef = useRef<HTMLVideoElement | null>(null)
  const [currentFile, setCurrentFile] = useState(0);

  const handleFileSelect = (fileIndex: number) => {
    if(fileIndex !== currentFile) {
      setCurrentFile(fileIndex);

      videoPreviewRef.current?.load();
    }
  }

  return (
    <Container
      id="newPostPreviewContainer"
      maxWidth={false}
      className={classes.newPostModalContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <IconButton
        id="closeNewPostPreviewModal"
        className={classes.closeNewPostModal}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>
      <Typography className={classes.previewModalTitle}>
        Post Preview
      </Typography>
      <Box className={classes.previewContainer}>
        <Box className={classes.imagePreviewContainer}>
          {files[currentFile]?.type.includes("image") ? (
            <img
              id={"image"}
              src={files[currentFile].preview}
              alt="image preview"
            />
          ) : (
            <video controls={false} poster={cover} ref={videoPreviewRef}>
              <source
                id={"video"}
                src={files[currentFile].preview}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
        </Box>

        <Box className={classes.overlayGradiend} />

        <Box className={classes.previewTopOverlay}>
          <Box className={classes.overlayTop}>
            <Box className={classes.imagePreviewPhoneInfo}>
              <Typography className={classes.imagePreviewPhoneTime}>
                9:41
              </Typography>
              <img
                className={classes.phoneStatusIcons}
                src={phoneStatusIcons}
                alt="phone status icons"
              />
            </Box>
            <Box className={classes.imagePreviewSecondaryInfo}>
              <Box className={classes.secondaryInfoTextContainer}>
                <Typography className={classes.secondaryInfoText}>
                  Home
                </Typography>
                <Typography
                  className={classes.secondaryInfoText}
                  style={{ opacity: "0.3" }}
                >
                  Trending
                </Typography>
              </Box>

              <VolumeUpRounded />
            </Box>
          </Box>

          <Box className={classes.overlayBottom}>
            <Box className={classes.postInfoContainer}>
              <Box className={classes.postTitleAndCaption}>
                <Box className={classes.dotsContainer}>
                  {Boolean(files?.length) &&
                    files.map((_, i) => {
                      return (
                        <Box
                          key={`dot-${i}`}
                          id={`dot-${i}`}
                          className={classes.filesChoiceDots}
                          onClick={() => handleFileSelect(i)}
                          style={{backgroundColor: currentFile === i ? "#FFF" : "rgba(255, 255, 255, 0.50)"}}
                        />
                      );
                    })}
                </Box>
                <Typography className={classes.postOverlayTitle}>
                  Necronomicon ART
                </Typography>
                <Typography className={classes.postOverlayCaption}>
                  This is the new episode from the dead
                </Typography>
                <span className={classes.showMoreLink}>more...</span>
              </Box>
              <Box className={classes.postSocialStats}>
                <Box className={classes.avatarContainer}>
                  <img
                    src={avatarExample}
                    alt="avatar"
                    className={classes.avatar}
                  />
                </Box>
                <Box className={classes.socialStatsContainer}>
                  <img
                    src={heartIcon}
                    alt="likes"
                    className={classes.likesIcon}
                  />
                  35 M
                </Box>
                <Box className={classes.socialStatsContainer}>
                  <img
                    src={message}
                    alt="comments"
                    className={classes.commentsIcon}
                  />
                  15 M
                </Box>
                <Box className={classes.socialStatsContainer}>
                  <MoreHorizRounded />
                </Box>
              </Box>
            </Box>
            <Box className={classes.everybrandLogoContainer}>
              <Typography className={classes.everybrandText}>
                Powered by
              </Typography>
              <img
                src={everyBrandLogo}
                className={classes.everybrandLogo}
                alt="everybrand"
              />
            </Box>
            <Box className={classes.videoProgressBarContainer}>
              <Box className={classes.videoProgressBar}>
                <Box className={classes.videoProgressBarStatus}>
                  <Box className={classes.videoProgressControl} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonsContainer}>
        <Button
          id="discardPostButton"
          className={classes.stepButton}
          onClick={() => {
            setStep(NewPostCreationSteps.COVER_SELECTION);
          }}
          style={{ color: "#FFF", backgroundColor: "#000" }}
        >
          Discard
        </Button>
        <Button
          id="confirmPostButton"
          className={classes.stepButton}
          onClick={sendPost}
          style={{ color: "#000", backgroundColor: "#FFC629" }}
        >
          Post
        </Button>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(NewPostPreview);

// Customizable Area End
