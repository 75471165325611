// Customizable Area Start
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { HeartIcon, ChatBubbleLargeIcon, StarIcon } from './assets';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reactionsContainer: {
      padding: theme.spacing(1),
      gap: theme.spacing(5)
    },
    reactionText: {
      color: '#FF0000',
      fontSize:"14px",
      fontStyle:"normal",
      fontFamily:"Inter",
      fontWeight: 600
    },
    icon: {
      width: '45px',
      height: '40px'
    }
  })
);

function Reactions() {
  const classes = useStyles();

  return (
    <Paper square elevation={0}>
      <Grid
        container
        justifyContent="center"
        className={classes.reactionsContainer}
      >
        <Grid item>
          <img src={HeartIcon} className={classes.icon} />
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.reactionText}
          >
            0
          </Typography>
        </Grid>
        <Grid item>
          <img src={ChatBubbleLargeIcon} className={classes.icon} />
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.reactionText}
          >
            0
          </Typography>
        </Grid>
        <Grid item>
          <img src={StarIcon} className={classes.icon} />
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.reactionText}
          >
            0
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Reactions;
// Customizable Area End
