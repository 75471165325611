// Customizable Area Start
import React from "react";
import {
    Button,

    Typography,

    Box,
    IconButton,

    Modal,
    TextField,

    Avatar,
    Checkbox,
    TextareaAutosize,
    Tooltip,
    FormControl,
    NativeSelect,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,

    InputAdornment,
} from "@material-ui/core";

import {
    withStyles,
    createStyles,
    Theme,
    styled,
} from "@material-ui/core/styles";
import { stepImageFirst, CloseIcon, infoIcon, IconAvtar, currentContact, checkMarkWhite, trashIcon } from "./assets";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Formik } from "formik";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import WaitListController from "./WaitListController.web";

import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { trueIcon } from "../../user-profile-basic/src/assets";






export class CreateWaitListContact extends WaitListController {
    FormField = (touched: any, error: any) => {

        return `1px solid ${error && touched ? "red" : "#CBD5E1"}`
    }
    errorField = (touched: any, errors: any, fieldName: any) => {
        return touched[fieldName] && Boolean(errors[fieldName]);
    };
    FormFieldError = (errors: any, touched: any) => {

        return errors && touched && <div data-testid="phone-error" style={{ color: "red", marginTop: "5px" }}>
            {errors}
        </div>
    }
    commonFunctionForCondition = (condition: any, param1: any, param2: any) => {
        return condition ? param1 : param2
    }
    render() {
        const { classes, }: any = this.props;
        const { createId } = this.props




        return (
            <>
                <Box className={classes.root}>
                    <Snackbar
                        autoHideDuration={6000}
                        open={this.state.isShowAlert}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                           
                            severity= {this.commonFunctionForCondition(this.state.errorAlertMessage,"error","success")}
                        >
                            {this.state.showSucess}

                        </MuiAlert>

                    </Snackbar>
                    <Box className={classes.waitListBox}>
                        <Box style={{ marginTop: "28px" }}>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <Typography className={classes.WaitListHeading}>
                                Waitlist Contacts
                            </Typography>
                            {this.state.selectedContacts.length>0 && <img src={trashIcon} alt="trashIcon" data-test-id="deleteIcon" style={{cursor:"pointer"}} onClick={this.removeBulkContactsOfWaitlistApi}/>}
                            </div>
                            <HrLine />
                            <TextField
                                data-test-id="searchWaitlistContact"
                                className={classes.searchField}
                                variant="outlined"
                                placeholder="Search"

                                value={this.state.searchQuery}
                                onChange={(e) => this.setState({ searchQuery: e.target.value })}
                                InputProps={{
                                    classes: {
                                        root: classes.customSearchField,
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ color: "#94A3B8" }} />
                                        </InputAdornment>
                                    ),
                                }}


                            />
                            <div className={classes.scrollbar}>

                                {this.props.contactId.filter((item: any) =>
                                    item.attributes.first_name.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
                                    item.attributes.last_name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
                                ).map((item: any, index: number) => {


                                    return (
                                        <>
                                            <Box key={index} className={`${classes.card} ${this.commonFunctionForCondition(index % 2 === 0, classes.cardWithBackGround, classes.cardWithoutBackGround)}`} >
                                                <div className={classes.mainDiv}>
                                                    <div>

                                                        <Avatar style={{
                                                            color: "black", width: "44px", height: "44px",
                                                            backgroundColor: "#FEF3C7"
                                                        }} src={this.commonFunctionForCondition(item.attributes?.profile_pic_url, item.attributes?.profile_pic_url, IconAvtar)} alt="ggg" />
                                                    </div>

                                                    <div className={classes.content}>
                                                        <Typography className={classes.name}>{item.attributes.first_name + " " + item.attributes.last_name}   <FiberManualRecordIcon
                                                            className={classes.bulletSmall
                                                            }
                                                        /></Typography>
                                                        <span className={classes.active}></span>
                                                        <Typography className={classes.contactInfoNumber} >{item.attributes.formated_phone_number}</Typography>
                                                        <Typography className={classes.contactInfoMail}>{item.attributes.email}</Typography>

                                                    </div>
                                                    <div className={classes.actions}>
                                                        <div>
                                                            <Checkbox
                                                                data-test-id="checkBox"
                                                                checked={this.state.selectedContacts.includes(item.id)}
                                                                onChange={() => this.handleCheckboxChange(item.id)}

                                                                style={{ color: "black", }}
                                                                inputProps={{
                                                                    'aria-label': 'primary checkbox',

                                                                }}
                                                                classes={{
                                                                    root: classes.checkboxRoot
                                                                }}
                                                            />
                                                        </div>
                                                        <div >
                                                            {item.attributes.note && item.attributes.note !== "" && (
                                                                <TextOnlyTooltip placement="top-start" title={
                                                                    <Typography style={{

                                                                        fontFamily: "Inter",
                                                                        fontSize: "12px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        lineHeight: "18px"
                                                                    }}>
                                                                        {item.attributes.note}
                                                                    </Typography>}>
                                                                    <img style={{ cursor: "pointer" }} src={infoIcon} alt="infoicon" />
                                                                </TextOnlyTooltip>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </>
                                    )
                                })}


                            </div>
                        </Box>
                    </Box>
                    <Box className={classes.createContactBox}>

                        <Box className={classes.containerOfCreateContact} >
                            <Typography variant="h6" gutterBottom className={classes.WaitListHeading}>
                                Create Waitlist Contact
                            </Typography>

                            <Formik
                                initialValues={
                                    {
                                        first_name: this.state.perticularContactDetail?.attributes?.first_name || '',
                                        last_name: this.state.perticularContactDetail?.attributes?.last_name || '',
                                        full_phone_number: this.state.perticularContactDetail?.attributes?.full_phone_number || '',
                                        note: this.state.perticularContactDetail?.attributes?.note || '',
                                        appointment_date: "",
                                    }
                                }
                                enableReinitialize={true}
                                validationSchema={this.validationSchema}
                                onSubmit={(values: any, actions: any) => {
                                    this.createContactApi(values, createId,actions)
                                    actions.setSubmitting(false)
                                  
                                    
                                }}
                            >
                                {({ values, handleChange, setFieldValue, handleSubmit, errors, touched, setValues,setTouched }) => {

                                    return (
                                        <form onSubmit={handleSubmit} className={classes.form}>

                                            <Typography variant="subtitle2" className={classes.createContactInfoHeadings} gutterBottom >
                                                First Name</Typography>
                                            <TextField
                                                className={classes.textField}
                                                id="firstname"


                                                variant="outlined"
                                                onChange={handleChange}
                                                placeholder="First name"
                                                name="first_name"

                                                error={this.errorField(touched, errors, 'first_name')}
                                                data-test-id="infoFirstname"
                                                value={values.first_name}
                                            />
                                            {this.FormFieldError(errors.first_name, touched.first_name)}

                                            <Typography variant="subtitle2" className={classes.createContactInfoHeadings} gutterBottom >Last Name</Typography>
                                            <TextField
                                                className={classes.textField}
                                                value={values.last_name}

                                                id="lastname"
                                                variant="outlined"
                                                error={this.errorField(touched, errors, 'last_name')}


                                                placeholder="Last name"
                                                name="last_name"
                                                onChange={handleChange}
                                                data-test-id="infoLastname"
                                            />
                                            {this.FormFieldError(errors.last_name, touched.last_name)}
                                            <Typography variant="subtitle2" className={classes.createContactInfoHeadings} gutterBottom >Mobile number</Typography>
                                            <PhoneInput
                                                value={values.full_phone_number}

                                                country={this.commonFunctionForCondition(values.full_phone_number || this.state.perticularContactDetail?.attributes?.full_phone_number, "", "us")}
                                                data-test-id="phoneinput"
                                                inputClass={classes.phoneInputClass}
                                                buttonClass={classes.phoneInputButton}

                                                buttonStyle={{
                                                    border: "none",
                                                    borderBottom: this.FormField(touched.full_phone_number, errors.full_phone_number),
                                                    borderLeft: this.FormField(touched.full_phone_number, errors.full_phone_number),
                                                    borderTop: this.FormField(touched.full_phone_number, errors.full_phone_number),
                                                    background: "transparent"


                                                }}
                                                onChange={(phone: any, data: any, event) => {
                                                    setFieldValue('full_phone_number', phone);

                                                }}
                                                inputProps={{
                                                    id: "mobileNumber",
                                                    name: "full_phone_number",
                                                }}
                                                inputStyle={{
                                                    height: "56px",
                                                    width: "100%",
                                                    flexShrink: 0,
                                                    borderRadius: "8px",
                                                    border: this.FormField(touched.full_phone_number, errors.full_phone_number),
                                                    background: "white !important",
                                                    color: "#64748B",
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    lineHeight: "24px"
                                                }}
                                            />
                                            {this.FormFieldError(errors.full_phone_number, touched.full_phone_number)}
                                            <Typography variant="subtitle2" className={classes.createContactInfoHeadings} gutterBottom>Date of Future Appointment</Typography>
                                            <ModalInputBoxDatePicker
                                                name="appointment_date"
                                                data-test-id="holidayDateCallId"

                                                selected={values.appointment_date? new Date(values.appointment_date):null}
                                                value={this.state.date||values.appointment_date}
                                                minDate={new Date()}
                                                onChange={(date) => {
                                                    // Call Formik's setFieldValue to update the form state
                                                    
                                                    // Call your custom onDateChange function to update the local component state
                                                    this.onDateChange(date, setFieldValue);
                                                    
                                                }}
                                                className={errors.appointment_date && touched.appointment_date? classes.errorBorder : ''}
                                                placeholderText="MM/DD/YYYY"



                                            />
                                           
                                         {  errors.appointment_date && touched.appointment_date &&( <div data-testid="phone-error" style={{ color: "red", marginTop: "5px" }}>
            {"Date is required"}
        </div>)}

                                            <div
                                                onClick={this.openaddNotesField}
                                                data-test-id="addNotes"
                                                style={{
                                                    display: "flex",

                                                    cursor: "pointer",
                                                    margin: "17px",
                                                    gap: "15.5px"
                                                }}>

                                                <img src={infoIcon}  alt="infoicon" width={22} height={22} />
                                                <Typography className={classes.fonts}>Add Note</Typography>
                                            </div>
                                            {this.commonFunctionForCondition(this.state.addNotesField, 
                                            <>
                                            <TextareaAutosize value={values.note} onChange={handleChange}
                                                minRows={5} className={classes.textareaAdd} name="note" data-test-id="aboutprofile" />
                                                <div className={classes.spanDiv}>
                                                <Typography className={classes.spanFonts}>limit 100 characters</Typography></div></>
                                                , <></>)}


                                            <div
                                                onClick={() => this.openCurrentContactsModal(createId)}
                                                data-test-id="addFromContacts"
                                                style={{
                                                    display: "flex",

                                                    cursor: "pointer",
                                                    margin: "17px",
                                                    gap: "15.5px"
                                                }}>
                                                <div >
                                                    <img src={currentContact} alt="logo" />
                                                </div>

                                                <Typography className={classes.fonts}>Add From Current Contacts</Typography>
                                            </div>


                                            <Dialog
                                                onClose={this.openCurrentContactsModal}
                                                aria-labelledby="customized-dialog-title"
                                                open={this.state.currentContactsModal}
                                                classes={{
                                                    paper: classes.dialogPaper,

                                                }}
                                                maxWidth="lg"
                                            >
                                                <DialogTitle id="customized-dialog-title" onClick={this.openCurrentContactsModal}>
                                                    <Typography className={classes.diaLogeTitleFonts}>Current Contacts</Typography>
                                                    <IconButton
                                                        aria-label="close"
                                                        className={classes.closeButton}
                                                        onClick={this.openCurrentContactsModal}
                                                    >
                                                        <img src={CloseIcon} alt="" />
                                                    </IconButton>
                                                </DialogTitle>
                                                <HrLine style={{ width: "100%" }} />
                                                <TextField
                                                    className={classes.currentContactsSearchField}
                                                    data-test-id="searchField"
                                                    variant="outlined"
                                                    value={this.state.searchQuery}

                                                    placeholder="Search"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customSearchField,
                                                        },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon style={{ color: "#94A3B8" }} />
                                                            </InputAdornment>
                                                        ),

                                                    }}
                                                    onChange={(e) => this.setState({ searchQuery: e.target.value })}

                                                />
                                                <DialogContent>
                                                    <Box
                                                        display="flex"
                                                        flexWrap="wrap"
                                                        p={1}
                                                        m={1}
                                                        style={{ gap: "95px" }}>

                                                        {this.state.currentContactListData.filter((item: any) =>
                                                            item.attributes.first_name.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
                                                            item.attributes.last_name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
                                                        ).map((item: any, index: number) => {

                                                            return (
                                                                <>
                                                                    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }} data-test-id="firstContact">
                                                                        <Avatar className={classes.activeUserAvatar} data-test-id="currentContacts" onClick={() => this.currentContactSelected(index, item.id)}>
                                                                            
                                                                            <img src={this.commonFunctionForCondition(item.attributes?.profile_pic_url, item.attributes?.profile_pic_url, IconAvtar)} className={classes.fallbackAvatar} />
                                                                        </Avatar>
                                                                        {this.commonFunctionForCondition(this.state.selectedCurrentContacts === index, <div className={classes.whiteMark}  >
                                                                            <img className={classes.ImagewhiteMrak} src={checkMarkWhite} alt="logo" data-test-id="unSelectedContacts" onClick={() => this.currentContactSelected(index, item.id)} />
                                                                        </div>, <></>)}



                                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                                            <Typography
                                                                                className={classes.currentContactsTypography}

                                                                            >
                                                                                {item.attributes.first_name}
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.currentContactsTypography}

                                                                            >
                                                                                {item.attributes.last_name}
                                                                            </Typography>
                                                                        </div>
                                                                    </Box>
                                                                </>
                                                            )
                                                        })}

                                                    </Box>
                                                </DialogContent>
                                                <DialogActions>

                                                    <Button variant="contained" data-test-id="cancelbutton" className={classes.cancelButton} onClick={this.openCurrentContactsModal}>Cancel</Button>

                                                    <Button
                                                        data-test-id="addToList"
                                                        onClick={async () => {
                                                            await this.getAutoFillPerticularContactApi();
                                                            this.openCurrentContactsModal(createId)
                                                        }} variant="contained" className={classes.nextHomePage2} disabled={this.commonFunctionForCondition(this.state.selectedCurrentContacts !== -1, false, true)}>
                                                        <img src={trueIcon} alt="img" />Add to List </Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Button data-test-id="addContactButton" type="submit" className={classes.addContactbtn}>ADD CONTACT</Button>
                                        </form>
                                    )
                                }}
                            </Formik>

                        </Box>
                    </Box>
                    <Box className={classes.addToWaitlistBox}>
                        <FormControl >

                            <NativeSelect
                                disableUnderline
                                className={classes.selectField}
                                IconComponent={() => (
                                    <IconButton className={classes.iconButton} size="small">
                                        <ExpandMoreIcon />
                                    </IconButton>
                                )}

                            >
                                <option value="" >
                                    {this.props.waitlListFoldersName}
                                </option>


                            </NativeSelect>

                        </FormControl>
                        <Typography className={classes.addedToWaitlistHeading}>Added to the waitlist</Typography>
                        <HrLine style={{ marginTop: "27px" }} />
                        <div className={classes.scrollbarForaddedInWaitlist}>
                            {this.state.bulkUploadContactsData?.map((item: any, index: number) => {


                                return (
                                    <>
                                        <Box key={index} className={classes.listOfWaitings}>
                                            {/* Render the selected contact details here */}
                                            <div style={{ background: "#FFC629", borderRadius: "100px" }}>
                                                <img
                                                    src={stepImageFirst}
                                                    alt="simeimag"
                                                    style={{ height: "29.92px", width: "29.21px", margin: "6.04px" }}
                                                />
                                            </div>
                                            <div>
                                                <Typography className={classes.middleContentFonts}>{item?.attributes?.first_name + " " + item?.attributes?.last_name}</Typography>
                                            </div>
                                            <div>
                                                <IconButton data-test-id="openModalIcon" className={classes.closeiconDiv} onClick={() => this.openConfirmModal(item?.id)}>
                                                    <img src={CloseIcon} alt="" />
                                                </IconButton>
                                            </div>
                                        </Box>

                                    </>
                                );
                            })}

                        </div>
                        <Button data-test-id="doneButton" onClick={this.handleDoneButtonClick} className={classes.doneButton}>DONE</Button>
                        <Modal
                            open={this.state.confirmModal}
                            onClose={this.closeConfirmModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            data-test-id="templateEditModal"
                        >
                            <Box className={classes.EndWaitlistmodalBox}>
                                <ConfirmResetModalTitle>
                                    Confirm the removal of this waitlist contact
                                    <CloseImageIcon src={CloseIcon} alt="" onClick={this.closeConfirmModal} />
                                </ConfirmResetModalTitle>

                                <Footer>
                                    <DeleteButton data-test-id="resetButtonCall" type="button" onClick={this.closeConfirmModal} >Cancel</DeleteButton>
                                    <CancelButton data-test-id="confirmButton" onClick={this.handleConfirmRemoval}>Confirm</CancelButton>
                                </Footer>
                            </Box>
                        </Modal>
                    </Box>

                </Box>
            </>
        );
    }
}

const TextOnlyTooltip = withStyles({
    tooltip: {
        color: "black",
        backgroundColor: "#FFC629",
        borderRadius: "8px 8px 8px 0px"
    }
})(Tooltip);

const ConfirmResetModalTitle = styled(Typography)({
    fontSize: "20px",
    position: "relative",
    marginBottom: "66px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
})
const Footer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px"
})
const CancelButton = styled("button")({
    backgroundColor: "#ffca29",
    padding: "16px 0",
    color: "black",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})

const DeleteButton = styled("button")({
    backgroundColor: "black",
    padding: "16px 0",
    color: "white",
    textTransform: "capitalize",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})
const CloseImageIcon = styled("img")({
    position: "absolute",
    right: "-89px",
    bottom: "83px",
    cursor: "pointer",
    width: "24px",
    height: "26px",
    padding: "4px",
    borderRadius: "80px",
    justifyContent: "space-between",
    alignItems: "center",
    border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    background: "#FFF",
    boxShadow: " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
})

const HrLine = styled("hr")({
    marginTop: "10px",
    strokeWidth: "1px",
    stroke: "rgba(100, 116, 139, 0.20)"


});
const ModalInputBoxDatePicker = styled(DatePicker)({
    display: "inline-flex",
    height: "56px",
    width: "360px",

    alignItems: "center",
    padding: "17px 8px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    color: "#64748B",

    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    "&:focus": {
        outline: "none",
        border: "2px solid #FFC629",
    },

});



const styles = (theme: Theme) =>
    createStyles({
        spanDiv:{
            display:"flex",
            justifyContent:"flex-end"
        },
        spanFonts:{
            fontSize: "12px",
            fontWeight: 400,
            fontStyle: "normal",
            fontFamily: "Inter",
            color:"#94A3B8"
        },
        fonts: {
            fontSize: "16px",
            fontWeight: 700,
            fontStyle: "normal",
            fontFamily: "Inter"
        },
        ImagewhiteMrak: {
            position: "absolute",
            top: "-125px",
            left: "-58px"
        },
        whiteMark: {
            position: "sticky",
            cursor: "pointer",
            top: "11px",
        },
        nextHomePage2: {
            backgroundColor: "#FFC629",
            textTransform: "capitalize" as "capitalize",
            height: "44px",
            fontSize: "16px",
            gap: "8px",
            padding: "10px 16px 10px 16px",
            fontWeight: 700,
            width: "188",
            borderRadius: "8px",
            "&:hover": {
                cursor: "pointer",

                background: "#FFC629",

            },
        },
        cancelButton: {
            width: "87px",
            height: "44px",
            padding: "10px 16px 10px 16px",
            borderRadius: "8px",
            gap: "8px",
            textTransform: "capitalize" as "capitalize",
            fontSize: "16px",
            fontWeight: 700,
            backgroundColor: "black",
            color: "#fff",
            marginRight: "20px",
            "&:hover": {
                cursor: "pointer",

                background: "#000",

            },
        },
        currentContactsTypography: {
            color: "#334155",

            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "19px",
        },
        fallbackAvatar: {
            width: "113px",
            height: "117px",
            objectFit: 'cover'
        },
        activeUserAvatar: {

            marginBottom: theme.spacing(1),

            cursor: "pointer",
            width: "113px",
            height: "117px",
            borderRadius: "136px",
            border: " 2px solid #FFC629",

            background: "#FFF",
        },
        diaLogeTitleFonts: {
            color: "#000",

            fontFamily: "Inter",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px", /* 133.333% */
            letterSpacing: "-0.12px",
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogPaper: {
            // Customize the styles as needed
            padding: "30px 25px 46px 90px",
            overflow: "hidden",
            width: "1165px",
            height: "942px",


            borderRadius: "0px 0px 24px 0px",
            background: "#FFF",

            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"

        },
        bulletSmall: {
            width: "10px",
            height: "10px",

            fontSize: "12px",
            margin: "6px 5px 0 0",
            color: "#000",
            borderRadius: "100px",
            background: "#0F172A"
        },
        closeiconDiv: {
            width: "24px",
            height: "26px",
            padding: "4px",
            borderRadius: "80px",
            justifyContent: "space-between",
            alignItems: "center",
            border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
            background: "#FFF",
            boxShadow: " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
        },
        option: {
            color: "#000",

            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px"
        },
        iconButton: {
            padding: 0,
            color: "#000"
        },
        errorBorder: {
            border: "1px solid red"
        },
        scrollbar: {

            maxHeight: "600px",
            overflowX: "auto",
            height: "100%",
            "&::-webkit-scrollbar": {
                width: "5px",


            },
            "&::-webkit-scrollbar-thumb":
            {
                borderRadius: "10px",

                backgroundColor: "#CBD5E1",
            },
            "&::-webkit-scrollbar-track":
            {

                borderRadius: "10px",
                backgroundColor: "#CBD5E1"
            }
        },
        scrollbarForaddedInWaitlist: {

            maxHeight: "600px",
            overflowX: "hidden",
            overflowY: "auto",
            height: "100%",
            "&::-webkit-scrollbar": {
                width: "5px",


            },
            "&::-webkit-scrollbar-thumb":
            {
                borderRadius: "10px",

                backgroundColor: "#CBD5E1",
            },
            "&::-webkit-scrollbar-track":
            {

                borderRadius: "10px",
                backgroundColor: "#CBD5E1"
            }
        },
        EndWaitlistmodalBox: {
            position: "relative",
            backgroundColor: "#fff",
            padding: "85px 106px 31px 72px",
            width: "100%",
            maxWidth: "636px",
            borderRadius: "0px 0px 40px 0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
            height: "272px"
        },
        doneButton: {
            display: "flex",
            width: "232px",
            height: "56px",
            padding: "16px 49px",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: "45px",
            margin: "0 0 0 30px",
            borderRadius: "8px",
            background: "#FFC629",

            color: "#0F172A",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            "&:hover": {
                backgroundColor: "#FFC629",
            },
        },
        addedToWaitlistHeading: {
            color: "#000",

            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",/* 228.571% */
            letterSpacing: " -0.12px",
            marginTop: "27px"
        },
        middleContentFonts: {
            color: "#0F172A",
            maxWidth: "133px",
            wordBreak: "break-word",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px"
        },
        crossIconDiv: {
            display: "flex",
            width: "24px",
            padding: "4px",
            justifyContent: "space-between",
            alignItems: "center",

            borderRadius: "80px",
            border: "1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
            background: "#FFF",

            boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",


        },
        listOfWaitings: {
            display: "flex",
            justifyContent: "space-between",
            gap: "23px",
            marginRight: "24px",
            marginLeft: "16.5px",
            marginTop: "27px",
            alignItems: "center"
        },
        selectField: {
            width: "281px",
            height: "28px",

            border: "1px solid #DADADA",

            background: "#FFF",
            color: "#000",

            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px"



        },
        tooltipStyles: {
            background: '#FFC629', // Change to your desired background color
            color: 'black',   // Change to your desired text color
            // Add any other styles you want to customize
        },
        textareaAdd: {
            width: "100%",
            marginBottom: "17px",
            padding: "10px",
            border: "1px solid #CBD5E1",
            borderRadius: "8px",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Inter",
            "&:focus": {
                outline: "none",
                border: "2px solid #FFC629",
            },
        },
        phoneInputClass: {
            background: "white !important",
            backgroundColor: "white !important",

            "&:focus": {
                boxShadow: "0 0 0 1px #FFC629 !important",
                borderColor: "#FFC629 !important",

            }

        },

        phoneInputButton: {
            "& .arrow": {
                display: "none !important",
            }
        },
        addContactbtn: {

            height: "56px",
            width: "100%",
            borderRadius: "8px",
            background: " #FFC629",

            color: "#0F172A",
            /* text-align: center; */
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            "&:hover": {
                cursor: "pointer",

                background: "#FFC629",

            },
        },
        createContactInfoHeadings: {
            color: "#334155",

            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px",
            marginTop: "17px"
        },
        textField: {
            display: "flex",
            height: "56px",

            justifyContent: "center",


            alignSelf: "stretch",

            borderRadius: "8px",


            background: "#FFF",

            marginBottom: "7px",

        },
        containerOfCreateContact: {

            width: "360px",
            display: "flex",
            flexDirection: "column"
        },
        checkboxRoot: {
            height: "20px", width: "20px", borderRadius: "6px",
            overflow: "hidden"
        },
        name: {
            color: "#0F172A",

            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px"
        },
        contactInfoNumber: {
            marginTop: "4px",
            color: "#0F172A",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22px",

        },
        contactInfoMail: {
            color: "#0F172A",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22px",
            textDecorationLine: "underline",
            marginTop: "10px"
        },
        mainDiv: {
            display: "flex",
            gap: '24px'
        },
        content: {
            width: "224px",

        },
        actions: {
            display: "flex",
            flexDirection: "column",
            gap: "49.5px"
        },
        bullet: {



            borderRadius: "100px",

        },
        cardHeader: {
            display: 'flex',
            alignItems: 'center'
        },
        avatar: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            marginRight: theme.spacing(2),
            backgroundColor: '#CCC'
        },
        cardHeaderContent: {
            flex: 1,
            marginRight: "24px"
        },
        cardHeaderAction: {
            marginTop: theme.spacing(-1),
            marginRight: theme.spacing(-1),
            '& button': {
                transform: 'rotate(90deg)'
            }
        },
        root: {
            boxSizing: "border-box",
            display: "flex",
            gap: "12px",
            minHeight: "909px",
            overflow: 'auto'
        },
        waitListBox: {
            width: "419px",
            padding: "24px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "8px 8px 32px 8px",
            background: "#FFF"
        },
        createContactBox: {
            display: "flex",


            width: "650px",
            padding: "40px 0px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "17px",
            borderRadius: "0px 0px 24px 0px",
            background: "#FFF"
        },
        addToWaitlistBox: {
            position: "relative",
            width: "305px",
            padding: "25px 10px",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "27px",
            borderRadius: "0px 0px 24px 0px",
            background: "#FFF"
        },
        WaitListHeading: {
            color: "#000",

            fontFamily: "Inter",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px", /* 133.333% */
            letterSpacing: "-0.12px"

        },
        folderField: {
            width: "281px",
            height: "28px",



            background: " #FFF",
        },
        currentContactsSearchField: {
            borderRadius: "98px",
            background: "#F7F8FC",


            marginTop: "10px",
            marginBottom: "10px"
        },
        searchField: {
            borderRadius: "98px",
            background: "#F7F8FC",
            width: "370px",

            marginTop: "10px",
            marginBottom: "10px"
        },
        customSearchField: {

            borderRadius: '98px',

        },
        card: {

            padding: '16px 8px 16px 8px',
            borderBottom: '1px solid #E2E8F0',

            marginTop: "10px"

        },
        cardWithBackGround: {
            background: '#E2E8F0',
        },
        cardWithoutBackGround: {
            background: '#FFF',
        }
    });

export default withStyles(styles)(CreateWaitListContact);
// Customizable Area End
