// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { NewEveryreelSteps, PostTypes } from "./enums";
import { File, FunnelFileData } from "./types";
import { getToken } from "../../../components/src/AuthService";

export const configJSON = require("./config");

export interface SavedFile {
  id: string;
  type: string;
  attributes: {
    id: number;
    body: any;
    social_videoable_id: any;
    social_videoable_type: string;
    file: {
      model_name: string;
      body: any;
      likes_count: number;
      comments_count: number;
      button_name: any;
      button_url: any;
      file: string;
    };
  };
}

export type Props = RouteComponentProps & {
  id?: string;
  classes?: any;
  navigation: any;
};

interface S {
  currentStep: NewEveryreelSteps;
  error: string;
  files: File[];
  savedFunnelFiles: FunnelFileData[];
  postType?: PostTypes;
}

interface SS {
  navigation: any;
}

export default class CreateEveryreelController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendFilesApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      currentStep: NewEveryreelSteps.INITIAL_CHOICE,
      files: [],
      savedFunnelFiles: [],
      postType: undefined,
      error: "",
    };
  }

  template = configJSON.template;

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.files !== this.state.files && this.state.files.length) {
      this.setStep(NewEveryreelSteps.FILE_CONFIRM);
    }
  }

  setStep = (step: NewEveryreelSteps) => {
    this.setState({ currentStep: step });
  };

  closeModal = () => {
    this.props.history.goBack();
  };

  setFiles = (files: File[]) => {
    this.setState({
      files,
    });
  };

  addFiles = (files: File[]) => {
    this.setState((prevState) => {
      return {
        files: [...prevState.files, ...files],
      };
    });
  };

  setPostType = (postType: PostTypes) => {
    this.setState({ postType });
  };

  sendFunnelFiles = () => {
    const formData = new FormData();

    this.state.files.forEach((file) => {
      formData.append("files[]", (file as unknown) as Blob);
    });

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendFilesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFunnelFilesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.sendFilesApiCallId) {
      this.handleFunnelFilesAPIResponse(message);
    }
  }

  handleFunnelFilesAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.data) {
      this.setState({ savedFunnelFiles: responseJson.data });
    }
  };
}
// Customizable Area End
