Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.httpPostMethod = "POST"

exports.EU_AUTH_TOKEN = "EUAuthToken"
exports.AUTH_TOKEN = "authToken"
exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.somethingWentWrong = "Something went wrong"
exports.deleteMessage = "Notifications deleted!"
exports.notificationEndPoint="/bx_block_notifications/notifications"
exports.markasReadEndpoint="/bx_block_notifications/notifications/mark_all_as_read"
exports.postCommentEndPoint="/bx_block_comments/comments"
exports.getPostDataEndPoint = "/bx_block_posts/posts"
exports.endPointApiGetEUNotificationBrands = "bx_block_dashboard/dashboards/end_user_data";
exports.followBrandApiEndpoint = "bx_block_followers/follows/create_follower"
exports.likeApiEndPoint="/bx_block_like/likes"
exports.undoLikeApiEndpoint = "/bx_block_like/likes/undo_like"
exports.contentTypeApiGetEUNotificationBrands = "application/json";
exports.methodTypeApiGetEUNotificationBrands = "GET";
exports.dashboardApiEndpoint = "bx_block_dashboard/dashboards";
exports.CommentClass = "BxBlockComments::Comment"
exports.PostClass = "BxBlockPosts::Post"
exports.likeableTypes = {
  "Comment": "BxBlockComments::Comment",
  "Post": "BxBlockPosts::Post",
}
exports.SinglePostPayloadKey = "SinglePostPayload"
// Customizable Area End